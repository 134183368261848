import React, { useEffect, useState, Suspense } from "react";

import styles from './Processsteps.css';

export default function Processsteps(props) {

  return(

    
            <>

              {(() => {

              let row = []
              if(props.state !== undefined && (props.state ===6) ) {
                
                row.push(
                  <div class="box-row">
                <div class="box">
                  <div class="box-button">
                    <div class="box-tag box-tag-left"></div>
                    <div  class={props.state !== undefined  && (props.state === 2 || props.state ===4 || props.state ===5 || props.state ===6 || props.state ===7 || props.state ===8) ? 'box-icon active' : 'box-icon' }>
                        <i class="fa fa-opencart" aria-hidden="true"></i>
                    </div>
                    <div class="box-tag box-tag-right"></div>
                  </div>
                  <span class="box-label">Order Cancel</span>
                </div>
                <div class="box">
                  <div class="box-button">
                    <div class="box-tag box-tag-left"></div>
                    <div class={props.state !== undefined  && (props.state ===4 || props.state ===5 || props.state ===7 || props.state ===8) ? 'box-icon active' : 'box-icon' }>
                        <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                    </div>
                    <div class="box-tag box-tag-right"></div>
                  </div>
                  <span class="box-label">Refund Processing</span>
                </div>
                <div class="box">
                  <div class="box-button">
                    <div class="box-tag box-tag-left"></div>
                    <div class={props.state !== undefined  && (props.state === 7 || props.state ===8 ) ? 'box-icon active' : 'box-icon' }>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div class="box-tag box-tag-right"></div>
                  </div>
                  <span class="box-label">Refunded</span>
                </div>
              </div>
                )
              }else{
                
                row.push(
                  <div class="box-row">
            <div class="box">
              <div class="box-button">
                <div class="box-tag box-tag-left"></div>
                <div  class={props.state !== undefined  && (props.state === 2 || props.state ===4 || props.state ===5 || props.state ===6 || props.state ===7 || props.state ===8) ? 'box-icon active' : 'box-icon' }>
                    <i class="fa fa-opencart" aria-hidden="true"></i>
                </div>
                <div class="box-tag box-tag-right"></div>
              </div>
              <span class="box-label">Order Placed</span>
            </div>
            <div class="box">
              <div class="box-button">
                <div class="box-tag box-tag-left"></div>
                <div class={props.state !== undefined  && (props.state ===4 || props.state ===5 || props.state ===7 || props.state ===8) ? 'box-icon active' : 'box-icon' }>
                    <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                </div>
                <div class="box-tag box-tag-right"></div>
              </div>
              <span class="box-label">Payment</span>
            </div>
            <div class="box">
              <div class="box-button">
                <div class="box-tag box-tag-left"></div>
                <div class={props.state !== undefined  && (props.state === 7 || props.state ===8 ) ? 'box-icon active' : 'box-icon' }>
                    <i class="fa fa-truck" aria-hidden="true"></i>
                </div>
                <div class="box-tag box-tag-right"></div>
              </div>
              <span class="box-label">Shipping</span>
            </div>
            <div class="box">
              <div class="box-button">
                <div class="box-tag box-tag-left"></div>
                <div class={props.state !== undefined  && ( props.state ===8) ? 'box-icon active' : 'box-icon' }>
                    <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="box-tag box-tag-right"></div>
              </div>
              <span class="box-label">Delivered</span>
            </div>
          </div>
                )
              }
              return row;
              })()}
              
            </>
  );

}


import React, {useEffect , useState } from 'react';
import * as Helper from '../../Helper';
import axios from 'axios';


//Component
import Topbanner from '../../containers/TopbannerContainer';
import Banner from '../Banner/Banner';
import Header from '../../containers/HeaderContainer';
import Productslider from '../Productslider/Productslider';
import Productpannel from '../Productpannel/Productpannel';
import Footer from '../Footer/Footer';
import Secondbanner from '../Secondbanner/Secondbanner';
import Productlist from '../Productlist/Productlist';
import Loader from '../Loader/Loader';
export default function Pages(props){

  const [loaderState, setLoaderState] = useState(true);
  const [Sitesettings , setSitesettings] = useState([]);

  const [token, setToken] = useState(localStorage.getItem('token'))
  const [uid, setuid] = useState(localStorage.getItem('UID'))


    //API call
    let url = Helper.SITEURL+'admin/api/settings';
    const Apicall = () => {
      axios
          .get(url)
          .then((res) => {
              if (res.status === 200) {
  
                setLoaderState(false);
                if(res.data.data !== undefined) {
                  setSitesettings(res.data.data);
                }
                
              }else{
                setLoaderState(true);
                setSitesettings([]);
              }
          })
          .catch((err) => {
              setLoaderState(true);
              setSitesettings([]);
          });
    };
  
    useEffect(() => {
        Apicall();
        
    }, []);

    const [Pagedata , setPagedata] = useState([]);
       //API call
       const Pagecall = () => {
      
        //SITE URL
        const currenturl = window.location.pathname;
        var ltrim = require('ltrim');
        const params = ltrim(currenturl, ' /');
        const formData = new FormData();
        formData.append("pageurl", params);
        axios
          .post(Helper.SITEURL+'admin/api/page-content/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  }
                })
              .then((res) => {
                //console.log(res.data.success === true);
                  if (res.data.success === true) {
                    if(res.data.data.pagecontent[0] !== undefined) {
                        setPagedata(res.data.data.pagecontent[0]);
                    }
                  }else{
                    setPagedata([]);
                  }
              })
              .catch((err) => {
                  setPagedata([]);
            
              });   
        };
      
        useEffect(() => {
          Pagecall();
        }, []);

  return(
        <>
          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          {loaderState === false && Pagedata !== undefined  ?<div className='container' dangerouslySetInnerHTML={{ __html: Pagedata.pagecontent}}></div> : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings}/> : ''}           
          
        </>
  );

}
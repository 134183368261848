import Addadress from '../components/Addadress/Addadress';
import {connect} from 'react-redux';
import {loadAddress , showAddress} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    loadAddressHandler:(data) => dispatch(loadAddress(data)),
    showAddressHandler:(data) => dispatch(showAddress(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Addadress)
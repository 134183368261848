import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch, Route , Redirect } from "react-router-dom";
import axios from "axios";
import * as Helper from '../../Helper';
import { Modal ,Button, Form  } from "react-bootstrap";

//FORM
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";


export default function Useraccount(props) {


	const [uid, setUid] = useState(localStorage.getItem('UID'));
	const [token, setstate] = useState(localStorage.getItem('token'))
	const [userinfo, setUserinfo] = useState([]);
	const [profileImage, setprofileImage] = useState(localStorage.getItem('profileImage'));
	
	useEffect(() => {
		setUserinfo(props.userinfo);
	}, [props.userinfo])


	useEffect(() => {
		if(profileImage === undefined || profileImage === '' || profileImage === null ){
			setprofileImage('/assets/images/noprofileimage.png');
		}
	}, [profileImage])


	function handleChange(evt) {
		const formData = new FormData();  
		const profileimage = document.querySelector('#profileimg');
		formData.append("customerid", uid);
		formData.append("profileimage", profileimage.files[0]);

		axios
		.post(Helper.SITEURL +'admin/api/customer-profile-image-upload/',formData , {
			headers: {
			  'Content-Type': 'multipart/form-data',
			  'Authorization': 'Bearer '+token
			}
		  })
		  .then((res) => {
			  if (res.data.success === true) {
				 setprofileImage(res.data.data[0].profile_image);
				 localStorage.setItem("profileImage", res.data.data[0].profile_image);
				 props.setimageHandler(res.data.data[0].profile_image);
				 toastSuccess("Success Updated Profile Image");
			  }else{
				  
				toastError('Failed to Upload Image.');
			  }
		  })
		  .catch((err) => {
			  console.log('Error to Upload Image.');
			  toastError('Error to Upload Image.');
		  });	       
	}

	//Change Password
	const [cshow, setCshow] = useState(false);
	const handlesShow = () => setCshow(true);
	const handlesHide = () => setCshow(false)


	    //Registration Validation

		const validationSchema = Yup.object().shape({
			oldpassword: Yup.string()
				.required('Please Enter your Old Password'),
			newpassword: Yup.string()
				.required('Please Enter your New Password.'),
				// .matches(
				//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				//     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
				// ),
			confirmpassword: Yup.string()
				.required('Please Enter your Confirm Password.')
				.oneOf([Yup.ref("newpassword"), null], "Passwords must match")
		
		});


	const formOptions = { resolver: yupResolver(validationSchema) };

	//get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');

	//API call REGISTRATION
    function onSubmit(data) {

            if(data.oldpassword!== undefined && data.oldpassword !== ''){
    
              const formData = new FormData();
            
              formData.append("customerid", uid);
              formData.append("oldpassword", data.oldpassword);
              formData.append("newpassword", data.newpassword);
              formData.append("confirmpassword", data.confirmpassword);
            
                axios
                .post(Helper.SITEURL +'admin/api/change-password/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {
                      setShowSuccess(true);
                      reset();
                      setMessage(res.data.message);
					  toastSuccess(res.data.message);
					  const interval = setInterval(() => {
						handlesHide();
					  }, 3000);
					  
                    }else{
					  toastError(res.data.message);	
                      setShowError(true);
                      setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    
            }
            return false;
    }

	//Site Css
    const successstyle = {
        'text-align' : "center",
        'float': 'left'
    };
    
    const successcontainer = {
        'paddingLeft': '15px',
        'paddingRight': '15px'
    };


  return(
            <>
                  						<div id="account-details" className="tab-pane fade">
											<h3 className="title2">
												Account details
												<a href="#" className="edit-i remove">
													<i className="anm anm-edit" aria-hidden="true"></i>
												</a>
											</h3>
											<div className="account-login-form bg-light-gray padding-20px-all">
												<form>
													<fieldset>
														<div className="row">
															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<label for="input-firstname">
																	Profile Picture <span className="required-f">*</span>
																</label>
																<div className="avatar-upload">
																	<div className="avatar-edit">
																		<input
																			type="file"
																			id="profileimg"
																			name="profileimg"
																			accept=".png, .jpg, .jpeg"
																			onChange={(evt) => handleChange(evt)}
																		/>
																		<label for="profileimg"></label>
																	</div>
																	<div className="avatar-preview">
																		<div
																			id="imagePreview"
																			style={{ backgroundImage: `url(${profileImage})` }}
																		></div>
																	</div>
																</div>
															</div>
														</div>

														<div className="row">
															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<label for="input-firstname">
																	Name <span className="required-f">*</span>
																</label>
																<input
																	name="name"
																	value={userinfo.name}
																	id="profileimage"
																	className="form-control"
																	type="text"
																	disabled
																/>
															</div>
															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<a className="passWordBtn" href="#" onClick={handlesShow} >
																	Change Password
																</a>
																
															</div>
															
														</div>
														<div className="row">
															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<label for="input-email">
																	E-Mail <span className="required-f">*</span>
																</label>
																<input
																	name="email"
																	value={userinfo.email}
																	id="input-email"
																	className="form-control"
																	type="email"
																	disabled
																/>
															</div>

															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<label>
																	Birthdate <span className="required-f">*</span>
																</label>
																<input
																	name="birthdate"
																	max="3000-12-31"
																	min="1000-01-01"
																	className="form-control"
																	type="date"
																/>
															</div>
															
														</div>
														<div className="row">
															<div className="form-group col-md-6 col-lg-6 col-xl-6 required">
																<label for="input-telephone">
																	Mobile Number <span className="required-f">*</span>
																</label>
																<input
																	name="telephone"
																	value={userinfo.contact}
																	id="input-telephone"
																	className="form-control"
																	type="tel"
																/>
															</div>
															
														</div>
													</fieldset>

													<button type="submit" className="btn margin-15px-top btn-primary">
														Save
													</button>
												</form>
											</div>
										</div>
										
            
			
			<Modal show={cshow}>
            	<>
                
                    <div className="modal-header border-bottom-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handlesHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-title text-center">
                            <h4>Change Password</h4>
                        </div>
                        <div className="d-flex flex-column text-center">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        
                                                { showSuccess ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="success" className="alert alert-success" style = {successstyle}>
                                                        <strong>{message}</strong>
                                                    </div>
                                                </div>
                                                : '' }
                                                { showError ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="error" className="alert alert-danger" style = {successstyle}>
                                                        <strong>{message}</strong>
                                                    </div>
                                                </div>
                                                : '' }    
                                                <div className="form-group">
                                                    <input {...register('oldpassword' , { required: true })} className={`form-control ${errors.oldpassword ? 'invalid' : ''}`} type="password" name="oldpassword" placeholder="Old Password" />
                                                    <div className="invalid-feedback">{errors.oldpassword?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                    <input {...register('newpassword' , { required: true })} className={`form-control ${errors.newpassword ? 'invalid' : ''}`} type="password" name="newpassword" placeholder="New Password" />
                                                    <div className="invalid-feedback">{errors.newpassword?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                    <input {...register('confirmpassword' , { required: true })} className={`form-control ${errors.confirmpassword ? 'invalid' : ''}`} type="password" name="confirmpassword" placeholder="Confirm Password" />
                                                    <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                                                </div>
                                               

                                                <input className="btn btn-info btn-block btn-round" type="submit" value="Submit" />
                                                
                                    </form>
                                
                                </div>
                    </div>


                
            </>
            
        </Modal>
			
			</>
  );

}

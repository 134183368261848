import React, { useEffect, useState, Suspense } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// Components
import Topbanner from "../../containers/TopbannerContainer";
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import Loader from "../Loader/Loader";
import * as Helper from "../../Helper";

import Useraddress from  '../../containers/UseraddressContainer';
import Userwishlist from  '../Userwishlist/Userwishlist';
import Userorders from  '../Userorders/Userorders';
import Useraccount from  '../../containers/UseraccountContainer';
import Userdashboard from  '../Userdashboard/Userdashboard';



export default function UserProfile(props) {

	const [loaderState, setLoaderState] = useState(true);
	const [Sitesettings, setSitesettings] = useState([]);

	//User Info
	const [profileImage, setprofileImage] = useState(localStorage.getItem('profileImage'));
	const [uid, setUid] = useState(localStorage.getItem('UID'));
	const [uname, setUname] = useState(localStorage.getItem('uname'));
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [userinfo, setUserinfo] = useState([]);

	useEffect(() => {

		if(profileImage === undefined || profileImage === '' || profileImage === null ){
			setprofileImage('/assets/images/noprofileimage.png');
		}
		
	}, [profileImage])


	//update profile iamge 
	useEffect(() => {
		if(props.data.setimageCall.length !== 0){
			setprofileImage(props.data.setimageCall);
		}
	}, [props.data.setimageCall])

	//user info call
	const Apiusercall = () => {
			  const formData = new FormData();
              formData.append("customerid", uid);

                axios
                .post(Helper.SITEURL +'admin/api/customer-details/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
						setUserinfo((res.data.data[0]));
                    }else{
						setUserinfo([])
						return <Redirect to='/'  />
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
	};

	useEffect(() => {
		Apiusercall();
	}, [token]);

	//API call
	let url = Helper.SITEURL + "admin/api/settings";
	const Apicall = () => {
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200) {
					setLoaderState(false);
					if (res.data.data !== undefined) {
						setSitesettings(res.data.data);
					}
				} else {
					setLoaderState(true);
					setSitesettings([]);
				}
			})
			.catch((err) => {
				setLoaderState(true);
				setSitesettings([]);
			});
	};

	useEffect(() => {
		Apicall();
	}, []);
	return (
		<>	
			{loaderState !== false  ? <Loader />  : ''}
			{loaderState === false && Sitesettings !== undefined ? (<Topbanner settings={Sitesettings} />) : ("")}
			{loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}

			<div id="page-content" className="userContent">
				<div id="page-content">
					<div className="page section-header text-center" style={{marginBottom: '0px'}}>
						<div className="page-title">
							<div className="wrapper">
								<h1 className="page-width">User Profile</h1>
							</div>
						</div>
					</div>

					<div className="container">
						<div className="row profile">
							<div className="col-md-3">
								<div className="profile-sidebar">
									<div className="profile-userpic">
										<img src={profileImage} className="img-responsive" alt="" />
									</div>
									<div className="profile-usertitle">
										<div className="profile-usertitle-name">{uname}</div>
									</div>

									<div className="portlet light bordered mb-0">
										<ul className="nav flex-column dashboard-list" role="tablist">
											<li>
												<a className="nav-link active" data-toggle="tab" href="#dashboard">
													<i className="icon anm anm-dashboard"></i> Dashboard
												</a>
											</li>
											<li>
												<a className="nav-link" data-toggle="tab" href="#account-details">
													<i className="icon anm anm-user-circle-o"></i> Account details
												</a>
											</li>
											<li>
												<a className="nav-link" data-toggle="tab" href="#orders">
													<i className="fa fa-first-order"></i> Orders
												</a>
											</li>
											<li>
												<a className="nav-link" data-toggle="tab" href="#downloads">
													<i className="icon anm anm-heart"></i> Wishlist
												</a>
											</li>
											<li>
												<a className="nav-link" data-toggle="tab" href="#address">
													<i className="fa fa-address-book"></i> Addresses
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-9">
								<div className="profile-content">
									<div className="tab-content dashboard-content padding-30px-all md-padding-15px-all">
										<Userdashboard/>
										<Useraccount userinfo={userinfo}/>
										<Userorders/>
										<Userwishlist/>
										<Useraddress/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			
			{loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings}/> : ''}
		</>
	);
}

import Cart from '../components/Cart/Cart'
import {connect} from 'react-redux';
import {cartProducts , cartPrice , signinShow , signinHide} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    setCartproductHandler:(data) => dispatch(cartProducts(data)),
    setcartPriceHandler:(data) => dispatch(cartPrice(data)),
    signinHideHandler:(data) => dispatch(signinHide(data)),
    signinShowHandler:(data) => dispatch(signinShow(data))
    
})
export default connect(mapStateToProps,mapDispatchToProps)(Cart)
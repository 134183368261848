import { ON_SIGNIN, ON_SIGNIN_HIDE , ON_FORGOT_SHOW , ON_FORGOT_HIDE } from '../constants'

export function SigninModalCall(state = [], action) {
    switch (action.type) {
        case ON_SIGNIN:
            return action.sdata;
        case ON_SIGNIN_HIDE:
            return action.sdata;
        default:
            return false;
    }
}

export function forgotModalCall(state = [], action) {
    switch (action.type) {
        case ON_FORGOT_SHOW:
            return action.forgotdata;
        case ON_FORGOT_HIDE:
            return action.forgotdata;
        default:
            return false;
    }
}


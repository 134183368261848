import { ADD_NEW_ADDRESS } from '../constants'

export default function addAddressCall(state = [], action) {

    switch (action.type) {
        case ADD_NEW_ADDRESS:
            return action.showaddress
        default:
            return false;
    }
}
import {combineReducers} from 'redux'
import {SigninModalCall , forgotModalCall} from './reducer';
import setimageCall from './imageset';
import loadAddressCall from './addressrdc';
import addAddressCall from './addressshow';
import {productFiltr , productBanner , totalProduct , activeLoader , setCategory , setPage , setOrder, settoOrder }  from './productfilter';
import {cartProducts ,cartPrice }  from './cart';
import {showSearch , setsiteSettings}  from './site';


export default combineReducers({
    SigninModalCall,
    setimageCall,
    loadAddressCall,
    addAddressCall,
    productFiltr,
    productBanner,
    totalProduct,
    activeLoader,
    setCategory,
    setPage,
    setOrder,
    cartProducts,
    cartPrice,
    showSearch,
    forgotModalCall,
    setsiteSettings
    
})
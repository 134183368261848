import React, {useEffect , useState } from 'react';
import Slider from "react-slick";
import * as Helper from '../../Helper';
import LazyLoad from 'react-lazyload';
import Lightbox from "react-image-lightbox";

import 'react-image-lightbox/style.css';


export default function Productimageslider(props) {

    const {product} = props; 
    
    //New
    const [Isnew, setIsnew] = useState(false);
    let today = new Date(),
    tdate =  today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

    //Sale
    const [Issale, setIssale] = useState(false);

    const arrOfImages = [];

    const [Productimage , setProductimage] = useState('');    
    const [Activeclass , setActiveclass] = useState('active');
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: 1
      };

      const imageChange = (url) => {
        setProductimage(url);
        this.addClass('active');
      };

      useEffect(() => {
        if (product.product_image !== undefined) {
            setProductimage(product.product_image[0]['large']);
        }
        // if (product.product_image !== undefined) {
        //     arrOfImages = product.product_image.map((item, i) => (item['large']));
        // }
      },[product])

    //   useEffect(() => {
    //     if (typeof objproductimage !== "undefined") {
    //         arrOfImages = objproductimage.map((item, i) => (item['large']));
    //     }
    //   },[objproductimage])



      useEffect(() => {
        if(props.product.created_at !== undefined ){

            const date1 = new Date(props.product.created_at);
            const date2 = new Date(tdate);
            const diff = Helper.getDifferenceInDays(date1, date2);
    
            if (diff <= props.settings.new_product_range  ){
                setIsnew(true);
            }
        }
      },props.product.created_at)

      useEffect(() => {
        if(props.product.product_offer_percentage !== undefined ){
            if (props.product.product_offer_percentage){
                setIssale(true);
            }
        }
      },props.product.product_offer_percentage , props.settings.new_product_range)

      //Modal show hide
      const [indexOfImages, setIndexOfImages] = useState(0);
      const [showModal, setShowModal] = useState(false);
      const openModalAndSetIndex = (index) => {
            setIndexOfImages(index);
            setShowModal(true);
            return;
      };


        return(
            <>
            <div className="product-details-img">
                        <div className="product-thumb">

                            <div id="gallery" className="product-dec-slider-2 product-tab-left">
                                <Slider {...settings}>

                                    { props.product.product_image !== undefined &&  props.product.product_image.map((productimage, i) => { 
                                        
                                        return (

                                            <a data-image={productimage['thumbnail']} className="slick-slide slick-cloned " aria-hidden="true" onClick={() => {
                                                imageChange(productimage['large']); this.addClass('active');
                                              }} > 
                                              
                                                    <img className="" data-src={productimage['large']} src={productimage['large']} alt="" />
                                              
                                            </a>

                                        )

                                    })}
                                
                                </Slider>
                            </div>
                        
                        </div>
                        <div className="zoompro-wrap product-zoom-right pl-20">
                            <div className="zoompro-span">

                                    <img className="zoompro" key={indexOfImages} src={Productimage} alt={Productimage} onClick={() => openModalAndSetIndex(0)} />

                                    {showModal && (
                                        <Lightbox
                                            mainSrc={arrOfImages[indexOfImages]}
                                            nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                                            prevSrc={
                                                arrOfImages[
                                                (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                                ]
                                            }
                                            onCloseRequest={() => setShowModal(false)}
                                            onMovePrevRequest={() =>
                                                setIndexOfImages(
                                                (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                                )
                                            }
                                            onMoveNextRequest={() =>
                                                setIndexOfImages(
                                                (indexOfImages + arrOfImages.length + 1) % arrOfImages.length
                                                )
                                            }
                                        />
                                    )}
 
                                
                            </div> 
                            <div className="product-labels">

                                {Issale === true ? (
                                    <span className="lbl on-sale">Sale</span>
                                ) : (
                                    ''
                                )}

                                {Isnew === true ? (
                                    <span className="lbl pr-label1">new</span>
                                ) : (
                                    ''
                                )}
                                
                            </div>
                        </div>

                    </div>
        </>
        );

}

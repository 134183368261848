import React, {useEffect , useState , Suspense} from 'react';
import Productfiltercategorydescription from '../../containers/ProductfiltercategorydescriptionContainer'
import Productfiltersidebar from '../../containers/ProductfiltersidebarContainer'
import Product from '../../containers/ProductContainer'
import ReactPaginate from 'react-paginate';
import * as Helper from '../../Helper';
import { Route, useParams } from "react-router-dom";

import axios from 'axios';


export default function Productfiltercontainer(props) {


  console.log(props);

  
  const [products, setProducts] = useState([]);
  const [count , setCount] = useState(0);
  const [page , setPage] = useState(0);
  const [selectedpage , setSelectedpage] = useState(0);
  const [totalproduct , setTotalproduct] = useState(1);
  const [limit , setLimit] = useState(20);


  const filterparam = useParams();
  const filterArray=(a,b)=>{return a.filter((e)=>{return e!=b})}
  const filterparamarray = filterparam.param.split('&');


  const handlePageClick = (page) => {
    page = page.selected + 1;
    props.setpageHandler(page)
  }


  const handleOrder = (value) => {

    props.setorderHandler(value);

  }


  const urldecode = () => {
    for (const [index, value] of filterparamarray.entries()) {
      const urlarg = value.split('=');
      if(urlarg[0] === 'lt'){
        setLimit(urlarg[1]);
      } 
      if(urlarg[0] === 'pg'){
        setSelectedpage(urlarg[1] - 1 );
      } 
    }
  }
  useEffect(() => {
    urldecode();
  }, [filterparamarray]);

    //API call
    const [settings, setSettings] = useState([]);
    let url = Helper.SITEURL+'admin/api/settings';
    const Apicall = () => {
      axios
          .get(url)
          .then((res) => {
              if (res.status === 200) {
  
                if(res.data.data !== undefined) {
                    setSettings(res.data.data);
                }
                
              }else{
                setSettings([]);
              }
          })
          .catch((err) => {
            setSettings([]);
          });
    };

    
  
    useEffect(()=>{
      Apicall();
    },[]);
  

  useEffect(() => {
    setProducts(props.data.productFiltr);
    setCount(props.data.productFiltr.length);
  }, [props.data.productFiltr]);
  

  useEffect(() => {
    setTotalproduct(props.data.totalProduct);
  }, [props.data.totalProduct]);

  useEffect(() => {
      let pge = Math.ceil(totalproduct/limit);
      setPage(pge);
  }, [limit , totalproduct]);

  return(
    <>
            <div class="container">
                <div class="row">
                    {/* <!--Sidebar--> */}
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 sidebar filterbar">
                        <div class="closeFilter d-block d-md-none d-lg-none"><i class="icon icon anm anm-times-l"></i>
                        </div>
                        <Productfiltersidebar />
                    </div>
                    {/* <!--End Sidebar--> */}
                    {/* <!--Main Content--> */}
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 main-col">
                        <Productfiltercategorydescription />
                        <hr />
                        <div class="productList product-load-more">
                            {/* <!--Toolbar--> */}
                            <button type="button" class="btn btn-filter d-block d-md-none d-lg-none"> Product
                                Filters</button>
                            <div class="toolbar">
                                <div class="filters-toolbar-wrapper">
                                    <div class="row">
                                        
                                        <div
                                            class="col-6 col-md-6 col-lg-6 text-left">
                                            <span class="filters-toolbar__product-count">Showing: { count !== '' ||	count !== undefined ||	count !== null ? count : 0 } of { totalproduct !== '' ||	totalproduct !== undefined ||	totalproduct !== null ? totalproduct : 0 }</span>
                                        </div>
                                        <div class="col-6 col-md-6 col-lg-6 text-right">
                                            <div class="filters-toolbar__item">
                                                <label for="SortBy" class="hidden">Sort</label>
                                                <select name="SortBy" id="SortBy"
                                                    class="filters-toolbar__input filters-toolbar__input--sort" onChange={e => handleOrder(e.target.value)} >
                                                    <option value={'new'} selected="selected">New Arrival</option>
                                                    <option value={'bs'}>Best Selling</option>
                                                    <option value={'a-z'}>Alphabetically, A-Z</option>
                                                    <option value={'z-a'}>Alphabetically, Z-A</option>
                                                    <option value={'plh'}>Price, low to high</option>
                                                    <option value={'phl'}>Price, high to low</option>
                                                </select>
                                                <input class="collection-header__default-sort" type="hidden"
                                                    value="manual" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!--End Toolbar--> */}
                            <div class="grid-products grid--view-items">
                                <div class="row">

                                    { products !== undefined && products !== '' &&  products.map((pdata, i) => { 
                                        return (
                                          < Product product= {pdata} settings= {settings} type='type2' panel ={'filter'}/>
         
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                        <div class="infinitpaginOuter">
                            <div class="infinitpagin">
                            <ReactPaginate
                              breakLabel=".."
                              nextLabel="next >"
                              onPageChange={page => handlePageClick(page)}
                              pageRangeDisplayed={5}
                              pageCount={page}
                              previousLabel="< prev"
                              renderOnZeroPageCount={null}
                              forcePage={selectedpage}
                            />
                            </div>
                        </div>
                    </div>
                    {/* <!--End Main Content--> */}
                </div>
            </div>
    </>
  );
}

import React, { useEffect, useState, Suspense } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Route, useParams } from "react-router-dom";
import * as Helper from "../../Helper";
import Navbar from '../../containers/NavbarContainer';
import { useHistory } from "react-router-dom";
import Productprice from '../Productprice/Productprice';

// Import the Commerce module
import Commerce from '@chec/commerce.js';

//Axios
import axios from 'axios';

function numFormatter(num) {
  return num+Helper.CURRENCY;
}

function defaultMAXvalue(value) {
  let divider =  value/100;
  return (Math.floor(divider))*100;
}

export default function Productfiltersidebar(props) {

    const history = useHistory();
    const [pricerange, setPricerange] = useState([0, 100]);
    const [category , setCategory] = useState(true);
    const categoryShow = () =>  {
        if(category === true) {
          setCategory(false);
        }else{
          setCategory(true);
        }
    };

    const [price , setPrice] = useState(true);
    const priceShow = () =>  {
        if(price === true) {
          setPrice(false);
        }else{
          setPrice(true);
        }
    };

    const [brand , setBrand] = useState(true);
    const brandShow = () =>  {
        if(brand === true) {
          setBrand(false);
        }else{
          setBrand(true);
        }
    };



  //Price Slider
  const [lowDefault, setLowDefault] = useState('');
  const [highDefault, setHighDefault] = useState('');

  const [priceind, setPriceind] = useState(true);


  const handleChange = (event, newValue) => {
      setPriceind(false);
      setPricerange(newValue);
  };

  const handleProduct  = (event, newValue) => {
    //props.activeLoaderHandler(true);
    handleUrlrequest(pricerange , 'pr');
  };



  //brand click
  const[selbrand , setSelbrand] = useState([]);

  const handleBrand  = (event, newValue) => {

    let sbrand = selbrand;
    let isChecked = event.target.checked;
    let value = event.target.value;

    if(isChecked === true) {
        sbrand.push(value);
    }else{
      var index = sbrand.indexOf(value)
      if (index !== -1) {
        sbrand.splice(index, 1);
      }
    }
    setPriceind(false);
    setSelbrand(sbrand);
    const data = (encodeURI(selbrand));
    //props.activeLoaderHandler(true);
    handleUrlrequest(data , 'br');
  };


  //slider
  const[productpricerenge , setProductpricerenge] = useState([]);
  useEffect(() => {

    let prange = '';
    if(pricerange[0] !== undefined && pricerange[1] !== undefined){
      prange = pricerange[0]+Helper.CURRENCY+'-'+pricerange[1]+Helper.CURRENCY;
    }else{
      prange = '1'+Helper.CURRENCY+'-'+'100'+Helper.CURRENCY;
    }
    setProductpricerenge(prange); 
  }, [pricerange]);

  useEffect(() => {
    
    if(props.data.setPage !== false){
      handleUrlrequest(props.data.setPage , 'pg' );
    }
    
  }, [props.data.setPage]);



  //ORDER
  useEffect(() => {
    
    if(props.data.setOrder !== false){
      handleUrlrequest(props.data.setOrder , 'or' );
    }
    
  }, [props.data.setOrder]);


  //Cat Subcat
  const [fcat, setFcat] = useState('');
  const [fscat, setFscat] = useState('');

  useEffect(() => {
    if(props.data.setCategory !== false){

      let category = props.data.setCategory.split('/');

      if(category[0] !== undefined) {
        setFcat(category[0]);
      }else{
        setFcat('');
      }

      if(category[1] !== undefined) {
        setFscat(category[1]);
      }else{
        setFscat('');
      }
      setPriceind(true);
    }
  }, [props.data.setCategory]);


  useEffect(() => {

    if(fcat !== ''){
      let fcatparam = [];
      fcatparam.push(fcat);
      handleUrlrequest(fcatparam , 'ct');
    }
    if(fscat !== ''){
      let fcatparam = [];
      fcatparam.push(fcat);
      fcatparam.push(fscat);

      handleUrlrequest(fcatparam , 'sct');
    }

  }, [fcat , fscat ]);


  //Product Filter API call
  const filterparam = useParams();
  const [scategory , setScategory] = useState([]);
  const [sbrand , setSbrand] = useState([]);
  const [newproduct , setNewproduct] = useState([]);
  const[selectedbrand , setSelectedbrand] = useState([]);

  //API Call Handeler
  const handleUrlrequest  = (urlparams , type ) => {

    const fparam = history.location.pathname.split('/').slice(-1)[0];
    let filterparamarray = [];
    const ftarray = fparam.split('&');

    const arrFiltered = ftarray.filter(el => {
      return el !== null && el !== '';
    });

    var finalarray = new Array();

    for (const [index2, value2] of arrFiltered.entries()) {
      const  sepval = value2.split('=');
      if(sepval[0] === 'pg'){
          finalarray['pg'] = sepval[1];
      }else if(sepval[0] === 'lt'){
          finalarray['lt'] = sepval[1];
      }else if(sepval[0] === 'br'){
          finalarray['br'] = sepval[1];
      }else if(sepval[0] === 'ct'){
          finalarray['ct'] = sepval[1];
      }else if(sepval[0] === 'sct'){
          finalarray['sct'] = sepval[1];
      }else if(sepval[0] === 'or'){
          finalarray['or'] = sepval[1];
          //props.data.settoOrderHandler({'test'});
      }else if(sepval[0] === 'lp'){
        finalarray['lp'] = sepval[1];
      }else if(sepval[0] === 'hp'){
        finalarray['hp'] = sepval[1];
      }
    }


    if (type !== undefined ) {

      if (type === 'pr') {
        finalarray['lp'] = urlparams[0];
        finalarray['hp'] = urlparams[1];

      }else if (type === 'sct'){
        finalarray = new Array();
        finalarray['ct'] = urlparams[0];
        finalarray['sct'] = urlparams[1];
        finalarray['pg'] = 1;
        finalarray['lt'] = Helper.PAGELIMIT;

      }else if (type === 'ct'){

        finalarray = new Array();

        finalarray[type] = urlparams[0];
        finalarray['pg'] = 1;
        finalarray['lt'] = Helper.PAGELIMIT;
        
      }else{

        finalarray[type] = urlparams;
      }
    }

    if (finalarray['pg'] === undefined ) {
      finalarray['pg'] = 1;
    }
    if (finalarray['lt'] === undefined ) {
      finalarray['lt'] = Helper.PAGELIMIT;
    }


    let newurl = '';
    let urlarray = [];
    Object.keys(finalarray).map(key => {

      if (finalarray[key] !== undefined  &&  finalarray[key] !== '') {

        newurl = newurl + '&' + key + '='+finalarray[key];
        urlarray.push(key + '='+finalarray[key]);
        
      }
        
    });
    if (finalarray['lp']!== undefined &&  finalarray['hp']!== undefined) {
      setPricerange([finalarray['lp'], finalarray['hp']]);
    }
    if (newurl !== '' ) {

      history.push(newurl);
      productfiltercall(urlarray);
      
    }
  
  };

  //API Call
  const productfiltercall = (url) => {

    const formData = new FormData();
    
    formData.append("filter", JSON.stringify(url));

      axios
      .post(Helper.SITEURL +'admin/api/filter-products/',formData , {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then((res) => {

          if(res.status === 200) { 
              //props.activeLoaderHandler(false);

              if (priceind === true) {
                setLowDefault(0);
                setHighDefault(defaultMAXvalue(res.data.data.maxprice));
                setPricerange([0, res.data.data.maxprice]);
              }

              if(res.data.data.products !== '') { 
                props.updateProductHandler(res.data.data.products);
              }else { 
                props.updateProductHandler([]);
              }
              if(res.data.data.singlecategory[0] !== undefined && res.data.data.singlecategory[0] !== '') { 
                props.updateBannerHandler(res.data.data.singlecategory[0]);
              }
              if(res.data.data.allproductscount !== undefined && res.data.data.allproductscount !== '') { 
                props.updateTotalproductHandler(res.data.data.allproductscount);
              }
              if(res.data.data.allcategories !== undefined && res.data.data.allcategories !== '') { 
                setScategory(res.data.data.allcategories);
              }
              if(res.data.data.brands !== undefined && res.data.data.brands !== '') { 
                setSbrand(res.data.data.brands);
              }
              if(res.data.data.latestproduct !== undefined && res.data.data.latestproduct !== '') { 
                setNewproduct(res.data.data.latestproduct);
              }

              //setPriceind(true);
          }
          
      })
      .catch((err) => {
          console.log(err);
      });
  };

  useEffect(() => {
      handleUrlrequest();
  }, []);

    return(
      <>
        <div class="sidebar_tags">
              {/* <!--Categories--> */}
              <div class="sidebar_widget categories filter-widget">
                  <div  class = {category ? 'widget-title active' : 'widget-title' } onClick={categoryShow}>
                      <h2>Categories</h2>
                  </div>
                  <div class="widget-content" style={{transition: "all .2s", display: category ? 'block' : 'none' }}>
                    {scategory ? <Navbar cat={scategory} /> : '' }
                  </div>
              </div>
              {/* <!--Categories--> */}
              {/* <!--Price Filter--> */}
              <div class="sidebar_widget filterBox filter-widget">
                  <div class = {price ? 'widget-title active' : 'widget-title' } onClick={priceShow}>
                      <h2>Price</h2>
                  </div>
                  
                      <Box sx={{ width: 300 }} style={{transition: "all .2s", display: price ? 'block' : 'none' }} >
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={pricerange}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          onChangeCommitted={handleProduct}
                          min={lowDefault}
                          max={highDefault}
                          step={100}
                          aria-labelledby="non-linear-slider"
                          valueLabelFormat={numFormatter}
                          
                        />

                      </Box>
                      <div class="row">
                          <div class="col-6">
                              <p class="no-margin"><input id="amount" type="text" value={productpricerenge} /></p>
                          </div>
                          <div class="col-6 text-center margin-25px-top">
                              <button class="btn btn-secondary btn--small">Reset</button>
                          </div>
                      </div>
                  
              </div>
              <div class="sidebar_widget filterBox filter-widget">
                  <div class = {brand ? 'widget-title active' : 'widget-title' } onClick={brandShow}>
                      <h2>Brands</h2>
                  </div>
                  <ul style={{transition: "all .2s", display: brand ? 'block' : 'none' }}>

                    {sbrand !== undefined && sbrand !== '' &&  Object.keys(sbrand).map((item, i) => (
                      <li key={i}>
                          {selectedbrand.indexOf(sbrand[item]) > -1 ? (
                            <input className={"brandcheck"} onClick={handleBrand} checked type="checkbox" value={sbrand[item]} id={'check1'+i} />
                          ) : (
                            <input className={"brandcheck"} onClick={handleBrand} type="checkbox" value={sbrand[item]} id={'check1'+i} />
                          )}
                          
                          <label for={'check1'+i}><span><span></span></span>{sbrand[item]}</label>
                      </li>
                    ))}

                  </ul>
              </div>

              <div class="sidebar_widget">
                  <div class="widget-title">
                      <h2>New Products</h2>
                  </div>
                  <div class="widget-content">
                      <div class="list list-sidebar-products">
                          <div class="grid">
                          { newproduct !== undefined &&  newproduct.map((npdata, i) => { 
                                   return (
                                    <div class="grid__item">
                                      <div class="mini-list-item">
                                          <div class="mini-view_image">
                                              <a class="grid-view-item__link" href={'/product/'+npdata.product_slug}>
                                                  <img class="grid-view-item__image" src={Helper.SITEURL+'admin/picture/product/resize/6767/'+npdata.product_image[0]} alt={npdata.product_name} />
                                              </a>
                                          </div>
                                          <div class="details"> <a class="grid-view-item__title" href={'/product/'+npdata.product_slug}>{npdata.product_name}</a>
                                              <div class="product-price">
                                              {npdata.product_retail_price !== undefined && npdata.product_retail_price > 0 ? (<Productprice price ={npdata.product_retail_price} sale ={npdata.product_offer_percentage} offerstatus={npdata.product_offer_status} type='type2' />) : ''}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  )
                          })}
                             
                          </div>
                      </div>
                  </div>
              </div>
            
              <div class="sidebar_widget static-banner">
                  <img src="assets/images/side-banner-2.jpg" alt="" />
              </div>
              
              {/* <div class="sidebar_widget">
                  <div class="widget-title">
                      <h2>Product Tags</h2>
                  </div>
                  <div class="widget-content">
                      <ul class="product-tags">
                          <li><a href="#" title="Show products matching tag $100 - $400">$100 - $400</a>
                          </li>
                          <li><a href="#" title="Show products matching tag $400 - $600">$400 - $600</a>
                          </li>
                          
                      </ul>
                      <span class="btn btn--small btnview">View all</span>
                  </div>
              </div> */}
              {/* <!--end Product Tags--> */}
          </div>
      </>
    ) 
}

import { 
    SET_CART,
    SET_PRICE
} from '../constants'

export function cartProducts(state = [], action) {
    switch (action.type) {
        case SET_CART:
                return action.cartproducts;
        default:
            return state;
    }
}

export function cartPrice(state = [], action) {
    switch (action.type) {
        case SET_PRICE:
                return action.cartprice;
        default:
            return state;
    }
}

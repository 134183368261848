import Productfiltercontainer from '../components/Productfiltercontainer/Productfiltercontainer';
import {connect} from 'react-redux'
import {setPage , setOrder} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    setpageHandler:(data) => dispatch(setPage(data)),
    setorderHandler:(data) => dispatch(setOrder(data)),
})
export default connect(mapStateToProps,mapDispatchToProps)(Productfiltercontainer)
import Addnewaddress from '../components/Addnewaddress/Addnewaddress';
import {connect} from 'react-redux';
import {showAddress} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    showAddressHandler:(data) => dispatch(showAddress(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Addnewaddress)
import Product from '../components/Product/Product'
import {connect} from 'react-redux'
import {signinShow , signinHide , cartProducts , cartPrice} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps = (dispatch) => {
    return {
      signinShowHandler:(data) => dispatch(signinShow(data)),
      signinHideHandler:(data) => dispatch(signinHide(data)),
      setCartproductHandler:(data) => dispatch(cartProducts(data)),
      setcartPriceHandler:(data) => dispatch(cartPrice(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Product)
import {ON_SIGNIN , 
        ON_SIGNIN_HIDE, 
        UPLOAD_PROFILE_IMAGE, 
        LOAD_ADDRESS , 
        ADD_NEW_ADDRESS , 
        PRODUCT_SIDE_FILTER, 
        PRODUCT_FILTER, 
        PRODUCT_BANNER, 
        TOTAL_PRODUCT,
        ACTIVE_LOADER,
        SET_CATEGORY,
        SET_PAGE,
        SET_ORDER,
        SET_TO_ORDER,
        SET_CART,
        SET_PRICE,
        SHOW_SEARCH,
        ON_FORGOT_HIDE,
        ON_FORGOT_SHOW,
        SETTINGS
    } from '../constants'

export const signinShow =(data)=>{
    return {
        type:ON_SIGNIN,
        sdata:data
    }
}
export const signinHide =(data)=>{
    return {
        type:ON_SIGNIN_HIDE,
        sdata:data
    }
}
export const profileImageUpdate =(data)=>{

    return {
        type:UPLOAD_PROFILE_IMAGE,
        setimage :data
    }
}

export const loadAddress =(data)=>{
    return {
        type:LOAD_ADDRESS,
        loadaddress :data
    }
}

export const showAddress =(data)=>{
    return {
        type:ADD_NEW_ADDRESS,
        showaddress :data
    }
}

export const productSideFilter =(data)=>{
    return {
        type:PRODUCT_SIDE_FILTER,
        productsidefilter :data
    }
}

export const productFilter =(data)=>{
    return {
        type:PRODUCT_FILTER,
        productfilter :data
    }
}

export const productBanner =(data)=>{
    return {
        type:PRODUCT_BANNER,
        productbanner :data
    }
}

export const totalProduct =(data)=>{
    return {
        type:TOTAL_PRODUCT,
        totalproduct :data
    }
}

export const activeLoader =(data)=>{
    return {
        type:ACTIVE_LOADER,
        activeloader :data
    }
}

export const setCategory =(data)=>{
    return {
        type : SET_CATEGORY,
        setcategory :data
    }
}

export const setPage =(data)=>{
    return {
        type : SET_PAGE,
        setpage :data
    }
}

export const setOrder =(data)=>{
    return {
        type : SET_ORDER,
        setorder :data
    }
}

export const cartProducts =(data)=>{
    return {
        type : SET_CART,
        cartproducts :data
    }
}

export const cartPrice =(data)=>{
    return {
        type : SET_PRICE,
        cartprice :data
    }
}

export const showSearch =(data)=>{
    return {
        type : SHOW_SEARCH,
        showsearch :data
    }
}

export const forgotShow =(data)=>{
    return {
        type:ON_FORGOT_SHOW,
        forgotdata:data
    }
}
export const forgotHide =(data)=>{
    return {
        type:ON_FORGOT_HIDE,
        forgotdata:data
    }
}
export const setSettings =(data)=>{
    return {
        type:SETTINGS,
        settings:data
    }
}


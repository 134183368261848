import Productfilterbanner from '../components/Productfilterbanner/Productfilterbanner';
import {connect} from 'react-redux';
import {setPage} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
        setpageHideHandler:(data) => dispatch(setPage(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Productfilterbanner)
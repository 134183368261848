import React, {useEffect , useState , Suspense , useCallback} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

//Component
import Topbanner from '../../containers/TopbannerContainer';
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import Deliverybanner from '../Deliverybanner/Deliverybanner';
import Loader from '../Loader/Loader';
import { useParams } from "react-router-dom";
import * as Helper from '../../Helper';
import $ from 'jquery'; 

import success from '../../assets/images/collection/check.png'

import failed from '../../assets/images/collection/error.png'

import base64 from 'react-native-base64';
import {Buffer} from 'buffer';


import axios from 'axios';

export default function Responsepayment(props) {

  const params = useParams();

  const [loaderState, setLoaderState] = useState(true);
  const [token, setstate] = useState(localStorage.getItem('token'))
  const [Sitesettings , setSitesettings] = useState([]);
  const [paymentprocess , setPaymentprocess] = useState(false);
  const [paymentstatus , setPaymentstatus] = useState([]);
  const [uid, setuid] = useState(localStorage.getItem('UID'));

        //API call
        let url = Helper.SITEURL+'admin/api/settings';
        const Apicall = () => {
          axios
              .get(url)
              .then((res) => {
                  if (res.status === 200) {
  
                    setLoaderState(false);
                    if(res.data.data !== undefined) {
                      setSitesettings(res.data.data);
                    }
                  
                  }else{
                    setLoaderState(true);
                    setSitesettings([]);
                  }
              })
              .catch((err) => {
                  setLoaderState(true);
                  setSitesettings([]);
              });
        };
  
        useEffect(() => {
            Apicall();
        }, [setSitesettings]);


        const [orderstate , setorderstate] = useState([]);
        //Order Response
        const ordercall = () => {

          let salt1 ='2U5K545';
          let salt2 ='5L58J545';
          let orderid = params.id;
          orderid = base64_decode(orderid);
          orderid = orderid.replace(salt1,'');
          orderid = orderid.replace(salt2,'');

          if (orderid !== '' ) {

            const formData = new FormData();
            
            formData.append("customerid", uid);
            formData.append("orderid", orderid);
            
            axios
            .post(Helper.SITEURL +'admin/api/customer-single-order/',formData , {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+token
              }
            })
            .then((res) => {
                if (res.status === 200) {

                  setLoaderState(false);

                  if(res.data.data.order[0] !== undefined) {
                    setorderstate(res.data.data.order[0]);
                  }
                
                }else{
                  setLoaderState(true);
                  setorderstate([]);
                }
            })
            .catch((err) => {
                setLoaderState(true);
                setorderstate([]);
            });
            
          }

             
        };
  
        useEffect(() => {
          ordercall();
        }, [params.id]);

  return(
            <>

          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}

              {(() => {

              let row = []
              if(orderstate.status !== undefined && orderstate.transaction_status!== undefined) {

                if(orderstate.transaction_status === 1 &&  orderstate.status === 4) {
                  if (orderstate.transaction_type === 'Cash on Delivery') {

                    row.push(
                      <div class="container" style={{marginTop: "5%" , width: '60%'}}>
                        <div class="col-md-12">
                              <div class="jumbotron" style={{boxShadow: "2px 2px 4px #000000" , textAlign: 'center'}}>
                                <img src={success} alt='success' width={'56px'} style={{marginBottom: "5px"}}></img>
                                <h2 class="text-center">YOUR ORDER HAS BEEN RECEIVED</h2>
                                <h3 class="text-center">Thank you for choosing Cash on Delivery. Always connect with us and stay happy stay safe.</h3>
                                
                                <p class="text-center">Your order # is: {orderstate.orderid} </p>
                                <p class="text-center">You will receive an order confirmation email with details of your order and a link to track your process.</p>
                                  <center><div class="btn-group" style={{marginTop: "50px"}} >
                                      <a href="/" class="btn btn-lg btn-warning">CONTINUE</a>
                                  </div></center>
                              </div>
                        </div>
                      </div>
                    )
                    
                  }else{

                    row.push(
                      <div class="container" style={{marginTop: "5%" , width: '60%'}}>
                        <div class="col-md-12">
                              <div class="jumbotron" style={{boxShadow: "2px 2px 4px #000000" , textAlign: 'center'}}>
                                <img src={success} alt='success' width={'56px'} style={{marginBottom: "5px"}}></img>
                                <h2 class="text-center">YOUR ORDER HAS BEEN RECEIVED</h2>
                                <h3 class="text-center">Thank you for your payment, it’s processing. Always connect with us and stay happy stay safe.</h3>
                                
                                <p class="text-center">Your order # is: {orderstate.orderid} </p>
                                <p class="text-center">You will receive an order confirmation email with details of your order and a link to track your process.</p>
                                  <center><div class="btn-group" style={{marginTop: "50px"}} >
                                      <a href="/" class="btn btn-lg btn-warning">CONTINUE</a>
                                  </div></center>
                              </div>
                        </div>
                      </div>
                    )

                  }
                  
                }else{
                  
                  row.push(
                    <div class="container" style={{marginTop: "5%" , width: '60%'}}>
                        <div class="col-md-12">
                              <div class="jumbotron" style={{boxShadow: "2px 2px 4px #000000" , textAlign: 'center'}}>
                                <img src={failed} alt='success' width={'56px'} style={{marginBottom: "5px"}}></img>
                                <h2 class="text-center">YOUR ORDER HAS BEEN FAILED</h2>
                                <h3 class="text-center">Please contact with our Support Team</h3>
                                
                                <p class="text-center">Your order # is: {orderstate.orderid}</p>
                                <p class="text-center">If your payment was done don't warry please contact with out Helpline No {Sitesettings.order_helpline}</p>
                                  <center><div class="btn-group" style={{marginTop: "50px"}} >
                                      <a href="/" class="btn btn-lg btn-warning">CONTINUE</a>
                                  </div></center>
                              </div>
                        </div>
                      </div>
                  )
  
                }

              }else{

                row.push(
                  <p class="text-center">No Record is found</p>
                )

                //window.location.href = "/";
              }

              
              return row;
              })()}

              {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings} type = 'productdetilas'/> : ''}

            </>
  );

}


import React, {useEffect , useState  } from 'react';
import ReactDOM from 'react-dom';
import Addadress from '../../containers/AddadressContainer';
import { Provider } from "react-redux";


export default function Addnewaddress(props){


  const [isshow, setIsshow] = useState(false);

  useEffect(() => {
    if(props.data.addAddressCall.show === true) {
        setIsshow(true);
    }else if(props.data.addAddressCall.show === false) {
       setIsshow(false);
    }
	}, [props.data.addAddressCall]);	

  const addressblock = (event) => {

    props.showAddressHandler({show:true})

    //setIsshow(true);
    // var currentnode =  event.currentTarget.parentNode;
    // ReactDOM.render(<Provider> <Addadress /> </Provider>, currentnode);
  }
  return(
    <>
      <a className="alert alert-success" role="alert" onClick={(e) => addressblock(e)}>
            <strong>
                <i className="fa fa-plus-circle" aria-hidden="true"></i> ADD A NEW ADDRESS
            </strong>
      </a>

      {isshow === true ? <Addadress /> : ''}
      
    </>
  );

}

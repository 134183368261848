import React, {useEffect , useState } from 'react';
import Productprice from '../Productprice/Productprice'
import Reviewstar from '../Reviewstar/Reviewstar'
import axios from "axios";
import * as Helper from '../../Helper';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

export default function Userwishlist(props) {

	const [wishlist, setwishlist] = useState([]);
	const [length, setLength] = useState(0);
	//User Info
	const [uid, setUid] = useState(localStorage.getItem('UID'));
	const [token, setToken] = useState(localStorage.getItem('token'));

    //API call
	let url = Helper.SITEURL+'admin/api/wish-list';
	const Apicall = () => {

		const formData = new FormData();  
		formData.append("customerid", uid);

		axios
		.post(url,formData , {
			headers: {
			  'Content-Type': 'multipart/form-data',
			  'Authorization': 'Bearer '+token
			}
		  })
		  .then((res) => {
			  if (res.data.success === true) {
				setwishlist(res.data.data);
			  }else{
				console.log('Failed to Upload Image.');
			  }
		  })
		  .catch((err) => {
				console.log('Error to Fetch');
		  });	       
	};
  
	useEffect(() => {
		Apicall();
	}, []);	

	useEffect(() => {
		setLength (wishlist.length);
	}, [wishlist]);	


	function wishlistfn(pid = ''){
        if (token === '' ||	token === undefined ||	token === null ) {
            props.signinShowHandler({show:true});
        } else {
            addwishlist(uid , pid);
        }
    }

    function addwishlist(uid = '' , pid = ''){
        
            if(uid !== '' && uid !== undefined && pid !== '' && pid !==undefined){

                const formData = new FormData();
                formData.append("customerid", uid);
                formData.append("productid", pid);

                axios
                    .post(Helper.SITEURL +'admin/api/wish-list-add/',formData , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+token
                    }
                    })
                    .then((res) => {
                        if (res.data.success === true) {
							const item = document.getElementById("product-"+pid);
							item.parentNode.parentNode.parentNode.parentNode.removeChild(item.parentNode.parentNode.parentNode);
							setLength (length- 1);
                            toastSuccess(res.data.message);
                        }else{
                        	toastError('ERROR');
                        }
                    })
                    .catch((err) => {
                        toastError('ERROR');
                    });
            }        
       
    }

  return(
            <>
            <div id="downloads" className="product-order tab-pane fade">
				<h3 className="title2">Wishlist ({length})</h3>
				<div className="list-view-items grid--view-items">

								{length <= 0 ? (<strong>No Product Found In Wish List</strong>) : ''}
						
								{wishlist !== undefined &&  wishlist.map((list, i) => { 
									return (

											<div className="list-product list-view-item">
													<div className="list-view-item__image-column">
														<div className="list-view-item__image-wrapper">
															<a href="product-layout-2.html">

															{(() => {
																let imgrow = []
																if(list.product_image[0] !== undefined && props.offerstatus !== '' )  {

																	imgrow.push(
																		<img
																		className="list-view-item__image blur-up ls-is-cached lazyloaded"
																		data-src={ Helper.SITEURL+'admin/picture/product/resize/450450/'+list.product_image[0]}
																		src={ Helper.SITEURL+'admin/picture/product/resize/450450/'+list.product_image[0]}
																		alt={ list.product_name}
																		title={ list.product_name}
																		/>
																	
																	)
																
																}else{

																	imgrow.push(
																		<img
																		className="list-view-item__image blur-up ls-is-cached lazyloaded"
																		data-src={ Helper.SITEURL+'admin/picture/product/resize/450450/'+list.product_image[0]}
																		src={ Helper.SITEURL+'admin/picture/product/resize/450450/'+list.product_image[0]}
																		alt={ list.product_name}
																		title={ list.product_name}
																		/>
																	
																	)
																}
																return imgrow
															})()}
																
															</a>
														</div>
													</div>
													<div className="list-view-item__title-column">
														<div className="h4 grid-view-item__title">
															<a href="product-layout-2.html"> <h1>{ list.product_name}</h1></a>
														</div>

														<Reviewstar level='2.2'/>

														{(() => {
															let row = []
															if(list.product_description.length > 500 ) {

																row.push(<p className="content" dangerouslySetInnerHTML={{__html: list.product_description.substring(0, 500)+' ....'}}></p>)
															  
															}else{

																row.push(<p className="content" dangerouslySetInnerHTML={{__html: list.product_description}}></p>)
															  }
															return row
														})()}
														
															

														{list.product_retail_price !== undefined && list.product_retail_price > 0 ? (<Productprice price ={list.product_retail_price} sale ={list.product_offer_percentage} offerstatus={list.product_offer_status} type='type2' />) : ''}

														<form className="variants" action="#">
															<button id={'product-'+list.id} onClick={() => wishlistfn(list.id)} className="btn btn--small" type="button">
																Remove
															</button>
														</form>
													</div>
												</div>
									)
								})}
												
								
												
				</div>
			</div>    						
            </>
  );

}

import React, { useEffect, useState, Suspense } from "react";
import {Navigation} from 'react-minimal-side-navigation';
import './Navbar.css';

export default function Navbar(props) {

  const [navmenu, setNavmenu] = useState([]);

  const navClick = (itemId) => {
      props.setCategoryHandler(itemId);
  };

  const navconst = () => {
      var menuitem = [];
      var lvl1 = new Array();

      for(let i = 0; i < props.cat.length; i++) {

        lvl1=new Array();
        lvl1['itemId']=props.cat[i].category_name;
        lvl1['elemBefore'] = () => props.cat[i].category_name;
        var lvl2 = new Array();
  
        for(let j = 0; j < props.cat[i].subcategory.length; j++) {
          
          lvl2[j] = new Array();
          lvl2[j]['itemId']= props.cat[i].category_name +'/'+ props.cat[i].subcategory[j].category_name;
          lvl2[j]['catId']= 
          lvl2[j]['elemBefore'] = () => props.cat[i].subcategory[j].category_name;
        }
        lvl1['subNav'] = lvl2;
         
         menuitem.push(lvl1);
     }


     setNavmenu(menuitem);
 
  };
  useEffect(() => {
      navconst();
  }, [props.cat]);

        return(
                  <>
                   {navmenu ? 
                    <Navigation
                      // you can use your own router's api to get pathname
                      onSelect={({itemId}) => {
                        navClick(itemId)
                      }}
                      items={navmenu}
                    />
                   : '' }
                  </>
        );

}
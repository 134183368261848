import React , { useState, useCallback , useEffect ,Suspense} from 'react';
import ReactDOM from 'react-dom';
import EditadressContainer  from '../../containers/EditaddressContainer'
import Editadress  from '../Editaddress/Editaddress'
import * as Helper from '../../Helper';

//Axios
import axios from 'axios';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";


 export default function Editaddressblock(props){

	const [token, setToken] = useState(localStorage.getItem('token'));
	const [uid, setuid] = useState(localStorage.getItem('UID'));

      const addressblock = (event) => {
        var addressId = event.currentTarget.attributes.getNamedItem('data-id').value;
        var currentnode =  event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        ReactDOM.render(<Editadress id={addressId} />, currentnode);
        
      }

	  const deleteaddress = (event) => {

        var addressId = event.currentTarget.attributes.getNamedItem('data-id').value;
		if (addressId) {

			const formData = new FormData();
			formData.append("customerid", uid);
			formData.append("addressid", addressId);

			axios
			.post(Helper.SITEURL +'admin/api/customer-address-delete/',formData , {
				headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer '+token
				}
			})
			.then((res) => {
				if (res.data.success === true) {
					toastSuccess(res.data.message);
					props.loadAddressHandler({show:true});
				}else{
				  	toastError(res.data.message);
				 
				}
			})
			.catch((err) => {
				console.log(err);
			});
			
		}
        
      }

	  const setaddress = (event) => {

			var addressid = event.target.value;
			var type = event.currentTarget.getAttribute('set');

			if (addressid !== undefined) {
                let deliverymethodurl = Helper.SITEURL+'admin/api/set-address';

                const formData = new FormData();
                formData.append("orderid", localStorage.getItem('orderid'));
                formData.append("addressid", addressid);

				if (type === 'delivery') {
					formData.append("addresstype", "delivery");
				}else{
					formData.append("addresstype", "billing");
				}
            
                axios
                    .post(deliverymethodurl,formData , {
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer '+token
						}
                    })
                    .then((res) => {
                    if (res.status === 200) {
                       
                    }else{
                        
                    }
                })
                .catch((err) => {
                    
                });  
        	}
      }

    return( 

                <>
					{props.info && 
                      <div className="card">
					  		<div className="card-body">
							  
							  {props.type && props.type ==='delivery' ? 
							  	
							  		<input checked={props.slected === props.info.id ? ( 'true' ) : ( 'false')} className='deliveryaddress' type="radio" name="deliveryaddress" value={props.info.id} set="delivery" onClick={(e) => setaddress(e)} />
							  : 
							  		<input checked={props.slected === props.info.id ? ( 'true' ) : ( 'false')} className='billingaddress' type="radio" name="billingaddress" value={props.info.id} set="billing"  onClick={(e) => setaddress(e)} />
							  
							  }

							                {(() => {

													let row = []
													if(props.type && props.type ==='delivery') {

														if(props.slected === props.info.id) {
														
															row.push(
																<input checked className='deliveryaddress' type="radio" name="deliveryaddress" value={props.info.id} set="delivery" onClick={(e) => setaddress(e)} />
															)
										
														}else{
														
															row.push(
																<input  className='deliveryaddress' type="radio" name="deliveryaddress" value={props.info.id} set="delivery" onClick={(e) => setaddress(e)} />
															)
										
														}

													}else{

															if(props.slected === props.info.id) {
																
																row.push(
																	<input checked className='billingaddress' type="radio" name="billingaddress" value={props.info.id} set="billing"  onClick={(e) => setaddress(e)} />
																)
											
															}else{
															
																row.push(
																	<input className='billingaddress' type="radio" name="billingaddress" value={props.info.id} set="billing"  onClick={(e) => setaddress(e)} />
																)
											
															}

													}
													return row;
													})()}
							  	
							  						<h2>
									  					{props.info.name}({props.info.contact})
														<span className="badge editAddress">
															<a className="edit-i remove" data-id={props.info.id} onClick={(e) => addressblock(e)}>
																<i className="anm anm-edit" aria-hidden="true"></i>
															</a>
															<a className="edit-i remove" data-id={props.info.id} onClick={(e) => deleteaddress(e)}>
																<i class="fa fa-trash" aria-hidden="true"></i>
															</a>
														</span>
													</h2>											
													<div>
														<strong>
														{props.info.address} , {props.info.city} , {props.info.state} -{props.info.pincode}
															
														</strong>
													</div>
							</div>					
					  </div>

					}						
                  </>
            )
} 

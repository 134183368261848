import React, {useEffect , useState , useCallback  } from 'react';
import Productprice from '../Productprice/Productprice';
import * as Helper from '../../Helper';
import { useHistory } from "react-router-dom";
import { BsFillBagPlusFill , BsFillBagDashFill } from "react-icons/bs";


import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";


//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";  

import axios from 'axios';  

export default function Product(props){


    let history = useHistory();
    //New
    const [Isnew, setIsnew] = useState(false);
    let today = new Date(),
    tdate =  (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
  
    //Sale
    const [Issale, setIssale] = useState(false);  

    const [logo, setlogo] = useState('');

    console.log(props.product);

    useEffect(() => {
        if (props.product.product_image[0]['large'] !== undefined ) {
            setlogo(props.product.product_image[0]['large']);
        }else{
            setlogo(Helper.SITEURL+'admin/picture/product/resize/500500/'+props.product.product_image[0]);
        }
        
    }, [props.product.product_image]);

    console.log(logo);


    useEffect(() => {
        if(props.product.created_at !== undefined ){

            const date1 = new Date(props.product.created_at);
            const date2 = new Date(tdate);
            const diff = Helper.getDifferenceInDays(date1, date2);

            if (diff <= props.settings.new_product_range ){
                setIsnew(true);
            }
        }
    },[props.product.created_at , props.settings.new_product_range ])

    useEffect(() => {
        if(props.product.product_offer_percentage !== undefined ){
            if (props.product.product_offer_percentage){
                setIssale(true);
            }
        }
    },props.product.product_offer_percentage , props.settings.new_product_range)

    //cart call
    const [addtocartInd, setaddtocartInd] = useState([])
    useEffect(() => {
        setaddtocartInd(props.product.cart);
    },[props.product.cart])

    //WISHLIST Modal call
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [uid, setuid] = useState(localStorage.getItem('UID'))

    const [wishlistInd, setwishlistInd] = useState([])
    useEffect(() => {
        setwishlistInd(props.product.wishlist);
    },[props.product.wishlist])

    
    function wishlist(pid = ''){
        if (token === '' ||	token === undefined ||	token === null ) {
            props.signinShowHandler({show:true});
        } else {
            addwishlist(uid , pid);
        }
    }

    function addwishlist(uid = '' , pid = ''){
        
            if(uid !== '' && uid !== undefined && pid !== '' && pid !==undefined){

                const formData = new FormData();
                formData.append("customerid", uid);
                formData.append("productid", pid);

                axios
                    .post(Helper.SITEURL +'admin/api/wish-list-add/',formData , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+token
                    }
                    })
                    .then((res) => {
                        if (res.data.success === true) {
                            if (wishlistInd === true) {
                                setwishlistInd(false);
                            }else{
                                setwishlistInd(true);
                                
                            }
                            toastSuccess(res.data.message);
                        }else{
                        	toastError('ERROR');
                        }
                    })
                    .catch((err) => {
                        toastError('ERROR');
                    });
            }        
       
    }

    function addtocart(slug){
        axios
            .get('https://geolocation-db.com/json/')
            .then((res) => {

                if (res.status ===200) {
                   
                    const formData = new FormData();
                    formData.append("carttype", 'ip');
                    formData.append("userip", res.data.IPv4);
                    formData.append("product_quantity", 1);
                    formData.append("product_slug", slug);
                    
              
                axios
                .post(Helper.SITEURL +'admin/api/add-to-cart/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {

                        props.setCartproductHandler(res.data.data.cart);
                        props.setcartPriceHandler(res.data.data.total);
                        toastSuccess(res.data.message);
                      
                    }else{

                        props.setcartPriceHandler([]);
                        props.setCartproductHandler([]);
                        toastError(res.data.message);
                      
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                });
                }
              
            })
            .catch((err) => {
                
            });
        
    }

    const pstyle = {
        'width': "100%",  
        'display': "inline-block"
    };

    const [col, setCol] = useState('');

    useEffect(() => {

        if (props.panel !== undefined ) {

            if (props.panel === 'newarrival') {
    
                setCol("col-6 col-sm-12 col-md-3 col-lg-3 item"); 
    
            }else if (props.panel === 'similer'){
                
    
                setCol("col-12 col-sm-12 col-md-12 col-lg-12 item"); 
    
            }else if (props.panel === 'filter'){
                
    
                setCol("col-6 col-sm-12 col-md-4 col-lg-3 item"); 
    
            }else if (props.panel === 'bestsale'){
                
    
                setCol("col-12 col-sm-12 col-md-12 col-lg-12 item"); 
    
            }

            
        }
        
      },[props.panel])
    
    let url = '/product/'+props.product.product_slug;

    return(
                        <>

                            <div className={col} style= {pstyle}>
                                    
                                    <div className="product-image">
                                       
                                        <a href="javascript:void(0)" className="grid-view-item__link" tabindex="0">
                                            
                                            <img className="primary" data-src={logo} src={logo} alt="image" title="product" />
                                            <div className="product-labels rounded">
                                                
                                            {Issale === true ? (
                                                <span className="lbl on-sale">Sale</span>
                                            ) : (
                                                ''
                                            )}

                                            {Isnew === true ? (
                                                <span className="lbl pr-label1">new</span>
                                            ) : (
                                                ''
                                            )}
                                                
                                            </div>
                                          
                                        </a>
                                        
                                        <form className="variants add" action="javascript:void(0)" method="post">
                                            
                                            <button value={url} className="btn btn-addto-cart" tupe="button"  onClick={(e) => {e.preventDefault(); window.location.href=e.target.value;}} >Buy Now</button>
                                           
                                        </form>
                                        <div className="button-set">
                                            <a href="javascript:void(0)" title="Add To Cart" onClick={() => addtocart(props.product.product_slug)}  className="quick-view-popup quick-view"tabindex="0">
                                                
                                                {(() => {
															 let row = []
															 if(addtocartInd === true ) {
                                                                row.push(<BsFillBagDashFill />)
															 }else{
                                                                row.push(<BsFillBagPlusFill />)
															 	  
															 }
															return row
												})()} 
                                            </a>
                                            <div className="wishlist-btn">
                                                <a className="wishlist add-to-wishlist" onClick={() => wishlist(props.product.id)} title="Add to Wishlist" tabindex="0">
                                                    
                                                {(() => {
															 let row = []
															 if(wishlistInd === true ) {
                                                                  row.push(<i class="fa fa-heart" aria-hidden="true"></i>)
															 }else{
															 	  row.push(<i class="fa fa-heart-o" aria-hidden="true"></i>)
															 }
															return row
												})()}      
                                                    
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                    
                                    <div className="product-details text-center">

                                        <div className="product-name">
                                            <a href={url} tabindex="0">{props.product.product_name}</a>
                                        </div>
                                        <div className="product-price">

                                            {props.product.product_retail_price !== undefined && props.product.product_retail_price > 0 ? (<Productprice price ={props.product.product_retail_price} sale ={props.product.product_offer_percentage} offerstatus={props.product.product_offer_status} type='type2' />) : ''}
                                            
                                        </div>
                                       
                                    </div>
                                   
                            </div>
 
                        </>
  );

}

import React, {Suspense, useEffect , useState  } from 'react';

export default function Productfiltercategorydescription(props) {

  const [banner , setBanner] = useState([]);
  useEffect(() => {
    setBanner(props.data.productBanner);
  }, [props.data.productBanner]);

  return(
    
    <>
        <div class="category-description">
            <h3>Category Description</h3>
            <p dangerouslySetInnerHTML={{ __html: banner.category_description}}></p>
        </div>
    </>
    
  );
}

import React, {Suspense, useEffect , useState  } from 'react';

export default function Productfilterbanner(props) {

  const [banner , setBanner] = useState([]);
  useEffect(() => {
    setBanner(props.data.productBanner);
  }, [props.data.productBanner]);

  return(
    
      <>
      {banner.category_banner !== undefined && banner.category_banner !== undefined && banner.category_banner !== '' &&
            <div class="collection-header mb-4">
                <div class="collection-hero">
                    <div class="collection-hero__image"><img class="blur-up lazyload" data-src={banner.category_banner ? banner.category_banner : ''} src={banner.category_banner ? banner.category_banner : ''} alt={banner.category_name ? banner.category_name : ''} title={banner.category_name ? banner.category_name : ''} /></div>
                    <div class="collection-hero__title-wrapper">
                        <h1 class="collection-hero__title page-width">{banner.category_name ? banner.category_name : ''}</h1>
                    </div>
                </div>
            </div> 
      } 
      </>
    
  );
}
import React from 'react';
import * as Helper from '../../Helper';

export default function Productprice(props){

  let saleprice = 0;
  saleprice = props.price - ((props.price * props.sale)/100);

  return(
                    <>

                              {props.type === 'type2' ? (

                                    <>
                                      {props.offerstatus !== undefined && props.offerstatus == 1 ? (
                                           <>
                                              <span className="old-price">{Helper.CURRENCY+props.price}</span>
                                              <span className="price">{Helper.CURRENCY+saleprice.toFixed(2)}</span> 
                                            </>
                                      ) : (
                                          <>
                                            <span className="price">{Helper.CURRENCY+props.price}</span> 
                                          </>
                                      )}
                                    </>
                                   
                              ) : (
                                <p className="product-single__price product-single__price-product-template">

                                    {props.offerstatus !== undefined && props.offerstatus == 1 ? (
                                      <>
                                            <span className="product-price__price product-price__price-product-template  product-price__sale--single">
                                                <span id="ProductPrice-product-template"><span className="money">{Helper.CURRENCY+saleprice.toFixed(2)}&nbsp;</span> </span>
                                            </span>
        
                                            <s id="ComparePrice-product-template"><span className="money">{Helper.CURRENCY+props.price}</span></s>
                                            <span className="off"> (<span>{props.sale}</span>%)</span>
                                      </>
                                    ) : (
                                      <>
                                            <span className="product-price__price product-price__price-product-template  product-price__sale--single">
                                                <span id="ProductPrice-product-template"><span className="money">{Helper.CURRENCY+props.price}</span></span>
                                            </span>

                                      </>
                                    )}
                                          
                                 </p> 
                                
                              )}

                    </>
  );

}

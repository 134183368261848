import React from 'react';
import ReactHtmlParser from 'html-react-parser';

export default function Bannerslide(props) {

        return(
                  <>
                    <div className="slide">
                        <div className="lazyload">
                            <img className="lazyload" data-src={props.sliderdata.slider_image} src={props.sliderdata.slider_image} alt="Slide" title="Slide" />
                            <div className="slideshow__text-wrap slideshow__overlay classNameic middle">
                                <div className="slideshow__text-content middle">
                                    <div className="container">
                                        <div className="wysiwyg">
                                        {ReactHtmlParser(props.sliderdata.slider_content)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </>
        );

}



import React, {useEffect , useState , Suspense , useCallback} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

//Component
import Topbanner from '../../containers/TopbannerContainer';
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import Deliverybanner from '../Deliverybanner/Deliverybanner';
import Loader from '../Loader/Loader';
import { useParams } from "react-router-dom";
import * as Helper from '../../Helper';
import $, { data } from 'jquery'; 

import card from '../../assets/images/payment-img.jpg';
import Productprice from '../Productprice/Productprice';

import './Cart.css';

import axios from 'axios';
import { array } from 'yup/lib/locale';

import { FaCartArrowDown } from "react-icons/fa";



export default function Cart(props){

    const params = useParams();

    const [loaderState, setLoaderState] = useState(true);
    const [Sitesettings , setSitesettings] = useState([]);

        //API call
        let url = Helper.SITEURL+'admin/api/settings';
        const Apicall = () => {
          axios
              .get(url)
              .then((res) => {
                  if (res.status === 200) {
  
                    setLoaderState(false);
                    if(res.data.data !== undefined) {
                      setSitesettings(res.data.data);
                    }
                  
                  }else{
                    setLoaderState(true);
                    setSitesettings([]);
                  }
              })
              .catch((err) => {
                  setLoaderState(true);
                  setSitesettings([]);
              });
        };
  
        useEffect(()=>{
          Apicall();
        },[]);


        //cart product
        const [cartproducts , setproducts] = useState([]);
        useEffect(() => {
          setproducts(props.data.cartProducts);
        }, [props.data.cartProducts]);  
        
        const [cartprice , setcartprice] = useState([]);
        useEffect(() => {
          setcartprice(props.data.cartPrice);
        }, [props.data.cartPrice]);  


      //cart update Product
      const Updatecartproductcall = (data) => {

        axios
        .get('https://geolocation-db.com/json/')
        .then((res) => {

            if (res.status ===200) {

                  const formData = new FormData();
                  formData.append("carttype", 'ip');
                  formData.append("userip", res.data.IPv4);
                  formData.append("cartid", data['cid']);
                  formData.append("product_quantity", data['qut']);


                  let cartproducturl = Helper.SITEURL+'admin/api/add-to-cart';
                    axios
                    .post(cartproducturl,formData , {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': '*'
                      }
                    })
                    .then((res) => {

                      if (res.status === 200) {
                          if(res.data.data !== undefined) {
                            props.setCartproductHandler(res.data.data.cart);
                            props.setcartPriceHandler(res.data.data.total);
                          }
                      }else{
                        props.setCartproductHandler([]);
                        props.setcartPriceHandler([]);
                      }
                  })
                  .catch((err) => {
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                  });        
              
            }
          
        })
        .catch((err) => {
            
        });

      };

      function incrementValue(e) {
        e.preventDefault();
        var attr = base64_decode(e.currentTarget.dataset.id); 
        var value = document.getElementById("qty-"+attr).value;
        var cartid = document.getElementById("qty-"+attr).getAttribute('cid');
        cartid = base64_decode (cartid);

        const setdata = new Array();
        setdata['cid'] = cartid;
        setdata['qut'] = parseInt(value)+parseInt(1);

        Updatecartproductcall(setdata);

      }

      function decrementValue(e) {
        e.preventDefault();
        var attr = base64_decode(e.currentTarget.dataset.id); 
        var value = document.getElementById("qty-"+attr).value;
        var cartid = document.getElementById("qty-"+attr).getAttribute('cid');
        cartid = base64_decode (cartid);

        const setdata = new Array();
        setdata['cid'] = cartid;
        setdata['qut'] = parseInt(value)-parseInt(1);

        Updatecartproductcall(setdata);
      }

      function refreshCart(e) {
        
        axios
        .get('https://geolocation-db.com/json/')
        .then((res) => {

            if (res.status ===200) {

                  const formData = new FormData();
                  formData.append("carttype", 'ip');
                  formData.append("userip", res.data.IPv4);
                  
                  let cartproducturl = Helper.SITEURL+'admin/api/go-to-cart';
                    axios
                    .post(cartproducturl,formData , {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })
                    .then((res) => {

                      if (res.status === 200) {
                          if(res.data.data !== undefined) {
                            props.setCartproductHandler(res.data.data.cart);
                            props.setcartPriceHandler(res.data.data.total);
                          }
                      }else{
                        props.setCartproductHandler([]);
                        props.setcartPriceHandler([]);
                      }
                  })
                  .catch((err) => {
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                  });        
              
            }
          
        })
        .catch((err) => {
            
        });
      }

      function removeProduct(e) {
        e.preventDefault();
        var cartid = e.currentTarget.dataset.id;
        cartid = base64_decode (cartid);

        const formData = new FormData();
        formData.append("cartid", cartid);
       
        if (cartid !== undefined && cartid !== '') {
                  let cartproducturl = Helper.SITEURL+'admin/api/remove-from-cart';
                    axios
                    .post(cartproducturl,formData , {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })
                    .then((res) => {

                      if (res.status === 200) {
                          if(res.data.data !== undefined) {
                            props.setCartproductHandler(res.data.data.cart);
                            props.setcartPriceHandler(res.data.data.total);
                          }
                      }else{
                        props.setCartproductHandler([]);
                        props.setcartPriceHandler([]);
                      }
                  })
                  .catch((err) => {
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                  }); 
        }

      }

      //WISHLIST Modal call
      const [token, setToken] = useState(localStorage.getItem('token'))
      const [uid, setuid] = useState(localStorage.getItem('UID'))

      function checkoutprocess(pid = ''){
        if (token === '' ||	token === undefined ||	token === null ) {
            props.signinShowHandler({show:true});
        } else {

          axios
          .get('https://geolocation-db.com/json/')
          .then((res) => {

              if (res.status ===200) {

                    const formData = new FormData();
                    formData.append("userip", res.data.IPv4);
                    formData.append("userid", uid);
                    
                    let cartproducturl = Helper.SITEURL+'admin/api/checkout';
                      axios
                      .post(cartproducturl,formData , {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': 'Bearer '+token
                        }
                      })
                      .then((res) => {

                        if (res.status === 200) {

                            if(res.data.data.order[0] !== undefined) {
                              
                              localStorage.setItem('orderid', res.data.data.order[0].orderid);
                              window.location.href = "/checkout/";
                              
                            }
                        }else{
                          props.setCartproductHandler([]);
                          props.setcartPriceHandler([]);
                        }
                    })
                    .catch((err) => {
                      props.setCartproductHandler([]);
                      props.setcartPriceHandler([]);
                    });        
                
              }
            
          })
          .catch((err) => {
              
          });
        }
      }

      console.log (cartproducts);

  return(
        <> 
          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          {cartproducts !== undefined && cartproducts.length > 0 ?
          <>
          <div id="page-content">
            {/* <!--Page Title--> */}
            {/* <!--Body Content--> */}
            <div id="page-content">
                {/* <!--Page Title--> */}
                <div className="page section-header text-center">
                    <div className="page-title">
                        <div className="wrapper">
                            <h1 className="page-width">Your cart</h1>
                        </div>
                    </div>
                </div>
                {/* <!--End Page Title--> */}

                <div className="container cartcontainer">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 main-col">
                            <form action="#" method="post" className="cart style2">
                                <table>
                                    <thead className="cart__row cart__header">
                                        <tr>
                                            <th colspan="2" className="text-center">Product</th>
                                            <th className="text-center">Price</th>
                                            <th className="text-center">Quantity</th>
                                            <th className="text-right">Total</th>
                                            <th className="action">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {cartproducts !== undefined && cartproducts.map((item ,key)=>{ 
                                      return(
                                      <tr className="cart__row border-bottom line1 cart-flex border-top" key={key}>
                                          <td className="cart__image-wrapper cart-flex-item">
                                              <a href={Helper.SITEURL+'product/'+item.productslug}><img style={{width:'95px'}} className="cart__image"
                                                      src={item.productimage}
                                                      alt={item.productname} /></a>
                                          </td>
                                          <td className="cart__meta small--text-left cart-flex-item">
                                              <div className="list-view-item__title">
                                                  <a href={Helper.SITEURL+'product/'+item.productslug}>{item.productname}</a>
                                              </div>
                                              <div className="cart__meta-text">
                                                  Varrient: {item.productvarient} <br />
                                              </div>
                                          </td>
                                          <td className="cart__price-wrapper cart-flex-item">
                                              <div className='product-price'>
                                              {item.setprice !== undefined && item.setprice > 0 ? (<Productprice price ={item.mainprice} sale ={item.offerpercentage} offerstatus={(item.offerstatus === 1) ? true:false} type='type2' />) : ''} 
                                              </div>
                                              
                                          </td>
                                          <td className="cart__update-wrapper cart-flex-item text-right">
                                              <div className="cart__qty text-center">
                                                  <div className="qtyField">
                                                      <a className="qtyBtn minus" href="javascript:void(0);" data-id={base64_encode(key)} onClick={decrementValue}>
                                                        <i className="icon icon-minus"></i>
                                                      </a>
                                                      <input className="cart__qty-input qty" type="text" name="updates[]"
                                                          id={'qty-'+key} value={item.productquantity} pattern="[0-9]*" min="1" cid = {base64_encode(item.id)} />
                                                      <a className="qtyBtn plus" href="javascript:void(0);" data-id={base64_encode(key)} onClick={incrementValue}>
                                                        <i className="icon icon-plus"></i>
                                                      </a>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className="text-right small--hide cart-price">
                                              <div><span className="money">{Helper.CURRENCY+parseFloat(item.totalprice).toFixed(2)}</span></div>
                                          </td>
                                          <td className="text-center small--hide"><a onClick={removeProduct} data-id={base64_encode(item.id)} href="javascript:void(0);"
                                                  className="btn btn--secondary cart__remove" title="Remove tem"><i
                                                      className="icon icon anm anm-times-l"></i></a>
                                          </td>
                                        </tr>
                                        )
                                    })}
                                        
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="3" className="text-left"><a href={'/product/&pg=1&lt='+Sitesettings.product_limit}
                                                    className="btn--link cart-continue"><i
                                                        className="icon icon-arrow-circle-left"></i> Continue shopping</a>
                                            </td>
                                            <td colspan="3" className="text-right"><a href='javascript:void(0);' onClick={refreshCart} name="update"
                                                    className="btn--link cart-update"><i className="fa fa-refresh"></i>
                                                    Update</a></td>
                                        </tr>
                                    </tfoot>
                                </table>


                            </form>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 cart__footer">
                            <div className="cart-note">
                                <div className="solid-border">
                                    <form action="#" method="post">
                                        <div className="input-group">
                                            <input type="email" className="input-group__field newsletter__input"
                                                name="EMAIL" value="" placeholder="Enter Coupon Code" required="" />
                                            <span className="input-group__btn">
                                                <button type="submit" className="btn newsletter__submit" name="commit"
                                                    id="Subscribe"><span
                                                        className="newsletter__submit-text--large">APPLY</span></button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div className="solid-border">
                                    <div className="row">
                                        <span className="col-12 col-sm-6 cart__subtotal-title"><strong>Subtotal</strong></span>
                                        <span className="col-12 col-sm-6 cart__subtotal-title cart__subtotal text-right"><span
                                                className="money">{Helper.CURRENCY+parseFloat(cartprice.totalgrossamount).toFixed(2)}</span></span>
                                    </div>
                                    <div className="cart__shipping">Shipping &amp; taxes calculated at checkout</div>
                                    <button onClick={checkoutprocess} name="checkout" id="cartCheckout" class="btn btn--small-wide checkout">checkout</button>
                                    <div className="paymnet-img"><img src={card} alt="Payment" /></div>
                                </div>

                            </div>
                            
                        </div>
                        <Deliverybanner/>
                    </div>
                </div>

            </div>
            {/* <!--End Body Content--> */}

          </div>
          </>
          : 
          <>
            <div id="page-content">
            <div class="info-box infocontainer">
              
            <FaCartArrowDown/>  No Product Into The Busket
            </div>
            </div>
          </>
          
          }
          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings} type = 'productdetilas'/> : ''}
        </>
  );

}

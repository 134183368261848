import React, { useEffect, useState, Suspense } from "react";
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import Addnewaddress  from '../../containers/AddnewaddressContainer'
import Editaddressblock from '../../containers/EditaddressblockContainer'
import axios from "axios";
import * as Helper from "../../Helper";


export default function Useraddress(props) {

	const [uid, setUid] = useState(localStorage.getItem('UID'));
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [addresslist, setAddresslist] = useState([]);


	//user info call
	const Apiuseraddress = () => {

		setToken(localStorage.getItem('token'));

		const formData = new FormData();
		formData.append("customerid", uid);

		  axios
		  .post(Helper.SITEURL +'admin/api/customer-address-list/',formData , {
			headers: {
			  'Content-Type': 'multipart/form-data',
			  'Authorization': 'Bearer '+token
			}
		  })
		  .then((res) => {

			  if (res.data.success === true) {
					setAddresslist((res.data.data));
			  }else{
				    setAddresslist([])
			  }
		  })
		  .catch((err) => {
			  console.log(err);
		  });
	};

	useEffect(() => {
		Apiuseraddress();
	}, [token]);

	useEffect(() => {
		if (props.data.loadAddressCall.show === true) {
			Apiuseraddress();
		}
	}, [props.data.loadAddressCall , token]);

	
  return(
            <>
            				<div id="address" className="address tab-pane">
							<h3 className="title2">{props.type && props.type ==='delivery' ? 'Delivery Address' : 'Billing Address'}</h3>
									
											<div>
												<Addnewaddress />
											</div>

											{ addresslist !== '' && addresslist !== undefined &&  addresslist.map((sdata, i) => { 
												return (<div> <Editaddressblock info={sdata} type = {props.type}  slected = {props.slected}/></div>)
											})}
										
											
							</div>													
											
            </>
  );

}



                    

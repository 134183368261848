import React, {useEffect , useState , Suspense , useCallback} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

//Component
import Topbanner from '../../containers/TopbannerContainer';
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import Deliverybanner from '../Deliverybanner/Deliverybanner';
import Loader from '../Loader/Loader';
import { useParams } from "react-router-dom";
import * as Helper from '../../Helper';
import $ from 'jquery'; 

//FORM
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


import success from '../../assets/images/collection/check.png';
import failed from '../../assets/images/collection/error.png';

import { FaBan } from "react-icons/fa";


import axios from 'axios';

export default function Passwordreset(props) {

  const params = useParams();

  const [loaderState, setLoaderState] = useState(true);
  const [token, setstate] = useState(localStorage.getItem('token'))
  const [Sitesettings , setSitesettings] = useState([]);
  const [uid, setuid] = useState(localStorage.getItem('UID'));

        //API call
        let url = Helper.SITEURL+'admin/api/settings';
        const Apicall = () => {
          axios
              .get(url)
              .then((res) => {
                  if (res.status === 200) {
  
                    setLoaderState(false);
                    if(res.data.data !== undefined) {
                      setSitesettings(res.data.data);
                    }
                  
                  }else{
                    setLoaderState(true);
                    setSitesettings([]);
                  }
              })
              .catch((err) => {
                  setLoaderState(true);
                  setSitesettings([]);
              });
        };
  
        useEffect(() => {
            Apicall();
        }, [setSitesettings]);


        const [verify , setverify] = useState(false);
        const [verificationtoken , setverificationtoken] = useState([]);
        //Order Response
        const TokenVerification = () => {

          if (params.token !== '' ) {

            const formData = new FormData();
            formData.append("token", params.token);
           
            axios
            .post(Helper.SITEURL +'admin/api/token-verify/',formData , {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((res) => {
                if (res.data.success === true) {
                  
                  setverify(true);
                  setcid(res.data.data[0].id);
                  setverificationtoken(res.data.data[0].token);
                
                }else{
                  setverify(false);
                  setcid('');
                }
            })
            .catch((err) => {
                setverify(false);
                setcid('');
            });
            
          }

             
        };
  
        useEffect(() => {
          TokenVerification();
        }, [params.token]);


    //Password Validation
		const validationSchema = Yup.object().shape({
			newpassword: Yup.string()
				.required('Please Enter your New Password.'),
				// .matches(
				//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				//     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
				// ),
			confirmpassword: Yup.string()
				.required('Please Enter your Confirm Password.')
				.oneOf([Yup.ref("newpassword"), null], "Passwords must match")
		
		});


	const formOptions = { resolver: yupResolver(validationSchema) };

	//get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');
    const [cid, setcid] =  useState('');

	//API call REGISTRATION
    function onSubmit(data) {



            if(data.newpassword !== undefined && data.newpassword !== '' && cid !== undefined && cid !== '' && verificationtoken !== undefined && verificationtoken !== '' ){
    
              const formData = new FormData();
            
              formData.append("customerid", cid);
              formData.append("newpassword", data.newpassword);
              formData.append("confirmpassword", data.confirmpassword);
              formData.append("token", verificationtoken);

              
            
                axios
                .post(Helper.SITEURL +'admin/api/reset-password/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
					          'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {
                        setShowSuccess(true);
                        setMessage(res.data.message);
                        const interval = setInterval(() => {
                          window.location.href ='/'
                        }, 3000);

                    }else{
                        setShowError(true);
                        setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    
            }
            return false;
    }

    const successcontainer = {
      'paddingLeft': '15px',
      'paddingRight': '15px'
    };

    const successstyle = {
      'textAlign' : "center",
      'float': 'left'
    };


    console.log(verify);


  return(
          <>

          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}

          {(() => {

              let row = []
              if(verify === true) {

                row.push(
                  <div class="container" style={{marginTop: "2%" , width: '25%'}}>
                    <div class="col-md-12">
                            <h2 style={{textAlign: "center"}}>Reset Your Password</h2>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                        
                                        { showSuccess ?
                                        <div className="row" style = {successcontainer}>
                                            <div id="success" className="alert alert-success" style = {successstyle}>
                                                <strong>{message}</strong>
                                            </div>
                                        </div>
                                        : '' }
                                        { showError ?
                                        <div className="row" style = {successcontainer}>
                                            <div id="error" className="alert alert-danger" style = {successstyle}>
                                                <strong>{message}</strong>
                                            </div>
                                        </div>
                                        : '' }    

                                        <div className="form-group">
                                            <input {...register('newpassword' , { required: true })} className={`form-control ${errors.newpassword ? 'invalid' : ''}`} type="password" name="newpassword" placeholder="New Password" />
                                            <div className="invalid-feedback">{errors.newpassword?.message}</div>
                                        </div>
                                        <div className="form-group">
                                            <input {...register('confirmpassword' , { required: true })} className={`form-control ${errors.confirmpassword ? 'invalid' : ''}`} type="password" name="confirmpassword" placeholder="Confirm Password" />
                                            <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                                        </div>
                                       

                                        <input className="btn btn-info btn-block btn-round" type="submit" value="Submit" />
                                        
                            </form>

                          
                    </div>
                  </div>
                )

              }else{

                row.push(
                  <>
                    <div id="page-content">
                      <div class="alert-box infocontainer">
                        <FaBan/> Invalid Token Please try it again.
                      </div>
                    </div>
                  </>
                )
              }

              return row;
          })()}

          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings} type = 'productdetilas'/> : ''}

          </>
  );

}


import UserProfile from '../components/UserProfile/UserProfile';
import {connect} from 'react-redux';
import {profileImageUpdate} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    setimageHandler:(data) => dispatch(profileImageUpdate(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(UserProfile)
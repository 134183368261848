import Useraccount from './../components/Useraccount/Useraccount'
import {connect} from 'react-redux'
import {profileImageUpdate} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps = (dispatch) => {
    return {
      setimageHandler:(data) => dispatch(profileImageUpdate(data)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Useraccount)
import React , { useState, useCallback , useEffect} from 'react';
import wplogo from '../../assets/images/whatsapp.png';
import { Modal ,Button, Form  } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PhoneInput , { getCountryCallingCode } from 'react-phone-number-input';
import * as Helper from '../../Helper';


//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

//Facebook Login
import FacebookLogin from 'react-facebook-login';
//Google Login
import { GoogleLogin } from 'react-google-login';


//CSS
import 'react-phone-number-input/style.css';

//Axios
import axios from 'axios';

export default function Topbanner(props) {

    const [token, setstate] = useState(localStorage.getItem('token'));
    const [value, setValue] = useState();


    //Registration Validation

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please Enter Your Name.'),
        contact: Yup.string()
            .required('Please Enter Your Valid Contact Number.'),
        email: Yup.string()
            .required('Please Enter Your Email.')
            .email('Invalid Email.'),   
        password: Yup.string()
            .required('Please Enter your Password.'),
            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            // ),
        confirmpassword: Yup.string()
            .required('Please Enter your Confirm Password.')
            .oneOf([Yup.ref("password"), null], "Passwords must match")
    
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    //get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');


    //API call REGISTRATION
    function onSubmit(data) {

            if(data.email!== undefined && data.email !== ''){

              const contactno_array = data.contact.split(' ');
              let countrycode = contactno_array.splice(0, 1);
              let contact = contactno_array.join('');

              const formData = new FormData();
            
              formData.append("name", data.name);
              formData.append("email", data.email);
              formData.append("contact", contact);
              formData.append("password", data.password);
              formData.append("confirm_password", data.confirmpassword);
              formData.append("logintype", 'normal');
              formData.append("international_calling_code", countrycode);
              
              
                axios
                .post(Helper.SITEURL +'admin/api/customer-registration/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {
                      //setShowSuccess(true);
                      toastSuccess(res.data.message);
                      setMessage(res.data.message);
                      reset();
                      localStorage.setItem("token", res.data.data[0].token);
                      localStorage.setItem("profileImage", res.data.data[0].profile_image);
                      localStorage.setItem("UID", res.data.data[0].id);
                      localStorage.setItem("uname", res.data.data[0].name);
                      const interval = setInterval(() => {
                        window.location.reload();
                      }, 2000);
                      
                    }else{
                      //setShowError(true);
                      toastError(res.data.message);
                      setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    
            }
            return false;
    }
    
    useEffect(() => {
          const interval = setInterval(() => {
            setShowSuccess(false);
            setShowError(false);
          }, 10000);
    }, [showSuccess ,showError,message]);


    //Login Validation
   
    const loginvalidation = Yup.object().shape({
            logemail: Yup.string()
                .required('Please Enter Your User ID.'),

            logpassword: Yup.string()
                .required('Please Enter your Password.')
           
    });

    const loginformOptions = { resolver: yupResolver(loginvalidation) };

    const {
        register: login,
        formState: { errors: loginerrors },
        handleSubmit: loginhandleSubmit,
        reset: loginreset,
      } = useForm(loginformOptions);

    const [loginshowSuccess, setLoginShowSuccess] = useState(false);
    const [loginshowError, setLoginShowError] =  useState(false);
    const [loginmessage, setLoginMessage] =  useState('');


    //API Call Login
    function onloginSubmit(data) {

            if(data.logemail!== undefined && data.logemail !== ''&& data.logpassword!== undefined && data.logpassword!== ''){
    
              const formData = new FormData();
              
              formData.append("username", data.logemail);
              formData.append("password", data.logpassword);
              formData.append("logintype", 'normal');
              
                axios
                .post(Helper.SITEURL +'admin/api/customer-login/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                      //setLoginShowSuccess(true);
                      toastSuccess(res.data.message);
                      loginreset();
                      setLoginMessage(res.data.message);
                      localStorage.setItem("token", res.data.data[0].token);
                      localStorage.setItem("profileImage", res.data.data[0].profile_image);
                      localStorage.setItem("UID", res.data.data[0].id);
                      localStorage.setItem("uname", res.data.data[0].name);
                      const interval = setInterval(() => {
                        window.location.reload();
                      }, 2000);
                      
                    }else{
                      //setLoginShowError(true);
                      toastError(res.data.message);
                      setLoginMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    
            }
            return false;
    }
    
    useEffect(() => {
          const interval = setInterval(() => {
            setLoginShowSuccess(false);
            setLoginShowError(false);
          }, 2000);
    }, [loginshowSuccess, loginshowError]);


    //Facebook Login
    const responseFacebook = (response) => {

        if(response.id){

              const formData = new FormData();
              formData.append("userid", response.id);
              formData.append("logintype", 'facebook');
              formData.append("name", response.name);
              formData.append("profile_image", response.picture.data.url);

                axios
                .post(Helper.SITEURL +'admin/api/customer-login/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                      toastSuccess(res.data.message);
                      //setLoginShowSuccess(true);
                      loginreset();
                      setLoginMessage(res.data.message);
                      localStorage.setItem("token", res.data.data[0].token);
                      localStorage.setItem("profileImage", res.data.data[0].profile_image);
                      localStorage.setItem("UID", res.data.data[0].id);
                      localStorage.setItem("uname", res.data.data[0].name);
                      const interval = setInterval(() => {
                        window.location.reload();
                      }, 1000);
                      
                    }else{
                      //setLoginShowError(true);
                      toastError(res.data.message);
                      setLoginMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        }
    }
    //Google Login
    const responseGoogle = (response) => {
        if(response.Ba){
            const formData = new FormData();
            formData.append("userid", response.Ba);
            formData.append("logintype", 'google');
            formData.append("email", response.profileObj.email);
            formData.append("name", response.profileObj.givenName+' '+response.profileObj.familyName);
            formData.append("profile_image", response.profileObj.imageUrl);
            
              axios
              .post(Helper.SITEURL +'admin/api/customer-login/',formData , {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((res) => {
                  if (res.data.success === true) {
                    //setLoginShowSuccess(true);
                    toastSuccess(res.data.message);
                    loginreset();
                    setLoginMessage(res.data.message);
                    localStorage.setItem("token", res.data.data[0].token);
                    localStorage.setItem("profileImage", res.data.data[0].profile_image);
                    localStorage.setItem("UID", res.data.data[0].id);
                    localStorage.setItem("uname", res.data.data[0].name);
                    const interval = setInterval(() => {
                      window.location.reload();
                    }, 1000);
                    
                  }else{
                    //setLoginShowError(true);
                    toastError(res.data.message);
                    setLoginMessage(res.data.message);
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
      }
    }

    //Site Css
    
    const successstyle = {
        'text-align' : "center",
        'float': 'left'
    };
    
    const successcontainer = {
        'paddingLeft': '15px',
        'paddingRight': '15px'
    };

    //Login
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(props.data.SigninModalCall.show)
    }, [props.data.SigninModalCall.show]);

    //signup
    const [sshow, setSshow] = useState(false);
    const handlesShow = () => {
        setSshow(true);
    };
    const handlesHide = () => {
        setSshow(false)
    };

    //search
    const [issearch, setIssearch] = useState(false);

    useEffect(() => {
        setIssearch(props.data.showSearch.show);
    }, [props.data.showSearch.show]);

    //Product SEARCH
    const [searchresult, setSearchresult] = useState([]);
    const productsearch = (event) => {

        var search = event.currentTarget.value;
        if(search !== ''){

            const formData = new FormData();
            formData.append("key", search);

            axios
            .post(Helper.SITEURL +'admin/api/auto-complete/' ,formData , {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((res) => {

                if (res.data.success === true) {

                    if (res.data.data !== undefined && res.data.data !== null ) {
                        setSearchresult(res.data.data)
                    }
                  
                }else{
                  
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    //forgotpassword
    const [forgotpasswod, setforgotpasswod] = useState(false);
    useEffect(() => {
        setforgotpasswod(props.data.forgotModalCall.show)
    }, [props.data.forgotModalCall.show]);


    //forgot Password
       
    const forgotvalidation = Yup.object().shape({
            femail: Yup.string()
                .required('Please Enter a Valid Email.'),
           
    });

    const forgotformOptions = { resolver: yupResolver(forgotvalidation) };

    const {
        register: forgot,
        formState: { errors: forgoterrors },
        handleSubmit: forgothandleSubmit,
        reset: forgotreset,
      } = useForm(forgotformOptions);

    const [forgotshowSuccess, setForgotShowSuccess] = useState(false);
    const [forgotshowError, setForgotShowError] =  useState(false);
    const [forgotmessage, setForgotMessage] =  useState('');


    //API Call Login
    function onforgotSubmit(data) {

            if(data.femail!== undefined && data.femail !== ''){
    
              const formData = new FormData();
              
              formData.append("customeremail", data.femail);
              
              
                axios
                .post(Helper.SITEURL +'admin/api/email-verify/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                      
                        setForgotMessage(res.data.message);
                        setForgotShowSuccess(true);
                      
                    }else{
                        
                        setForgotMessage(res.data.message);
                        setForgotShowError(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    
            }
            return false;
    }



  return(
    <>
      {issearch === true ? 
      
        <div className="search search--opened" display="none" >
            <div className="search__form">
                <form className="search-bar__form" action="#">
                    <button className="go-btn search__button" type="submit"><i className="icon anm anm-search-l"></i></button>
                    <input className="search__input" type="search" name="q" placeholder="Search entire store..." aria-label="Search" autocomplete="off" onKeyUp={productsearch} />
                    <>
                        <ul className='searchResult'>
                        {searchresult.productlist !== null && searchresult.productlist !== undefined && searchresult.productlist.map((item ,key)=>{ 
                            
                            return(
                                <li> 
                                    <div >
                                        <a href={Helper.SITEURL+'product/'+item.product_slug}  className="">
                                            <div className="productimage">
                                                <div className="">
                                                    <img src={Helper.SITEURL+'admin/picture/product/resize/500500/'+item.product_image[0]} alt={item.product_name} width="50" height="50"></img>
                                                </div>
                                            </div>
                                            <div className="productcontent">
                                                <span className="">{item.product_name}</span>
                                                <div className="">in {item.product_category}</div>
                                            </div>
                                        </a>
                                    </div> 
                                </li>
                             )                   
                        })}
                        </ul>
                    </>
                </form>
                <button type="button" className="search-trigger close-btn" onClick={()=>{props.searchShowHandler({show:false})} }><i className="anm anm-times-l"></i></button>
            </div>
        </div>

      : ''}
      
      <div className="top-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 col-sm-8 col-md-5 col-lg-4">

                        <p className="phone-no"><img src={wplogo} style={{ width: "21px"}} /> {props.settings.sale_contact}</p>
                    </div>

                    <div className="col-2 col-sm-4 col-md-3 col-lg-4 text-right ml-auto">
                        <span className="user-menu d-block d-lg-none" onClick={()=>{props.signinShowHandler({show:true})} } ><i className="anm anm-user-al"
                                aria-hidden="true"></i></span>
                        <ul className="customer-links list-inline">

                            { token === '' ||	token === undefined ||	token === null ?

                                <>
                                    <li><a href="javascript:void(0)" onClick={()=>{props.signinShowHandler({show:true})} }>Login</a></li>
                                    <li><a href="javascript:void(0)" onClick={handlesShow} >SignUp</a></li> 
                                </>            
                            :  
                                ''
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={show}>
            <>
                
                    <div className="modal-header border-bottom-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{props.signinHideHandler({show:false})}
                        }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-title text-center">
                            <h4>Login</h4>
                        </div>
                        <div className="d-flex flex-column text-center">
                                    <form onSubmit={loginhandleSubmit(onloginSubmit)}>
                                                { loginshowSuccess ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="success" className="alert alert-success" style = {successstyle}>
                                                        <strong>{loginmessage}</strong>
                                                    </div>
                                                </div>
                                                : '' }
                                                { loginshowError ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="error" className="alert alert-danger" style = {successstyle}>
                                                        <strong>{loginmessage}</strong>
                                                    </div>
                                                </div>
                                                : '' }                
                                                
                                        <div className="form-group">
                                                <input {...login('logemail' , { required: true })} className={`form-control ${loginerrors.logemail ? 'invalid' : ''}`} type="text" name="logemail" placeholder="Email/Contact" />
                                                <div className="invalid-feedback">{loginerrors.logemail?.message}</div>
                                        </div>
                                        <div className="form-group">
                                                <input {...login('logpassword' , { required: true })} className={`form-control ${loginerrors.logpassword ? 'invalid' : ''}`} type="password" name="logpassword" placeholder="Password" />
                                                <div className="invalid-feedback">{loginerrors.logpassword?.message}</div>
                                        </div>
                                    <input className="btn btn-info btn-block btn-round" type="submit" value="Login" />
                                </form>
                                <div className="text-center text-muted delimiter"><a href='javascript:void(0)' onClick={()=>{ props.signinHideHandler({show:false}); props.forgotShowHandler({show:true});  } }>FORGET PASSWORD?</a></div>
                                <div className="text-center text-muted delimiter">or use a social network</div>

                                    <div style={{display: 'flex',flexWrap: 'wrap' }} >                           
                                            <FacebookLogin
                                                appId="941918019772530"
                                                cssClass="btnFacebook"
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                scope="public_profile,user_friends"
                                                callback={responseFacebook}
                                                icon="fa-facebook" 
                                            />
                                            
                                            <GoogleLogin
                                                clientId="89607273877-sken77akdvdsuoi9tlp759aiqumd160d.apps.googleusercontent.com"
                                                cssClass="btnFacebook"
                                                buttonText="Login With"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                                icon={false}
                                                className="btnGoogle"
                                            >
                                                <i className="fab fa-google" style={{ marginLeft: '8px' }}></i>
                                                
                                                <span>&nbsp;&nbsp;Sign In with Google</span>   
                                            </GoogleLogin>
                                            
                                    </div>
                                </div>
                    </div>

                    <div className="modal-footer d-flex justify-content-center">
                        <div className="signup-section">Not a member yet? <a href="javascript:void(0)" onClick={()=>{ props.signinHideHandler({show:false});  handlesShow();} } className="text-info"> Sign Up</a>.</div>
                    </div>
                
            </>
            
        </Modal>

        <Modal show={sshow}>
            <>
                
                    <div className="modal-header border-bottom-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handlesHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-title text-center">
                            <h4>Sign Up</h4>
                        </div>
                        <div className="d-flex flex-column text-center">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        
                                                { showSuccess ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="success" className="alert alert-success" style = {successstyle}>
                                                        <strong>{message}</strong>
                                                    </div>
                                                </div>
                                                : '' }
                                                { showError ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="error" className="alert alert-danger" style = {successstyle}>
                                                        <strong>{message}</strong>
                                                    </div>
                                                </div>
                                                : '' }    
                                                <div className="form-group">
                                                    <input {...register('email' , { required: true })} className={`form-control ${errors.email ? 'invalid' : ''}`} type="email" name="email" placeholder="Email" />
                                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                    <PhoneInput {...register('contact' , { required: true, min: 10 })}  name="contact" international defaultCountry="IN" value={value} onChange={setValue} />
                                                    <div className="invalid-feedback">{errors.contact?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                    <input {...register('name' , { required: true })} className={`form-control ${errors.name ? 'invalid' : ''}`} type="text" name="name" placeholder="Name" />
                                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                    <input {...register('password' , { required: true })} className={`form-control ${errors.password ? 'invalid' : ''}`} type="password" name="password" placeholder="Password" />
                                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                                </div>
                                                <div className="form-group">
                                                <input {...register('confirmpassword' , { required: true })} className={`form-control ${errors.confirmpassword ? 'invalid' : ''}`} type="password" name="confirmpassword" placeholder="Confirm Password" />
                                                    <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                                                </div>

                                                <input className="btn btn-info btn-block btn-round" type="submit" value="Submit" />
                                                
                                    </form>
                                    <div className="text-center text-muted delimiter">Sign up with</div>
                                        <div style={{display: 'flex',flexWrap: 'wrap' }} >                           
                                                <FacebookLogin
                                                    appId="941918019772530"
                                                    cssClass="btnFacebook"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    scope="public_profile,user_friends"
                                                    callback={responseFacebook}
                                                    icon="fa-facebook" 
                                                    textButton="Sign up Facebook"
                                                />
                                                
                                                <GoogleLogin
                                                    clientId="89607273877-sken77akdvdsuoi9tlp759aiqumd160d.apps.googleusercontent.com"
                                                    cssClass="btnFacebook"
                                                    buttonText="Login With"
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}
                                                    cookiePolicy={'single_host_origin'}
                                                    icon={false}
                                                    className="btnGoogle"
                                                >
                                                    <i className="fab fa-google" style={{ marginLeft: '8px' }}></i>
                                                    
                                                    <span>&nbsp;&nbsp;Sign up Google</span>   
                                                </GoogleLogin>
                                                
                                        </div>
                                    </div>
                    </div>

                    <div className="modal-footer d-flex justify-content-center">
                        <div className="signup-section">Exiesting member Please <a href="javascript:void(0)" onClick={()=>{ handlesHide(); props.signinShowHandler({show:true})} }  className="text-info"> Login In</a></div>
                    </div>
                
            </>
            
        </Modal>

        <Modal show={forgotpasswod}>
            <>
                
                    <div className="modal-header border-bottom-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{props.signinHideHandler({show:false})}
                        }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-title text-center">
                            <h4>Reset Password</h4>
                        </div>
                        <div className="d-flex flex-column text-center">
                                    <form onSubmit={forgothandleSubmit(onforgotSubmit)} style={{textAlign: 'left'}}>
                                                { forgotshowSuccess ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="success" className="alert alert-success" style = {successstyle}>
                                                        <strong>{forgotmessage}</strong>
                                                    </div>
                                                </div>
                                                : '' }
                                                { forgotshowError ?
                                                <div className="row" style = {successcontainer}>
                                                    <div id="error" className="alert alert-danger" style = {successstyle}>
                                                        <strong>{forgotmessage}</strong>
                                                    </div>
                                                </div>
                                                : '' }                
                                                
                                        <div className="form-group">
                                                <strong>Registed Email</strong>
                                                <input {...forgot('femail' , { required: true })} className={`form-control ${forgoterrors.femail ? 'invalid' : ''}`} type="text" name="femail" placeholder="Email" />
                                                <div className="invalid-feedback">{forgoterrors.femail?.message}</div>
                                        </div>
                                        
                                    <input className="btn btn-info btn-block btn-round" type="submit" value="Send" />
                                </form>                                   
                                </div>
                    </div>
                
            </>
            
        </Modal>
        
    </>
  );

}

import React from 'react';

export default function Delivery(){


  return(
                    <>
                        <div className="store-feature section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <ul className="display-table store-info">
                                            <li className="display-table-cell">
                                                <i className="icon anm anm-truck-l"></i>
                                                <h5>Free Shipping Worldwide</h5>
                                                <span className="sub-text">
                                                    Diam augue augue in fusce voluptatem
                                                </span>
                                            </li>
                                            <li className="display-table-cell">
                                                <i className="icon anm anm-money-bill-ar"></i>
                                                <h5>Money Back Guarantee</h5>
                                                <span className="sub-text">
                                                    Use this text to display your store information
                                                </span>
                                            </li>
                                            <li className="display-table-cell">
                                                <i className="icon anm anm-comments-l"></i>
                                                <h5>24/7 Help Center</h5>
                                                <span className="sub-text">
                                                    Use this text to display your store information
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
  );

}

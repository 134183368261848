import React, {useEffect , useState , Suspense} from 'react';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Bannerslide from '../Bannerslide/Bannerslide';

import axios from 'axios';
import * as Helper from '../../Helper';

export default function Banner() {

    var settings = {
        autoplay: true,
        autoplaySpeed: 1000,
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
      };

      const [loaderState, setLoaderState] = useState(true);
      const [Sliderdata , setSliderdata] = useState([]);

      //API call
      const Apicall = () => {
        axios
            .get(Helper.SITEURL+'admin/api/sliders/list')
            .then((res) => {
                if (res.status === 200) {

                  setLoaderState(false);
                  if(res.data.data !== undefined) {
                    setSliderdata(res.data.data);
                  }
                  
                }else{
                  setLoaderState(true);
                  setSliderdata([]);
                }
            })
            .catch((err) => {
                setLoaderState(true);
                setSliderdata([]);
            });
      };
    
      useEffect(() => {
          Apicall();
      }, []);

  return(
        <>
            <div className="slideshow slideshow-wrapper pb-section">
                <div className="home-slideshow">
                <Slider {...settings}>
                    { Sliderdata !== undefined &&  Sliderdata.map((sdata, i) => { 
                        return (<Bannerslide sliderdata={sdata}/>)
                    })}
                </Slider>
                </div>
            </div>
        </>
  );

}
import React, {useEffect , useState , Suspense} from 'react';

import Topbanner from '../../containers/TopbannerContainer';
import Productimageslider from '../Productimageslider/Productimageslider';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Productprice from '../Productprice/Productprice';
import Productreview from '../Productreview/Productreview';
import Deliverybanner from '../Deliverybanner/Deliverybanner';
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import * as Helper from '../../Helper';
import Loader from '../Loader/Loader';
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Productslider from '../Productslider/Productslider';
import Reviewstar from '../Reviewstar/Reviewstar';
import Reviewcomment from '../Reviewcomment/Reviewcomment';
import FileBrowser, { Icons } from 'react-keyed-file-browser';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import  { Redirect } from 'react-router-dom';
import $, { data } from 'jquery'; 

import { useHistory } from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    InstapaperShareButton,
    InstapaperIcon,
    EmailShareButton,
    EmailIcon
    
  } from "react-share";


import axios from 'axios';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

import '../../assets/css/overwrite.css';
import { FormControlUnstyled } from '@mui/base';
import { array } from 'yup/lib/locale';


export default function Productdetails(props){

    let history = useHistory();

    const params = useParams();

    const [loaderState, setLoaderState] = useState(true);
    const [Sitesettings , setSitesettings] = useState([]);

    const [productshareurl , setProductshareurl] = useState([]);
    const [productsharetitle , setProductsharetitle] = useState([]);

    const successstyle = {
        'margin' : "2%",
    };


    //API call
      let url = Helper.SITEURL+'admin/api/settings';
      const Apicall = () => {
        axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {

                  setLoaderState(false);
                  if(res.data.data !== undefined) {
                    setSitesettings(res.data.data);
                  }
                
                }else{
                  setLoaderState(true);
                  setSitesettings([]);
                }
            })
            .catch((err) => {
                setLoaderState(true);
                setSitesettings([]);
            });
      };

      useEffect(() => {
          Apicall();
      }, [setSitesettings]);
    
    //Product Details
    const [Product , setProduct] = useState([]);
    useEffect(() => {
        productCall(params.id);
    }, [params.id]);

    //Similler Products
    const [Similerproduct , setSimilerproduct] = useState([]);

    const [currenturl , setCurrenturl] = useState([]);
    const [cpid , setCpid] = useState([]);
    const [cvarrient , setCvarrient] = useState([]);

    function productoptionCall(slug){

        var url = slug;
        if (url !== '' ) {
            history.push(url);
            history.go(1);
        }
    
    }


    function productCall(slug){

        let producturl = Helper.SITEURL+'admin/api/product/'+slug;
        axios
            .get(producturl)
            .then((res) => {

                if (res.status === 200) {
                    setLoaderState(false);
                    if(res.data.data[0] !== undefined) {
                        setProduct(res.data.data[0]);
                        setProductshareurl(Helper.SITEURL+'/product/'+slug);
                        setProductsharetitle(Product.product_name);
                        setSimilerproduct(res.data.data[0].similarproducts);
                        setCurrenturl(slug);
                        if (res.data.data[0] !== undefined ) {
                            setCpid(res.data.data[0].id );
                            setCvarrient(res.data.data[0].product_varient );
                        }
                        
                    }
                    
                }else{
                    setLoaderState(true);
                    setProduct([]);
                }
            })
            .catch((err) => {
                setLoaderState(true);
                setProduct([]);
            });

    }

    //Product Looking
    function productlooking(productid = ''){

        if (productid !== '') {
            axios
            .get('https://geolocation-db.com/json/')
            .then((res) => {

                if (res.status ===200) {
                    var ip = res.data.IPv4;
                    const formData = new FormData();
                    formData.append("userip", ip);
                    formData.append("productid", productid);

                    let lookingproducturl = Helper.SITEURL+'admin/api/looking-for-product';
                    axios
                    .post(lookingproducturl, formData ,{
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                      })
                      .then((res) => {
        
                        if (res.status === 200) {
                            
                            console.log('success');
                            
                        }else{
                            
                        }
                    })
                    .catch((err) => {
                        
                    });
                }
            
            })
            .catch((err) => {
                
            });
        }
    }

    const[ip , setIp] = useState('');
    const[country , setCountry] = useState('');

    const handleAddtocart = (event, newValue) => {
        var qut = document.getElementById('Quantity').value;

        axios
            .get('https://geolocation-db.com/json/')
            .then((res) => {

                if (res.status ===200) {
                    setIp(res.data.IPv4);
                    setCountry(res.data.country_code);

                    const formData = new FormData();
                    setIp(res.data.IPv4);
                    formData.append("carttype", 'ip');
                    formData.append("userip", res.data.IPv4);
                    formData.append("product_quantity", qut);
                    formData.append("product_slug", params.id);
                    
              
                axios
                .post(Helper.SITEURL +'admin/api/add-to-cart/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {

                        props.setCartproductHandler(res.data.data.cart);
                        props.setcartPriceHandler(res.data.data.total);
                        toastSuccess(res.data.message);
                      
                    }else{
                    
                        toastError(res.data.message);
                        props.setcartPriceHandler([]);
                        props.setCartproductHandler([]);
                      
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.setcartPriceHandler([]);
                    props.setCartproductHandler([]);
                });
                }
              
            })
            .catch((err) => {
                
            });
    };  

    const handleBuy = (event, newValue) => {
        var qut = document.getElementById('Quantity').value;

        axios
            .get('https://geolocation-db.com/json/')
            .then((res) => {

                if (res.status ===200) {
                    setIp(res.data.IPv4);
                    setCountry(res.data.country_code);

                    const formData = new FormData();
                    setIp(res.data.IPv4);
                    formData.append("carttype", 'ip');
                    formData.append("userip", res.data.IPv4);
                    formData.append("product_quantity", qut);
                    formData.append("product_slug", params.id);
                    
              
                axios
                .post(Helper.SITEURL +'admin/api/add-to-cart/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res) => {
    
                    if (res.data.success === true) {

                        props.setCartproductHandler(res.data.data.cart);
                        props.setcartPriceHandler(res.data.data.total);
                        checkoutprocess();
                      
                    }else{
                    
                        toastError(res.data.message);
                        props.setcartPriceHandler([]);
                        props.setCartproductHandler([]);
                      
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.setcartPriceHandler([]);
                    props.setCartproductHandler([]);
                });
                }
              
            })
            .catch((err) => {
                
            });
    };  

    function checkoutprocess(pid = ''){
      if (token === '' ||	token === undefined ||	token === null ) {
          props.signinShowHandler({show:true});
      } else {

        axios
        .get('https://geolocation-db.com/json/')
        .then((res) => {

            if (res.status ===200) {

                  const formData = new FormData();
                  formData.append("userip", res.data.IPv4);
                  formData.append("userid", uid);
                  
                  let cartproducturl = Helper.SITEURL+'admin/api/checkout';
                    axios
                    .post(cartproducturl,formData , {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+token
                      }
                    })
                    .then((res) => {

                      if (res.status === 200) {

                          if(res.data.data.order[0] !== undefined) {
                            
                            localStorage.setItem('orderid', res.data.data.order[0].orderid);
                            window.location.href = "/checkout/";
                            
                          }
                      }else{
                        props.setCartproductHandler([]);
                        props.setcartPriceHandler([]);
                      }
                  })
                  .catch((err) => {
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                  });        
              
            }
          
        })
        .catch((err) => {
            
        });
      }
    }



    useEffect(() => {
        productlooking(Product.id);
        productreviewcall(Product.id);
    }, [Product.id]);


      //add review
      const [token, setToken] = useState(localStorage.getItem('token'))
      const [uid, setuid] = useState(localStorage.getItem('UID'))

      function addreview(pid = ''){
        if (token === '' ||	token === undefined ||	token === null ) {
            props.signinShowHandler({show:true});
        } else {

         
        }
      }

      const [productreviews , setProductreviews] = useState([]);
      //Review List
      function productreviewcall(productid = ''){

        if (productid !== '' ) {

            const formData = new FormData();
            formData.append("productid", productid);

            let productreviewurl = Helper.SITEURL+'admin/api/review-list/';
            axios
                .post(productreviewurl , formData , {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                .then((res) => {
    
                    if (res.status === 200) {
                        
                        setProductreviews(res.data.data.reviewlist);
                        
                    }else{
                        setLoaderState(true);
                        setProduct([]);
                    }
                })
                .catch((err) => {
                    setLoaderState(true);
                    setProduct([]);
                });
            
        }
       

    }

    //Reviw Validation
    const validationSchema = Yup.object().shape({
        review: Yup.string()
        .required('Please write your Review.'),
        rating: Yup.string()
            .required('Please Enter Your Valid Contact Number.')          
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    //get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');

    function onSubmit(sdata) {

        if (token === '' ||	token === undefined ||	token === null ) {
            props.signinShowHandler({show:true});
        } else {

            const formData = new FormData();
            const reviewimage = document.querySelector('#reviewimage');

            for (let index = 0; index < reviewimage.files.length; index++) {
                formData.append("reviewimage[]", reviewimage.files[index]);
                
            }
            formData.append("review", sdata.review);
            formData.append("rating", sdata.rating);
            formData.append("customerid",  uid);
            formData.append("productid",  Product.id);

            axios
                .post(Helper.SITEURL +'admin/api/review-add/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                        reset();
                        toastSuccess(res.data.message);
                        productreviewcall(Product.id);

                    }else{
                        toastError(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        }

        return false;
    }

    const [currentstockstatus, setCurrentstockstatus] =  useState('');

    function incrementValue(e) {
        e.preventDefault();

        var qut =document.getElementById("Quantity").value;  
        qut = parseInt(qut)+ parseInt(1);
        if (cpid !== '' ) {

            const formData = new FormData();
            formData.append("productid", cpid);
            formData.append("quantity", qut);
            formData.append("varient", cvarrient);


            let productreviewurl = Helper.SITEURL+'admin/api/product-quantity-check/';
            axios
                .post(productreviewurl , formData , {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                .then((res) => {
    
                    if (res.status === 200) {

                        if (res.data.success === true) {

                            setCurrentstockstatus();
                            document.getElementById('Quantity').value= qut ;
                            
                            
                        }else if (res.data.success === false) {
                            
                        }
                        
                       
                    }
                })
                .catch((err) => {
                    setCurrentstockstatus();
                });
            
        } 
        
        
    }

    function decrementValue(e) {
        e.preventDefault();
        var qut =document.getElementById("Quantity").value;  
        qut = parseInt(qut)- parseInt(1)
        if (cpid !== '' ) {

            const formData = new FormData();
            formData.append("productid", cpid);
            formData.append("quantity", qut);
            formData.append("varient", cvarrient);


            let productreviewurl = Helper.SITEURL+'admin/api/product-quantity-check/';
            axios
                .post(productreviewurl , formData , {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                .then((res) => {

                    if (qut > 0) {

                        if (res.status === 200) {
                            if (res.data.success === true) {

                                setCurrentstockstatus();
                                document.getElementById('Quantity').value= qut ;
                                
                            }else if (res.data.success === false) {
                                
                            }
                        }
                        
                    }
                    
                })
                .catch((err) => {
                    setCurrentstockstatus();
                });
            
        } 
            
    }

  return(
    <>

          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          
          
   

        <div id="page-content ">
        <div id="MainContent" className="main-content" role="main">

            <Breadcrumb data={Product} settings = {Sitesettings}  />

            <div id="ProductSection-product-template" className="product-template__container prstyle1 container">

                <div className="product-single">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            {Product !== undefined ? (<Productimageslider product= {Product} settings = {Sitesettings} />) : ''}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="product-single__meta">
                                <h1 className="product-single__title text-left">{Product.product_name}</h1>

                                <div className="prInfoRow">
                                    <div className="product-stock"> 

                                        {Product.soldout !== undefined && Product.soldout === 'no' ? (
                                            <span className="instock">In Stock</span> 
                                        ) : (
                                            <span className="outstock">Out Of Stock</span> 
                                        )}
                                    
                                    </div>
                                </div>
                                <div className="prInfoRow">
                                {Product.reviewtotalcount !== undefined && Product.reviewtotalcount > 0 ? (
                                    <div className="product-review">
                                        <a className="reviewLink" href="#tab2">
                                            <Reviewstar level={Product.reviewrating} reviews ={Product.reviewtotalcount}/>
                                        </a>
                                    </div>
                                    ) 
                                : ''}
                                </div>

                                {Product.product_retail_price !== undefined && Product.product_retail_price > 0 ? (<Productprice price ={Product.product_retail_price} sale ={Product.product_offer_percentage} offerstatus={Product.product_offer_status} />) : ''}
                                

                                {Product.recentsoldcount !== undefined && parseInt(Product.recentsoldcount) > 0 ? (

                                    <div className="orderMsg" data-user="23" data-time="24">
                                        <img src="/assets/images/order-icon.jpg" alt="" /> <strong className="items">{Product.recentsoldcount}</strong> sold in last <strong className="time">{Product.recentsoldhour}</strong> hours
                                    </div>

                                ) : ''}

                                
                            </div>
                            {Sitesettings.stock_alert !== undefined && Product.product_stock_quantity !== undefined && parseInt(Product.product_stock_quantity)>0 && parseInt(Sitesettings.stock_alert) >= parseInt(Product.product_stock_quantity) ? (
                                <div id="quantity_message">Hurry! Only <span className="items">{Product.product_stock_quantity+' '+Product.product_varient.toUpperCase ().slice(0, -1)}</span> left in stock.</div>
                                )
                            : ''} 
                            


                            <form method="post" action="http://annimexweb.com/cart/add"
                                id="product_form_10508262282" accept-charset="UTF-8"
                                className="product-form product-form-product-template hidedropdown"
                                enctype="multipart/form-data">
                                

                                <div className="swatch clearfix swatch-0 option1" data-option-index="0">
                                    <div className="product-form__item">


                                    { Product.options !== undefined && Product.options !== "" && Product.options.map((option, i) => { 

                                                let switchid = 'swatch-'+option.quantity+'-'+option.varient;
                                                let name = option.quantity+' '+option.varient;

                                                return (

                                                    <>

                                                    {option.soldout !== undefined && option.soldout === 'no' ? (
                                                        <div data-value={name} className="swatch-element color red available">
                                                            <input className="swatchInput" id={switchid}  value= {name} onClick={() => { productoptionCall(option.product_slug); }}/>
                                                            <label className={option.product_slug !== undefined && option.product_slug === currenturl ? 'swatchLbl color medium rectangle active' : 'swatchLbl color medium rectangle'} for={switchid}
                                                                style={{ backgroundImage: `url(${option.product_image})` }}
                                                                title={name}></label>
                                                            <span className="slVariant">{option.quantity} {option.varient_unit.toUpperCase().slice(0, -1)}</span>
                                                        </div>
                                                    ) : (
                                                        <div data-value={name}
                                                            className="swatch-element color orange available notAvailable">
                                                            <input className="swatchInput" id={switchid}  value={name} onClick={() => { productoptionCall(option.product_slug); }}/>
                                                            <label className={option.product_slug !== undefined && option.product_slug === currenturl ? 'swatchLbl color medium rectangle active' : 'swatchLbl color medium rectangle'} for={switchid}
                                                                style={{ backgroundImage: `url(${option.product_image})` }}
                                                                title={name}></label>
                                                            <span className="slVariant">{option.quantity} {option.varient_unit.toUpperCase().slice(0, -1)}</span>
                                                        </div>
                                                    )}

                                                    </>   
                                                            
                                                    
                                                )

                                    })}
                                        
                                    </div>
                                </div>

                            {Product.soldout !== undefined && Product.soldout === 'no'  ? (
                                <>
                                <div className="product-form__item--quantity">
                                    <div className="wrapQtyBtn">
                                        <div className="qtyField">
                                            <a className="qtyBtn minus" href="javascript:void(0);" onClick={decrementValue}><i
                                                    className="fa anm anm-minus-r" aria-hidden="true"></i></a>
                                            <input type="text" id="Quantity" name="quantity" value="1" className="product-form__input qty" min="1" autocomplete="off"/>
                                            <a className="qtyBtn plus" href="javascript:void(0);" onClick={incrementValue}><i
                                                    className="fa anm anm-plus-r" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-action clearfix">

                                    <div className="product-form__item--submit">
                                        <button type="button" name="add" className="btn product-form__cart-submit" onClick={handleAddtocart}>
                                            <span>Add to cart</span>
                                        </button>
                                    </div>
                                    <div className="shopify-payment-button" data-shopify="payment-button">
                                        <button type="button"
                                            className="shopify-payment-button__button shopify-payment-button__button--unbranded" onClick={handleBuy}>Buy
                                            it now</button>
                                    </div>
                                </div>
                                </>
                                )
                            : <div id="notification_message" className=""  dangerouslySetInnerHTML={{ __html: Sitesettings.notification_msg}}></div> } 

                                
                            </form>
                            <div className="display-table shareRow">
                                <div className="display-table-cell medium-up--one-third">
                                <div className="social-sharing">

                                        <TwitterShareButton
                                            url={productshareurl}
                                            title={productsharetitle}
                                            className="Demo__some-network__share-button ">
                                            <TwitterIcon
                                            size={32}
                                            round />
                                        </TwitterShareButton>

                                        <FacebookShareButton
                                            url={productshareurl}
                                            title={productsharetitle}
                                            className="Demo__some-network__share-button ml">
                                            <FacebookIcon
                                            size={32}
                                            round />
                                        </FacebookShareButton>

                                        <WhatsappShareButton
                                            url={productshareurl}
                                            title={productsharetitle}
                                            className="Demo__some-network__share-button ml">
                                            <WhatsappIcon
                                            size={32}
                                            round />
                                        </WhatsappShareButton>
                                       
                                        <InstapaperShareButton
                                            url={productshareurl}
                                            title={productsharetitle}
                                            className="Demo__some-network__share-button ml">
                                            <InstapaperIcon
                                            size={32}
                                            round />
                                        </InstapaperShareButton>

                                        <EmailShareButton
                                            url={productshareurl}
                                            title={productsharetitle}
                                            className="Demo__some-network__share-button ml">
                                            <EmailIcon
                                            size={32}
                                            round />
                                        </EmailShareButton>

                                        
                                       
                                    </div>
                                </div>
                               
                            </div>

                            
                            <div dangerouslySetInnerHTML={{ __html: Sitesettings.delivery_msg}}></div>
                            <div className="userViewMsg" data-user="20" data-time="11000"><i className="fa fa-users"
                                    aria-hidden="true"></i> <strong className="uersView">{Product.lookingfor}</strong> PEOPLE ARE LOOKING
                                FOR THIS PRODUCT
                            </div>
                        </div>
                    </div>
                </div>

                <Deliverybanner/>

                <Tabs className ="tabs-listing">
                    <TabList className ="product-tabs">
                        <Tab className =""><a className ="tablink">Product Details</a></Tab>
                        <Tab className =""><a className ="tablink">Product Reviews</a></Tab>
                        <Tab className =""><a className ="tablink">Shipping &amp; Returns</a></Tab>
                    </TabList>

                    <TabPanel>
                        <p style={successstyle}  dangerouslySetInnerHTML={{ __html: Product.product_description}}></p>
                    </TabPanel>
                    <TabPanel>
                        <p style={successstyle} >
                            <div id="tab2" class="tab-content">
                                <div id="shopify-product-reviews">
                                    <div class="spr-container">
                                        <div class="spr-content">
                                            <div class="clearfix">
                                                <form method="post" action="#" id="new-review-form" class="new-review-form" onSubmit={handleSubmit(onSubmit)}>
                                                    <h3 class="spr-form-title">Write a review</h3>
                                                    
                                                    <fieldset>
                                                        <span class="star-cb-group">
                                                        <input type="radio" id="rating-5" {...register('rating')}  value="5" /><label for="rating-5"></label>
                                                        <input type="radio" id="rating-4" {...register('rating')}  value="4" checked="checked" /><label for="rating-4"></label>
                                                        <input type="radio" id="rating-3" {...register('rating')}  value="3" /><label for="rating-3"></label>
                                                        <input type="radio" id="rating-2" {...register('rating')}  value="2" /><label for="rating-2"></label>
                                                        <input type="radio" id="rating-1" {...register('rating')}  value="1" /><label for="rating-1"></label>
                                                        </span>
                                                        <div className="invalid-feedback">{errors.street?.message}</div>
                                                    </fieldset>
                                                    <fieldset class="spr-form-review">
                                                        <div class="spr-form-review-body">
                                                            <label class="spr-form-label"
                                                                for="review_body_10508262282">Review <span
                                                                    class="spr-form-review-body-charactersremaining">(1500)</span></label>
                                                            <div class="spr-form-input">
                                                                <textarea {...register('review' , { required: true })} className={`spr-form-input spr-form-input-textarea col-md-8 ${errors.review ? 'invalid' : ''}`} 
                                                                    rows="6"
                                                                    placeholder="Write your comments here"></textarea>
                                                                <div className="invalid-feedback">{errors.review?.message}</div>
                                                            </div>
                                                            <input id='reviewimage' type="file" name="reviewimage" style={{'padding': '0' , 'border':'none' }}  multiple />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset class="spr-form-actions col-md-8" style={{padding: 0}} >
                                                        <input type="submit" class="spr-button spr-button-primary button button-primary btn btn-primary" value="Submit Review" />
                                                    </fieldset>
                                                </form>
                                            </div>
                                            <div class="spr-reviews">

                                                { productreviews !== undefined &&  productreviews.map((sdata, i) => { 
                                                    return ( <Reviewcomment key={i} data ={sdata} />)
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p style={successstyle}  dangerouslySetInnerHTML={{ __html: Sitesettings.return_policy}}></p>
                    </TabPanel>
                </Tabs>
                
                

                <Productslider slidetitle='Similar Products' slidedescription='' product ={Similerproduct} settings ={Sitesettings} panelname = {'similer'} />
                
            </div>

        </div>

        </div>

    {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings} type = 'productdetilas'/> : ''}
 
    </>
  );

}

import React from 'react';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Product from '../../containers/ProductContainer';


export default function Productslider(props){

  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : null;
  let slide = 1;

  if (width >= 375 && width < 420) {
    slide = 1;
  }else if (width >= 420 && width < 575 ) {
    slide = 2;
  }else if (width >= 575 && width <= 768 ) {
    slide = 3;
  }else if (width >= 768 && width <= 1099) {
    slide = 4;
  }else if (width >= 1200) {
    slide = 4;
  }
  
  var settings = {
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: slide,
    slidesToScroll: 1,
    fade: false
  };
  
  var panelname = '';
  if (props.panelname !== undefined) {
    panelname = props.panelname;
  } 

 

  return(
    
    <>
    {props.product !== undefined && props.product.length > 4 ? (
      <div className="section">
        <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="section-header text-center">
                    <h2 className="h2">{props.slidetitle}</h2>
                    <p>{props.slidedescription}</p>
                  </div>
                  <div className="productSlider grid-products slick-initialized slick-slider">
                        <Slider {...settings}>

                            { props.product !== undefined &&  props.product.map((sdata, i) => { 
                                   return (< Product product= {sdata} settings= {props.settings} type='type2' panel ={panelname} />)
                            })}

                        </Slider>
                  </div>
              </div>
            </div>
        </div>
      </div>
      ) : ''}
    </>
  );
}



        
import Productfiltercategorydescription from '../components/Productfiltercategorydescription/Productfiltercategorydescription';
import {connect} from 'react-redux'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    // signinHideHandler:(data) => dispatch(signinHide(data)),
    // signinShowHandler:(data) => dispatch(signinShow(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Productfiltercategorydescription)
import { UPLOAD_PROFILE_IMAGE } from '../constants'

export default function setimageCall(state = [], action) {
    switch (action.type) {
        case UPLOAD_PROFILE_IMAGE:
            return action.setimage;

        default:
            return state;
    }
}
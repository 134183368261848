import React, {useEffect , useState , Suspense , useCallback} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

//Component
import  { Redirect } from 'react-router-dom'
import Topbanner from '../../containers/TopbannerContainer';
import Header from '../../containers/HeaderContainer';
import Responsepayment from '../Responsepayment/Responsepayment';
import Footer from '../Footer/Footer';
import Deliverybanner from '../Deliverybanner/Deliverybanner';
import Loader from '../Loader/Loader';
import { useParams } from "react-router-dom";
import * as Helper from '../../Helper';
import card from '../../assets/images/payment-img.jpg';
import $ from 'jquery'; 

import { useHistory } from "react-router-dom";

import Useraddress from  '../../containers/UseraddressContainer';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

import axios from 'axios';

import { FaShoppingBag , FaAmazonPay } from "react-icons/fa";

export default function Checkout(props){

    const params = useParams();
    let history = useHistory();

    const [loaderState, setLoaderState] = useState(true);
    const [token, setstate] = useState(localStorage.getItem('token'))
    const [Sitesettings , setSitesettings] = useState([]);
    const [uid, setuid] = useState(localStorage.getItem('UID'))


        //API call
        let url = Helper.SITEURL+'admin/api/settings';
        const Apicall = () => {
          axios
              .get(url)
              .then((res) => {
                  if (res.status === 200) {
  
                    setLoaderState(false);
                    if(res.data.data !== undefined) {
                      setSitesettings(res.data.data);
                    }
                  
                  }else{
                    setLoaderState(true);
                    setSitesettings([]);
                  }
              })
              .catch((err) => {
                  setLoaderState(true);
                  setSitesettings([]);
              });
        };
  
        useEffect(() => {
            Apicall();
        }, [setSitesettings]);

      //cart Product
      const [checkoutproducts , setCheckoutproducts] = useState([]);
      const [deliverymethod , setDeliverymethod] = useState([]);
      const [checkoutprice , setCheckoutprice] = useState([]);
      const [setdeliveryaddress , setSetdeliveryaddress] = useState([]);
      const [setbillingaddress , setSetbillingaddress] = useState([]);
      const [selectdeliverymethod , setSelectdeliverymethod] = useState([]);
      const [paystate , setpaystate] = useState(false);
      const [redirecturl , setredirecturl] = useState('/');

      

    const checkoutcall = () => {

        let checkoutproducturl = Helper.SITEURL+'admin/api/order-wise-product-list';

        const formData = new FormData();
        formData.append("orderid", localStorage.getItem('orderid'));
    
        axios
            .post(checkoutproducturl,formData , {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+token
            }
            })
            .then((res) => {
            if (res.status === 200) {
                if(res.data.data !== undefined) {

                    setCheckoutproducts(res.data.data.products);
                    setDeliverymethod(res.data.data.deliverymethod);
                    setCheckoutprice(res.data.data.total);
                    if (res.data.data.order[0] !== undefined ) {
                        setSetdeliveryaddress(res.data.data.order[0].delivery_address);
                        setSetbillingaddress(res.data.data.order[0].billingaddress);
                        setSelectdeliverymethod(res.data.data.order[0].delivery_method);
                    }

                }
            }else{
                setCheckoutproducts([]);
                setDeliverymethod([]);
                setCheckoutprice([]);
            }
        })
        .catch((err) => {
            setCheckoutproducts([]);
            setDeliverymethod([]);
            setCheckoutprice([]);
        });  

    };

      useEffect(() => {
        checkoutcall();
      }, []);  

      //Total Price
      const [totalprice , setTotalprice] = useState(0);

      useEffect(() => {
        setTotalprice(checkoutprice.totalnetamount);
      }, [checkoutprice.totalnetamount]); 

    const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
         document.body.appendChild(script);
       });
    };
    
    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    });


    const handleDelivery = (value) => {

        if (value !== undefined) {
                let deliverymethodurl = Helper.SITEURL+'admin/api/update-delivery-method';

                const formData = new FormData();
                formData.append("orderid", localStorage.getItem('orderid'));
                formData.append("deliverymethod", base64_decode (value));
            
                axios
                    .post(deliverymethodurl,formData , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+token
                    }
                    })
                    .then((res) => {
                    if (res.status === 200) {

                        console.log(res);
                        
                        var tmp = (parseFloat(res.data.data.order[0].orderprice) + parseFloat(res.data.data.order[0].delivery_method_cost)).toFixed(2);
                        setTotalprice(tmp);
                       
                    }else{
                        
                    }
                })
                .catch((err) => {
                    
                });  
        }

        
    }

    const handleProcesstopay = (e) => {

        let processtopay = Helper.SITEURL+'admin/api/process-to-pay';
        const formData = new FormData();
        formData.append("orderid", localStorage.getItem('orderid'));
        
        axios
            .post(processtopay,formData , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                }
            })
            .then((res) => {

            if (res.status === 200) {

                if(res.data.success === true) {

                    var transectiondata = new Array();

                    transectiondata['name'] =  Sitesettings.Title;
                    transectiondata['oid'] =  localStorage.getItem('orderid');
                    transectiondata['description'] = Helper.SITEDETAILS;
                    transectiondata['ammount'] = res.data.data.total.totalnetamount * 100;
                    transectiondata['currency'] = Helper.CURRENCYTYPE;
                    transectiondata['logo'] = Helper.SITEURL+'admin/'+Sitesettings.site_logo;
                    transectiondata['contact'] = res.data.data.billingaddress[0].contact ? res.data.data.billingaddress[0].contact : '';
                    transectiondata['email'] = res.data.data.billingaddress[0].email ? res.data.data.billingaddress[0].email : '';
                    transectiondata['name'] = res.data.data.billingaddress[0].name ? res.data.data.billingaddress[0].name : '';
                    transectiondata['razorpayorderid'] = res.data.data.razorpayorderid ? res.data.data.razorpayorderid : '';
    
                    razorpaycall(transectiondata);

                }else{

                    toastError('Please select Billing and Delivery Address');

                }
               
               
            }else{
                
                alert('Failed To Load');
            }
        })
        .catch((err) => {
            
        });  
    }

    const handleProcesstodelivery = async(data) => {

        let processtopay = Helper.SITEURL+'admin/api/process-to-pay';
        const formData = new FormData();
        formData.append("orderid", localStorage.getItem('orderid'));
        
        axios
            .post(processtopay,formData , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                }
            })
            .then((res) => {
                  
            if (res.status === 200) {

                if(res.data.success === true) {

                    let transectionprocess = Helper.SITEURL+'admin/api/transaction';
                    const formData = new FormData();
                    formData.append("orderid", localStorage.getItem('orderid'));
                    formData.append("userid", uid);
                    formData.append("transaction_type", 'Cash on Delivery');
                    formData.append("transaction_status", 1);
                    formData.append("status", 4);
                    formData.append("ordertype", 'cash');
            
                    axios
                        .post(transectionprocess,formData , {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer '+token
                            }
                        })
                        .then((res) => {
            
                            if (res.data.success === true) {
            
                                let salt1 ='2U5K545';
                                let salt2 ='5L58J545';
            
                                let originalString = localStorage.getItem('orderid');
                                originalString = salt1+originalString+salt2;
                                // Create buffer object, specifying utf8 as encoding
                                let bufferObj = base64_encode(originalString);
                                
                                url = '/payment/status/'+bufferObj;
            
                                setpaystate(true);
                                setredirecturl(url);
            
                                window.location.href =url;
                                
                            }else{
            
                                alert('Failed Payment');
                                
                            }
            
                            
                        })
                    .catch((err) => {
            
                        return false;
                        
                    });  

                }else{

                    toastError('Please select Billing and Delivery Address');

                }
               
               
            }else{
                
                alert('Failed To Load');
            }
        })
        .catch((err) => {
            
        });  
        
    }


    const [transectionrecord , settransectionrecord] = useState([]);

    const handleUpdatetransection = async(data) => {

        let transectionprocess = Helper.SITEURL+'admin/api/transaction';
        const formData = new FormData();
        formData.append("orderid", localStorage.getItem('orderid'));
        formData.append("userid", uid);
        formData.append("transactionid", data['tid']);
        formData.append("transactional_orderid", data['toid']);
        formData.append("transactional_signature", data['tsignature']);
        formData.append("transaction_type", 'Razorpay');
        formData.append("transaction_status", 1);
        formData.append("status", 4);
        formData.append("ordertype", 'cash');
    
        axios
            .post(transectionprocess,formData , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                }
            })
            .then((res) => {

                if (res.data.data.transection[0] !== undefined) {

                    let salt1 ='2U5K545';
                    let salt2 ='5L58J545';

                    let originalString = localStorage.getItem('orderid');
                    originalString = salt1+originalString+salt2;
                    // Create buffer object, specifying utf8 as encoding
                    let bufferObj = base64_encode(originalString);

                    alert(bufferObj);
                    
                    
                    url = '/payment/status/'+bufferObj;

                    setpaystate(true);
                    setredirecturl(url);

                    window.location.href =url;
                    
                }else{

                    alert('Failed Payment');
                    
                }

                
            })
        .catch((err) => {

            return false;
            
        });  
        
    }

    function refreshCart(e) {
        
        axios
        .get('https://geolocation-db.com/json/')
        .then((res) => {

            if (res.status ===200) {

                  const formData = new FormData();
                  formData.append("carttype", 'ip');
                  formData.append("userip", res.data.IPv4);
                  
                  let cartproducturl = Helper.SITEURL+'admin/api/go-to-cart';
                    axios
                    .post(cartproducturl,formData , {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })
                    .then((res) => {

                      if (res.status === 200) {
                          if(res.data.data !== undefined) {
                            props.setCartproductHandler(res.data.data.cart);
                            props.setcartPriceHandler(res.data.data.total);
                          }
                      }else{
                        props.setCartproductHandler([]);
                        props.setcartPriceHandler([]);
                      }
                  })
                  .catch((err) => {
                    props.setCartproductHandler([]);
                    props.setcartPriceHandler([]);
                  });        
              
            }
          
        })
        .catch((err) => {
            
        });
      }


    const razorpaycall = async(data) =>{
      const options = {
        key: Sitesettings.rozerpay_key,
        currency: data['currency'],
        amount: data['ammount'],
        name: data['name'],
        description: data['description'],
        image: data['logo'] ,
        order_id: data['razorpayorderid'],
       
        handler: function (response) {

            var transectionresponse = new Array();
            transectionresponse['tid'] = response.razorpay_payment_id;
            transectionresponse['toid'] = response.razorpay_order_id;
            transectionresponse['tsignature'] = response.razorpay_signature;

            if (response.razorpay_payment_id !== undefined && response.razorpay_payment_id !== '') {
                
                handleUpdatetransection(transectionresponse);

            }else{
                localStorage.getItem('orderid');
            }

        },
        prefill: {
          name: data['name'],
          email: data['email'],
          contact: data['contact']
        }
      };
    const paymentObject = new window.Razorpay(options)
    paymentObject.open();

    }

  return(
        <>
          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          {checkoutproducts !== undefined && checkoutproducts.length > 0 ? 
          <>
            {paystate !== true ?
            <>
            <div id="page-content">
                <div id="page-content">
                    {/* <!--Page Title--> */}
                    <div className="page section-header text-center">
                        <div className="page-title">
                            <div className="wrapper">
                                <h1 className="page-width">Checkout</h1>
                            </div>
                        </div>
                    </div>
                    {/* <!--End Page Title--> */}

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 main-col productTable">
                                <form action="#" method="post" className="cart style2">
                                    <table>
                                        <thead className="cart__row cart__header">
                                            <tr>
                                                <th colspan="2" className="text-center">Product</th>
                                                <th className="text-center">Price</th>
                                                <th className="text-center">Quantity</th>
                                                <th className="text-right">Total</th>
                                                <th className="action">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {checkoutproducts !== undefined && checkoutproducts.map((item ,key)=>{ 
                                        return(
                                        <tr className="cart__row border-bottom line1 cart-flex border-top" key={key}>
                                            <td className="cart__image-wrapper cart-flex-item">
                                                <a href={Helper.SITEURL+'product/'+item.productslug}><img style={{width:'95px'}} className="cart__image"
                                                        src={item.productimage}
                                                        alt={item.productname} /></a>
                                            </td>
                                            <td className="cart__meta small--text-left cart-flex-item">
                                                <div className="list-view-item__title">
                                                    <a href={Helper.SITEURL+'product/'+item.productslug}>{item.productname}</a>
                                                </div>
                                                <div className="cart__meta-text">
                                                    Varrient: {item.productvarient} <br />
                                                </div>
                                            </td>
                                            <td className="cart__price-wrapper cart-flex-item">
                                                <span className="money">{Helper.CURRENCY+parseFloat(item.setprice).toFixed(2)}</span>
                                            </td>
                                            <td className="cart__update-wrapper cart-flex-item text-right">
                                                <div className="cart__qty text-center">
                                                    <div className="qtyField">
                                                        
                                                        <input className="cart__qty-input qty" type="text" name="updates[]"
                                                            id={'qty-'+key} value={item.productquantity} pattern="[0-9]*" min="1" cid = {base64_encode(item.id)} disable/>
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-right small--hide cart-price">
                                                <div><span className="money">{Helper.CURRENCY+parseFloat(item.totalprice).toFixed(2)}</span></div>
                                            </td>
                                            
                                            </tr>
                                            )
                                        })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="3" className="text-left"><a href="http://annimexweb.com/"
                                                        className="btn--link cart-continue"><i
                                                            className="icon icon-arrow-circle-left"></i> Continue shopping</a>
                                                </td>
                                                <td colspan="3" className="text-right"><a href='javascript:void(0);' onClick={refreshCart} name="update"
                                                    className="btn--link cart-update"><i className="fa fa-refresh"></i>
                                                    Update</a></td>
                                            </tr>
                                        </tfoot>
                                    </table>


                                </form>
                                <div className="payment-accordion">
                                    <div id="accordion" className="payment-section">
                                        <div className="card mb-2">
                                            <div className="card-header customer-box">
                                                <a className="card-link" data-toggle="collapse" href="#collapseOne">Billing Address </a>
                                            </div>
                                            <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                                <div className="card-body">
                                                    <Useraddress type={'billing'} slected={setbillingaddress}/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-accordion">
                                    <div id="accordion" className="payment-section">
                                        <div className="card mb-2">
                                            <div className="card-header customer-box">
                                                <a className="card-link" data-toggle="collapse" href="#collapseTwo">Delivery
                                                    Address </a>
                                            </div>
                                            <div id="collapseTwo" className="collapse show" data-parent="#accordion">
                                                <div className="card-body">
                                                
                                                    <Useraddress type={'delivery'} slected={setdeliveryaddress} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 cart__footer">

                                <div className="solid-border">
                                    <div className="row">

                                        <span className="col-12 ">
                                            <h1 className="mainTotal">Total</h1>
                                        </span>
                                        <span className="col-12 ">
                                            <hr />
                                        </span>
                                        <span className="col-12 col-sm-6 cart__subtotal-title"><strong>Subtotal</strong></span>
                                        <span className="col-12 col-sm-6 cart__subtotal-title cart__subtotal text-right"><span
                                                className="money">{Helper.CURRENCY+parseFloat(checkoutprice.totalgrossamount).toFixed(2)}</span></span>
                                        
                                        {checkoutprice.totaltax !== null ? (
                                            <>
                                            <span className="col-12 col-sm-6 cart__subtotal-title"><strong>Tax [{checkoutprice.totaltax+ '%'}] </strong></span>
                                            <span className="col-12 col-sm-6 cart__subtotal-title cart__subtotal text-right"><span
                                                    className="money">{Helper.CURRENCY+parseFloat(checkoutprice.totaltaxamount).toFixed(2)}</span></span>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    
                                            
                                        <span className="col-12 ">
                                            <form action="#" method="post" className="mt-3">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <span
                                                            className="col-12 col-sm-6 cart__subtotal-title"><strong>Delivery</strong></span>
                                                        <span
                                                            className="col-12 col-sm-6 cart__subtotal-title cart__subtotal text-right"><span
                                                                className="infoIcon"><i
                                                                    className="fa fa-info-circle"></i></span></span>
                                                        <span className="col-12 ">
                                                            <select id="address_province" className="mt-3"
                                                                name="address[province]" data-default="" onChange={e => handleDelivery(e.target.value)}>
                                                                    
                                                        
                                                            {deliverymethod !== undefined && deliverymethod.map((delivery ,key)=>{ 
                                                                return(
                                                                    <option key={key} value={base64_encode(delivery['id'])} selected={selectdeliverymethod === delivery['delivery_method'] ? ( 'true' ) : ( 'false')} >{delivery['delivery_method']}  - {Helper.CURRENCY+delivery['delivery_method_cost']} </option>
                                                                )
                                                            })}

                                                            </select>
                                                        </span>

                                                    </div>
                                                </div>
                                            </form>
                                        </span>
                                        
                                        <>
                                            <span className="col-12 col-sm-6 cart__subtotal-title"><strong>Net Payment</strong></span>
                                            <span className="col-12 col-sm-6 cart__subtotal-title cart__subtotal text-right"><span
                                                    className="money">{Helper.CURRENCY+parseFloat(totalprice).toFixed(2)}</span></span>
                                        </>
                                    </div>
                                    
                                    <div className="cart__shipping">Shipping &amp; taxes calculated at checkout</div>
                                    <p className="cart_tearm"></p>
                                    < FaAmazonPay ></FaAmazonPay>
                                    <input type="submit" name="checkout" id="cartCheckout" onClick={(e) => handleProcesstopay(e)}
                                        className="btn btn--small-wide checkout" value="Process TO Pay" style={{background:'green' , position: 'relative'}}  />

                                    <input type="submit" name="checkout" id="cartCheckout" onClick={(e) => handleProcesstodelivery(e)}
                                        className="btn btn--small-wide checkout" value="Cash On Delivery" style={{marginTop:'2%'}}/>    
                                    <div className="paymnet-img"><img src={card} alt="Payment" /></div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </>
            : 
            <Redirect to={redirecturl} />}
          </>
          : 
          <>
            <div id="page-content">
            <div class="info-box infocontainer">
                <FaShoppingBag/> No Product Placed Into The Checkout
            </div>
            </div>
          </>
          }
          
          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings} type = 'productdetilas'/> : ''}
        </>
  );

}

import Topbanner from '../components/Topbanner/Topbanner';
import {connect} from 'react-redux'
import {signinShow , signinHide , showSearch , forgotShow , forgotHide} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    signinHideHandler:(data) => dispatch(signinHide(data)),
    signinShowHandler:(data) => dispatch(signinShow(data)),
    forgotShowHandler:(data) => dispatch(forgotShow(data)),
    forgothideHandler:(data) => dispatch(forgotHide(data)),
    searchShowHandler:(data) => dispatch(showSearch(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Topbanner)
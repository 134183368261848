import React, { useEffect, useState, Suspense } from "react";
import { FaHome } from "react-icons/fa";
import * as Helper from '../../Helper';
import axios from 'axios';

export default function Breadcrumb (props) {

  const [product, setProduct] = useState([]);

  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);


    const [Sitesettings , setSitesettings] = useState([]);
  
    useEffect(() => {
      setSitesettings(props.settings);
    }, [props.settings]);

  return (
    <>
      <div className="bredcrumbWrap">
          <div className="container breadcrumbs">
              <a href="/" title="Back to the home page"><FaHome/></a>

              {(() => {

															let row = []
															if(product.product_category !== undefined && product.product_category !=='') {
																row.push(<><span aria-hidden="true">/</span><span><a href={'/products/&ct='+encodeURI(product.product_category)+'&pg=1&lt='+Sitesettings.product_limit}>{product.product_category}</a></span></>)
															}
                              if(product.product_subcategory !== undefined && product.product_subcategory !== '') {
																row.push(<><span aria-hidden="true">/</span><span><a href={'/products/&ct='+encodeURI(product.product_category)+'&sct='+encodeURI(product.product_subcategory)+'&pg=1&lt='+Sitesettings.product_limit}>{product.product_subcategory}</a></span></>)
															}
                              if(product.product_name !== undefined && product.product_name !== '') {
																row.push(<><span aria-hidden="true">/</span><span>{product.product_name}</span></>)
															}
															return row;
							})()}
              
              
          </div>
      </div>
    </>
  );
}

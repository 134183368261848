import { PRODUCT_SIDE_FILTER, 
         PRODUCT_FILTER, 
         PRODUCT_BANNER, 
         TOTAL_PRODUCT,
         ACTIVE_LOADER,
         SET_CATEGORY,
         SET_PAGE,
         SET_ORDER,
         SET_TO_ORDER
} from '../constants'

export function productFiltr(state = [], action) {
    switch (action.type) {
        case PRODUCT_SIDE_FILTER:
            return action.productsidefilter;
        case PRODUCT_FILTER:
                return action.productfilter;
        default:
            return state;
    }
}

export function productBanner(state = [], action) {
    switch (action.type) {
        case PRODUCT_BANNER:
                return action.productbanner;
        default:
            return state;
    }
}

export function totalProduct(state = [], action) {
    switch (action.type) {
        case TOTAL_PRODUCT:
                return action.totalproduct;
        default:
            return state;
    }
}

export function activeLoader(state = [], action) {
    switch (action.type) {
        case ACTIVE_LOADER:
                return action.activeloader;
        default:
            return false;
    }
}

export function setCategory(state = [], action) {
    switch (action.type) {
        case SET_CATEGORY:
                return action.setcategory;
        default:
            return false;
    }
}


export function setPage(state = [], action) {
    switch (action.type) {
        case SET_PAGE:
                return action.setpage;
        default:
            return false;
    }
}

export function setOrder(state = [], action) {
    switch (action.type) {
        case SET_ORDER:
                return action.setorder;
        default:
            return false;
    }
}

import React, {useEffect , useState , Suspense} from 'react';

//Component
import Topbanner from '../../containers/TopbannerContainer';
import Banner from '../Banner/Banner';
import Header from '../../containers/HeaderContainer';
import Productslider from '../Productslider/Productslider';
import Productpannel from '../Productpannel/Productpannel';
import Footer from '../Footer/Footer';
import Secondbanner from '../Secondbanner/Secondbanner';
import Productlist from '../Productlist/Productlist';
import Loader from '../Loader/Loader';
import * as Helper from '../../Helper';

import axios from 'axios';

export default function Homepage(props) {

  const [loaderState, setLoaderState] = useState(true);
  const [Sitesettings , setSitesettings] = useState([]);

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [uid, setuid] = useState(localStorage.getItem('UID'));
  

  //API call
  let url = Helper.SITEURL+'admin/api/settings';
  const Apicall = () => {
    axios
        .get(url)
        .then((res) => {
            if (res.status === 200) {

              setLoaderState(false);
              if(res.data.data !== undefined) {
                setSitesettings(res.data.data);
              }
              
            }else{
              setLoaderState(true);
              setSitesettings([]);
            }
        })
        .catch((err) => {
            setLoaderState(true);
            setSitesettings([]);
        });
  };

  useEffect(() => {
      Apicall();
      
  }, []);


      //Best Products
      const [Bestproduct , setBestproduct] = useState([]);
      let bestproducturl = Helper.SITEURL+'admin/api/weekly-best-sell-product';
      const Apibestproductcall = () => {
              const formData = new FormData();
              formData.append("userid", uid);
              formData.append("page", 1);
              formData.append("limit", 8);
              axios
              .post(bestproducturl,formData , {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((res) => {
  
                  if (res.status === 200) {

                      setLoaderState(false);
                      if(res.data.data !== undefined) {
                        setBestproduct(res.data.data);
                      }
                      
                  }else{
                      setLoaderState(false);
                      setBestproduct([]);
                  }
              })
              .catch((err) => {
                  setLoaderState(false);
                  setBestproduct([]);
              });
          };
      useEffect(() => {
        Apibestproductcall();
      }, [setBestproduct]);


      //New Product
      const [Newproduct , setNewproduct] = useState([]);
      let newproducturl = Helper.SITEURL+'admin/api/new-products';
      const Apinewproductcall = () => {

          const formData = new FormData();
          formData.append("userid", uid);
          formData.append("page", 1);
          formData.append("limit", 12);

              axios
              .post(newproducturl,formData , {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((res) => {
                if (res.status === 200) {
                    if(res.data.data !== undefined) {
                      setNewproduct(res.data.data);
                    }
                }else{
                  setNewproduct([]);
                }
            })
            .catch((err) => {
                setNewproduct([]);
            });        
      };

      useEffect(() => {
        Apinewproductcall();
      }, [setNewproduct]);  

    return(
      <>
          {loaderState !== false  ? <Loader />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          {loaderState === false  ?  <Banner /> : ''}
          {loaderState === false && Bestproduct !== undefined && Bestproduct !== '' ?   <Productslider slidetitle='Weekly Bestseller' slidedescription='Our most popular products best sales on this weak' product ={Bestproduct} settings ={Sitesettings} panelname = {'bestsale'} /> : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Productpannel title='Our Best Uses Categoy Product' description='' settings = {Sitesettings} /> : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Secondbanner settings = {Sitesettings} /> : ''}
          {loaderState === false  ? <Productlist title='New Arrival Product' description='Our Latest products for best sales on this weak' product ={Newproduct} settings = {Sitesettings} panelname = {'newarrival'}/> : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings}/> : ''}
    
      </>
    );

}
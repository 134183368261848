import {connect} from 'react-redux';
import Checkout from '../components/Checkout/Checkout';
import {cartProducts} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    setCartproductHandler:(data) => dispatch(cartProducts(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Checkout)
import React from 'react';

export default function Userdashboard(props) {

  return(
            <>
              <div id="dashboard" className="tab-pane fade active show">
											<h3>Dashboard</h3>
											<p>
												From your account dashboard. you can easily check &amp; view your
												<a className="text-decoration-underline" href="#">
													recent orders
												</a>
												, manage your
												<a className="text-decoration-underline" href="#">
													shipping and billing addresses
												</a>
												and
												<a className="text-decoration-underline" href="#">
													edit your password and account details.
												</a>
											</p>
							</div>
            </>
  );

}




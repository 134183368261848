import React, { useEffect, useState, Suspense } from "react";
import * as Helper from "../../Helper";



export default function Productcategory(props){

    const [Sitesettings , setSitesettings] = useState([]);

    useEffect(()=>{
        setSitesettings(props.settings);
    },[props.settings]);
    
  return(
                    <>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                            <p>
                                <a >
                                    <img className="blur-up ls-is-cached lazyloaded" data-src={props.logo} src={props.logo} alt={props.title} />
                                </a>
                            </p>
                            <h3 className="h4"><a >{props.title}</a></h3>
                            <div className="rte-setting">
                                <p dangerouslySetInnerHTML={{ __html: props.description}}></p>
                            </div>
                            <a href={'/products/&ct='+encodeURI(props.title)+'&pg=1&lt='+Sitesettings.product_limit} className="btn no-border">Shop Now</a>
                        </div>             
                    </>
  );

}

import Navbar from '../components/Navbar/Navbar'
import {connect} from 'react-redux'
import {setCategory} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps = (dispatch) => {
    return {
      setCategoryHandler:(data) => dispatch(setCategory(data))
    //   signinHideHandler:(data) => dispatch(signinHide(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Navbar)
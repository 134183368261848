import Productfiltersidebar from '../components/Productfiltersidebar/Productfiltersidebar';
import {connect} from 'react-redux'
import {productFilter , productBanner , totalProduct , activeLoader , setOrder} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    
    updateProductHandler:(data) => dispatch(productFilter(data)),
    updateBannerHandler:(data) => dispatch(productBanner(data)),
    updateTotalproductHandler:(data) => dispatch(totalProduct(data)),
    activeLoaderHandler:(data) => dispatch(activeLoader(data)),
    setToOrderHandler:(data) => dispatch(setOrder(data)),
    
    
})
export default connect(mapStateToProps,mapDispatchToProps)(Productfiltersidebar)
export const SITEURL = 'https://www.bajarsongi.com/';
export const CURRENCY = '₹';
export const CURRENCYTYPE = 'INR';
export const PAGELIMIT = '8';
export const SITENAME = 'Bajar Songi';
export const SITEDETAILS = 'Happy to connect with us.';
export function getDifferenceInDays(d1, d2) {
    //const diffInMs = Math.abs(date2 - date1);
    var date1 = new Date(d1);
    var date2 = new Date(d2);
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}
  
import Productdetails from '../components/Productdetails/Productdetails'
import {connect} from 'react-redux'
import {cartProducts , cartPrice , signinShow} from '../services/actions/action';

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps = (dispatch) => {
    return {
        setCartproductHandler:(data) => dispatch(cartProducts(data)),
        setcartPriceHandler:(data) => dispatch(cartPrice(data)),
        signinShowHandler:(data) => dispatch(signinShow(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Productdetails)
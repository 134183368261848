import React from 'react';
import safepay from '../../assets/images/credit-card.png';
import confidence from '../../assets/images/shield.png';
import worldwide from '../../assets/images/worldwide.png';
import phonecall from '../../assets/images/phone-call.png';

const divStyle = {
  marginTop: '5%',
};


const Deliverybanner = () => (
  <>
    <div class="prFeatures" style={divStyle} >
   <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 feature">
         <img src={safepay} alt="Safe Payment" title="Safe Payment" />
         <div class="details">
            <h3>Safe Payment</h3>
            Pay with the world's most payment methods.
         </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 feature">
         <img src={confidence} alt="Confidence" title="Confidence" />
         <div class="details">
            <h3>Confidence</h3>
            Protection covers your purchase and personal data.
         </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 feature">
         <img src={worldwide} alt="Worldwide Delivery" title="Worldwide Delivery" />
         <div class="details">
            <h3>Worldwide Delivery</h3>
            FREE &amp; fast shipping to over 200+ countries &amp; regions.
         </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 feature">
         <img src={phonecall} alt="Hotline" title="Hotline" />
         <div class="details">
            <h3>Hotline</h3>
            Talk to help line for your question on 4141 456 789, 4125 666 888
         </div>
      </div>
   </div>
</div>
  </>
);

export default Deliverybanner;


import React, {useEffect , useState } from 'react';
import Product from '../../containers/ProductContainer';
import * as Helper from '../../Helper';

import axios from 'axios';

export default function Productlist(props){

  var panelname = '';
  if (props.panelname !== undefined) {
    panelname = props.panelname;
  } 


  const [settings , setSettings] = useState([]);
  useEffect(()=>{
    setSettings(props.settings);
  },[props.settings]);

  return(
                    <>
                        <div class="product-rows section">
                          <div class="container">
                              <div class="row">
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div class="section-header text-center">
                                          <h2 class="h2">{props.title}</h2>
                                          <p>{props.description}</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="grid-products">
                                  <div class="row">

                                    { props.product !== undefined &&  props.product.map((sdata, i) => { 
                                        return ( <Product settings= {props.settings} product= {sdata}  type='type2' panel ={panelname}/>)
                                    })}

                                  </div>
                                  
                                  <div class="row">
                                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                          <a href={"/products/&pg=1&lt="+settings.product_limit} class="btn">View all</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
          
                    </>
  );

}




import React, { useEffect, useState, Suspense } from "react";
import parse from 'html-react-parser';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import axios from "axios";
import * as Helper from "../../Helper";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import Processsteps from "../Processsteps/Processsteps";


export default function Userorders(props) {

  const [uid, setUid] = useState(localStorage.getItem('UID'));
	const [token, setToken] = useState(localStorage.getItem('token'));
	const [orderlist, setOrderlist] = useState([]);


  	//user info call
	const Orderlist = () => {

		setToken(localStorage.getItem('token'));

		const formData = new FormData();
		formData.append("customerid", uid);

		  axios
		  .post(Helper.SITEURL +'admin/api/customer-order-list/',formData , {
			headers: {
			  'Content-Type': 'multipart/form-data',
			  'Authorization': 'Bearer '+token
			}
		  })
		  .then((res) => {

			  if (res.data.success === true) {
					setOrderlist((res.data.data));
			  }else{
          setOrderlist([])
			  }
		  })
		  .catch((err) => {
			  console.log(err);
		  });
	};

	useEffect(() => {
		Orderlist();
	}, [token]);


  const handlePageClick = (evt) => {
    var page = parseInt(evt.selected)+ 1;
    $(document).find('.orderlisttab').css('display', 'none');
    $(document).find('.ordertab'+page).css('display', '-webkit-box');
  }

  const handelPay = (evt) => {

        // let processtopay = Helper.SITEURL+'admin/api/process-to-pay';
        // const formData = new FormData();
        // formData.append("orderid", localStorage.getItem('orderid'));
        
        // axios
        //     .post(processtopay,formData , {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //             'Authorization': 'Bearer '+token
        //         }
        //     })
        //     .then((res) => {
        //     if (res.status === 200) {

        //         var transectiondata = new Array();

        //         transectiondata['name'] =  Sitesettings.Title;
        //         transectiondata['oid'] =  localStorage.getItem('orderid');
        //         transectiondata['description'] = Helper.SITEDETAILS;
        //         transectiondata['ammount'] = res.data.data.total.totalnetamount * 100;
        //         transectiondata['currency'] = Helper.CURRENCYTYPE;
        //         transectiondata['logo'] = Helper.SITEURL+'admin/'+Sitesettings.site_logo;
        //         transectiondata['contact'] = res.data.data.billingaddress[0].contact ? res.data.data.billingaddress[0].contact : '';
        //         transectiondata['email'] = res.data.data.billingaddress[0].email ? res.data.data.billingaddress[0].email : '';
        //         transectiondata['name'] = res.data.data.billingaddress[0].name ? res.data.data.billingaddress[0].name : '';
        //         transectiondata['razorpayorderid'] = res.data.data.razorpayorderid ? res.data.data.razorpayorderid : '';

        //         razorpaycall(transectiondata);
               
        //     }else{
                
        //     }
        // })
        // .catch((err) => {
            
        // });  
    
  }

  const handelCancel = (evt) => {

    let orderid = base64_decode(evt.target.getAttribute("dt-id"));

    if (window.confirm("Do you want to Cancel this Order?")) {
      if(orderid !== '' && uid !== ''){

        const formData = new FormData();
        formData.append("customerid", uid);
        formData.append("orderid", orderid);
        formData.append("status", 6);
  
  
        axios
        .post(Helper.SITEURL +'admin/api/customer-order-status-update/',formData , {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer '+token
        }
        })
        .then((res) => {
  
          if (res.data.success === true) {
            setOrderlist((res.data.data));
          }else{
            setOrderlist([])
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
    
  }

  let noview =4;
  let totalpage = 0;
  if(orderlist.order !== undefined && orderlist.order.length > 0) {

    totalpage = orderlist.order.length/noview;
  }

  return(
            <>


              
              <div id="orders" className="product-order tab-pane fade">
              <h3 className="title2">Orders</h3>

              <div className="d-flex justify-content-center row orderList">
                <div className="col-md-11">
                {orderlist.order !== undefined && orderlist.order.length > 0 ? (
                  <>
                    {orderlist !== undefined && Object.keys(orderlist.order).map((keyName, i) => (
                        <> 
                        <div  className={"row p-2 bg-white border rounded orderlisttab ordertab"+(parseInt(i / noview) + 1)}  style={(parseInt(i / noview) + 1) === 1 ? ({marginBottom:'12px !important' , visibility :'block'}) : ({marginBottom:'12px !important', display :'none'})}>
                          <div className="col-md-3 mt-1 p-0 orderlistimage">
                            <ul className=""> 

                            {(() => {

                              let row = []
                              var count = 0;
                              if(orderlist.order[i].productlist !== undefined && orderlist.order[i].productlist !=='' && orderlist.order[i].productlist.length < 3) {
                                
                                orderlist.order[i].productlist.forEach(element => {
                                  count ++ ;
                                  if(count <= 1) {
                                    row.push(<li style={{width: "98%"}}><img className="img-fluid img-responsive rounded product-image" src={element.product_image[0].large}  alt={element.product_image[0].large}/></li>)
                                  }
                                });
                              }else if(orderlist.order[i].productlist !== undefined && orderlist.order[i].productlist !=='' && (orderlist.order[i].productlist.length >= 3 )) {
                                orderlist.order[i].productlist.forEach(element => {
                                  count ++ ;
                                  if(count <= 4) {
                                    row.push(<li style={{width: "48%"}}><img className="img-fluid img-responsive rounded product-image" src={element.product_image[0].large}  alt={element.product_image[0].large}/></li>)
                                  }
                                });
                              }
                              return row;
                              })()}

                            </ul> 
                            
                            
                          </div>
                          <div className="col-md-7 mt-1">
                            <h5><i class="fa fa-cart-arrow-down"></i> {orderlist.order[i].orderid}</h5>
                            <div className="mt-1 mb-1 spec-1">

                                    { orderlist.order[i].productlist !== undefined && orderlist.order[i].productlist !== '' &&  orderlist.order[i].productlist.map((pdata, i) => { 
                                        
                                        return (
                                          <>
                                          <span className="dot"></span>
                                          <span>{ pdata.product_name +'-'+ pdata.product_quantity +''+ pdata.product_varient_unit + ' x ' + pdata.checkout_product_quantity }</span>
                                          </>
         
                                        )
                                    })}
                              
                              
                            </div>
                            

                            <Processsteps state ={orderlist.order[i].status} />
                          </div>
                          <div className="align-items-center align-content-center col-md-2 border-left mt-1">
                            <div className="d-flex flex-row align-items-center">
                              <h4 className="mr-1">{Helper.CURRENCY+orderlist.order[i].orderprice}</h4>
                            </div>
                            <h6 className="text-success">{orderlist.order[i].delivery_method}</h6>
                            <div className="d-flex flex-column mt-4">

                            {(() => {

                                let row = []
                                
                                if(orderlist.order[i].status !== undefined && orderlist.order[i].status !== 1 ) {
                                  row.push(<button className="btn btn-primary btn-sm" type="button" onClick={handelPay}>Pay Now</button>)
                                }

                                if(orderlist.order[i].transaction_status !== undefined && orderlist.order[i].transaction_status !== 1 ) {
                                  
                                  row.push(<button className="btn btn-primary btn-sm" type="button" onClick={handelPay}>Pay Now</button>)
                                }
                                return row;
                              })()}

                              {(() => {

                                let row = []
                                if(orderlist.order[i].status !== undefined && (orderlist.order[i].status !== 3 || orderlist.order[i].status !== 8 ) ) {
                                  
                                  row.push(<button className="btn btn-primary btn-sm mt-2 btn-cancel" type="button" dt-id={base64_encode(orderlist.order[i].orderid)}  onClick={handelCancel}>Cancel</button>)
                                }
                                return row;
                              })()}
                              
                              
                            </div>
                          </div>
                        </div>
                        </>
                    ))}

                  </>
                  ): <div class="list-view-items grid--view-items" style={{textAlign:'center'}} ><strong>No Order Placed Yet</strong></div> } 
                  
                  <div >
                    <div class="infinitpaginOuter">
                        <div class="infinitpagin">

                        {orderlist.order !== undefined && orderlist.order.length > 0 ? (
                                  <ReactPaginate
                                    breakLabel=".."
                                    nextLabel="next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalpage}
                                    previousLabel="< prev"
                                    renderOnZeroPageCount={null}
                                    forcePage={0}
                                  />
                        ): '' } 

                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
              </div>
            </>
  );

}



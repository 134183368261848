import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import * as Helper from '../../Helper';

export default function Secondbanner(props) {

        return(
                  <>
                    <div className="section">
                        <div className="hero hero--large hero__overlay bg-size" style={{ backgroundImage: `url(${Helper.SITEURL+'/admin/'+props.settings.section_second_banner})` }} >
                            <img className="bg-img" src= {Helper.SITEURL+'/admin/'+props.settings.section_second_banner} alt="" style={{ display: 'none' }}/>
                            <div className="hero__inner">
                                <div className="container">
                                    <div className="wrap-text left text-small font-bold">
                                        <h2 className="h2 mega-title">Belle The best choice for your store</h2>
                                        <div className="rte-setting mega-subtitle">Lorem Ipsum is simply dummy text of the printing
                                            and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s.</div>
                                        <a href="#" className="btn">Purchase Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </>
        );

}






import Useraddress from '../components/Useraddress/Useraddress';
import {connect} from 'react-redux';
import {loadAddress} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps=(dispatch)=>({
    //loadAddressHandler:(data) => dispatch(loadAddress(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Useraddress)
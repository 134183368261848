import React from 'react';
import Reviewstar from '../Reviewstar/Reviewstar';
import dateFormat from 'dateformat';


export default function Reviewcomment(props) {

    return ( 
        <div class="spr-review">
        <div class="spr-review-header">
            <span class="product-review spr-starratings spr-review-header-starratings">
                <img src={props.data.customer_profileimage} width="50" height="50" class="rounded-circle border-1px" />
                <span class="reviewLink reviewstar">
                    <Reviewstar level={props.data.rating}  />
                </span>
            </span>
            <h3 class="spr-review-header-title">{props.data.customer_name}</h3>
            <span class="spr-review-header-byline">
                <strong>{dateFormat(props.data.created_at, "mmmm dS, yyyy")}</strong>
            </span>
        </div>
        <div class="spr-review-content">
            <p class="spr-review-content-body">{props.data.review}</p>
        </div>
        <div class="spr-review-content">
            { props.data.reviewimage !== undefined && props.data.reviewimage.length > 0 &&  props.data.reviewimage.map((reviewimage, i) => { 
				return (<img src={reviewimage}  height="250" class="border-1px" />)
			})}
        </div>
    </div>
    );

}

import React , { useState, useCallback , useEffect , useRef , Suspense} from 'react';
import ReactDOM from 'react-dom';
import Editaddressblock  from '../../containers/EditaddressblockContainer'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Helper from '../../Helper';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from '../../services/reducers/index';

//Axios
import axios from 'axios';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

const successstyle = {
  'text-align' : "center",
  'float': 'left'
};

const successcontainer = {
  'paddingLeft': '15px',
  'paddingRight': '15px'
};

const store = createStore(reducer)

function Editaddress(props){

  var settings = {
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false
    
  };
  

  //ADDRESS Validation
  const validationSchema = Yup.object().shape({
      name: Yup.string()
          .required('Please Enter Your Name.'),
      contact: Yup.string()
          .required('Please Enter Your Valid Contact Number.'),
      pincode: Yup.string()
          .required('Please Enter Your Pincode.'),
      street: Yup.string()
          .required('Please Enter Street.'),
      address: Yup.string()
          .required('Please Write down the address.'),    
      city: Yup.string()
          .required('Please add City.'),
      state: Yup.string()
          .required('Please add State.')          
    });

    const [token, setstate] = useState(localStorage.getItem('token'))
    const formOptions = { resolver: yupResolver(validationSchema) };
    const [uid, setUid] = useState(localStorage.getItem('UID'));

    //get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');



      const [address, setAddress] =  useState([]);


      const addressblockreturn = (event) => {
        var currentnode =  event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        ReactDOM.render(<Editaddressblock info ={address} /> , currentnode);
      }

      const [getdata, setGetdata] = useState(false);

    	//user info call
      const ApiAddresscall = () => {
              const formData = new FormData();
              formData.append("customerid", uid);
              formData.append("addressid", props.id);

                axios
                .post(Helper.SITEURL +'admin/api/customer-single-address/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {
                    if (res.data.success === true) {
                      setAddress((res.data.data[0]));
                      setGetdata(true);
                    }else{
                      setAddress([])
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    };

    useEffect(() => {
        ApiAddresscall();
    }, [props.id]);

    
    // const onSubmitclick = (event) => {
    //   event.preventDefault();
    //   setValue(event.target.value);
    //   console.log(sdata);
    // };

    //API call ADDRESS

    // const onSubmit = (event) => {

    //   if(sdata.name!== undefined && sdata.name !== ''){

    //     const formData = new FormData();
    //     formData.append("name", sdata.name);
    //     formData.append("contact", sdata.contact);
    //     formData.append("pincode", sdata.pincode);
    //     formData.append("street", sdata.street);
    //     formData.append("address", sdata.address);
    //     formData.append("city", sdata.city);
    //     formData.append("state", sdata.state);
    //     formData.append("optionalphone", sdata.optionalphone);
    //     formData.append("street", sdata.street);
    //     formData.append("addresslocation", sdata.addresslocation);
    //     formData.append("customerid", uid);
    //     formData.append("addressid", props.id);
    //     formData.append("addresstype", '1');
        
        
    //       axios
    //       .post(Helper.SITEURL +'admin/api/customer-address-add/',formData , {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': 'Bearer '+token
    //         }
    //       })
    //       .then((res) => {

    //           if (res.data.success === true) {
    //             setShowSuccess(true);
    //               // var currentnode =  sevent.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
    //               // ReactDOM.render(<Editaddressblock info ={formData} /> , currentnode);
    //             //setMessage(res.data.message);
    //             toastSuccess(res.data.message);
    //           }else{
    //             setShowError(true);
    //             toastError(res.data.message);
    //             //setMessage(res.data.message);
    //           }
    //       })
    //       .catch((err) => {
    //           console.log(err);
    //       });

    //   }
    //   return false;
    // };

    function onSubmit(sdata) {

            if(sdata.name!== undefined && sdata.name !== ''){

              const formData = new FormData();
              formData.append("name", sdata.name);
              formData.append("contact", sdata.contact);
              formData.append("pincode", sdata.pincode);
              formData.append("street", sdata.street);
              formData.append("address", sdata.address);
              formData.append("city", sdata.city);
              formData.append("state", sdata.state);
              formData.append("optionalphone", sdata.optionalphone);
              formData.append("street", sdata.street);
              formData.append("addresslocation", sdata.addresslocation);
              formData.append("customerid", uid);
              formData.append("addressid", props.id);
              formData.append("addresstype", '1');
              
              
                axios
                .post(Helper.SITEURL +'admin/api/customer-address-add/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                        setShowSuccess(true);
                        // var currentnode =  sevent.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
                        // ReactDOM.render(<Editaddressblock info ={formData} /> , currentnode);
                        toastSuccess(res.data.message);
                        var parentDOM = document.getElementById('editform');
                        ReactDOM.render(<Provider store={store}><Editaddressblock info ={res.data.data[0]} /></Provider> , parentDOM.parentNode.parentNode.parentNode);
                      //setMessage(res.data.message);
                        
                    }else{
                      setShowError(true);
                      toastError(res.data.message);
                      //setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }
            return false;
    }

    return(
      <>
        {getdata === true ? 
        
        <div class="card">
                  <div className="account-login-form bg-light-gray padding-20px-all">
                    <form id='editform' method="post" onSubmit={handleSubmit(onSubmit)} >
                    <fieldset  style={{margin:'25px'}}>
                        <div className="row" style={{marginLeft:'0px'}}>
                          <h1>EDIT ADDRESS</h1>
                        </div >

                        {/* { showSuccess ?
                        <div className="row" style = {successcontainer}>
                            <div id="success" className="alert alert-success" style = {successstyle}>
                                <strong>{message}</strong>
                            </div>
                        </div>
                        : '' }
                        { showError ?
                        <div className="row" style = {successcontainer}>
                            <div id="error" className="alert alert-danger" style = {successstyle}>
                                <strong>{message}</strong>
                            </div>
                        </div>
                        : '' }                               */}
                        <div className="row">
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                            <input {...register('id' , { required: true })}  value={address.id} type="hidden" name="id" />
                            <input {...register('name' , { value: address.name } , { required: true })} className={`form-control ${errors.name ? 'invalid' : ''}`} type="text" name="name" placeholder="Name" />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                          </div>
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                            <input {...register('contact' , { value: address.contact } , { required: true })} className={`form-control ${errors.contact ? 'invalid' : ''}`} type="text" name="contact" placeholder='10-digit mobile number' />
                            <div className="invalid-feedback">{errors.contact?.message}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                            <input {...register('pincode' , { value: address.pincode } , { required: true })} className={`form-control ${errors.pincode ? 'invalid' : ''}`} type="text" name="pincode" placeholder='Pincode' />
                            <div className="invalid-feedback">{errors.pincode?.message}</div>
                          </div>
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                            <input {...register('street' , { value: address.street } , { required: true })} className={`form-control ${errors.street ? 'invalid' : ''}`} type="text" name="street" placeholder='Address (Area and Street)' />
                            <div className="invalid-feedback">{errors.street?.message}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-10 col-lg-10 col-xl-10 required">
                              <textarea  {...register('address' , { value: address.address } , { required: true })} className={`form-control ${errors.address ? 'invalid' : ''}`} id="address" name="address" rows="4" cols="50" maxlength="200" placeholder='Address (Area and Street)'>
                              </textarea>
                              <div className="invalid-feedback">{errors.address?.message}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                            <input {...register('city' , { required: true })} className={`form-control ${errors.city ? 'invalid' : ''}`} value={address.city} type="text" name="city" placeholder='City/District/Town' />
                            <div className="invalid-feedback">{errors.city?.message}</div>
                          </div>
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                              <select {...register('state' , { value: address.state } , { required: true })} className={`form-control ${errors.state ? 'invalid' : ''}`} name="state" required="" tabindex="7">
                                <option value="" disabled="">--Select State--</option><option value="Andaman &amp; Nicobar Islands">Andaman &amp; Nicobar Islands</option><option value="Andhra Pradesh">Andhra Pradesh</option><option value="Arunachal Pradesh">Arunachal Pradesh</option><option value="Assam">Assam</option><option value="Bihar">Bihar</option><option value="Chandigarh">Chandigarh</option><option value="Chhattisgarh">Chhattisgarh</option><option value="Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu">Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu</option><option value="Delhi">Delhi</option><option value="Goa">Goa</option><option value="Gujarat">Gujarat</option><option value="Haryana">Haryana</option><option value="Himachal Pradesh">Himachal Pradesh</option><option value="Jammu &amp; Kashmir">Jammu &amp; Kashmir</option><option value="Jharkhand">Jharkhand</option><option value="Karnataka">Karnataka</option><option value="Kerala">Kerala</option><option value="Ladakh">Ladakh</option><option value="Lakshadweep">Lakshadweep</option><option value="Madhya Pradesh">Madhya Pradesh</option><option value="Maharashtra">Maharashtra</option><option value="Manipur">Manipur</option><option value="Meghalaya">Meghalaya</option><option value="Mizoram">Mizoram</option><option value="Nagaland">Nagaland</option><option value="Odisha">Odisha</option><option value="Puducherry">Puducherry</option><option value="Punjab">Punjab</option><option value="Rajasthan">Rajasthan</option><option value="Sikkim">Sikkim</option><option value="Tamil Nadu">Tamil Nadu</option><option value="Telangana">Telangana</option><option value="Tripura">Tripura</option><option value="Uttarakhand">Uttarakhand</option><option value="Uttar Pradesh">Uttar Pradesh</option><option value="West Bengal">West Bengal</option>
                              </select>
                              <div className="invalid-feedback">{errors.state?.message}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-5 col-lg-5 col-xl-5">
                            <input {...register('landmark' , { value: address.landmark })} className={`form-control ${errors.landmark ? 'invalid' : ''}`} type="text" name="landmark" placeholder='Landmark (Optional)' />
                            <div className="invalid-feedback">{errors.landmark?.message}</div>
                          </div>
                          <div className="form-group col-md-5 col-lg-5 col-xl-5">
                            <input {...register('optionalphone' , { value: address.optionalphone })} className={`form-control ${errors.optionalphone ? 'invalid' : ''}`} type="text" name="optionalphone" placeholder='Alternate Phone (Optional)' />
                            <div className="invalid-feedback">{errors.optionalphone?.message}</div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                              <label for="HOME" className="form-group">
                                <input {...register('addresslocation')} checked={`${address.addresslocation === 'h' ? 'true' : 'false'} `}   type="radio" value ='h' className="form-group" name="addresslocation" readonly="" id="HOME" />&nbsp;
                                HOME
                              </label>
                              <label for="WORK" className="form-group" style={{marginLeft:'10px'}}>
                                <input {...register('addresslocation')} checked={`${address.addresslocation === 'w' ? 'false' : 'false'} `}  type="radio" value ='w' className="form-group" name="addresslocation" readonly="" id="WORK" />&nbsp;
                                WORK
                              </label>
                          </div>
                        </div>

                        <input type="submit" className="btn margin-15px-top btn-primary" value="Save" />
                        <button type="button" className="btn margin-15px-top btn-primary" style={{marginLeft:'10px' , background:'gray'}} onClick={(e) => addressblockreturn(e)}>
                          Cancel
                        </button>
                      </fieldset>
                    
                    </form>
                  </div>
        </div>
        : ''}
      </>
  )
}
export default Editaddress;


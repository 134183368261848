export const ON_SIGNIN="ON_SIGNIN"
export const ON_SIGNIN_HIDE="ON_SIGNIN_HIDE"
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE"
export const LOAD_ADDRESS = "LOAD_ADDRESS"
export const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS"
export const PRODUCT_SIDE_FILTER = "PRODUCT_SIDE_FILTER"
export const PRODUCT_FILTER = "PRODUCT_FILTER"
export const PRODUCT_BANNER = "PRODUCT_BANNER"
export const TOTAL_PRODUCT = "TOTAL_PRODUCT"
export const ACTIVE_LOADER = "ACTIVE_LOADER"
export const SET_CATEGORY = "SET_CATEGORY"
export const SET_PAGE = "SET_PAGE"
export const SET_ORDER = "SET_ORDER"
export const SET_TO_ORDER = "SET_TO_ORDER"
export const SET_CART = "SET_CART"
export const SET_PRICE = "SET_PRICE"
export const SHOW_SEARCH = "SHOW_SEARCH"
export const ON_FORGOT_HIDE = "ON_FORGOT_HIDE"
export const ON_FORGOT_SHOW = "ON_FORGOT_SHOW"
export const SETTINGS = "SETTINGS"



import React, { useEffect, useState, Component , lazy , Suspense } from "react";
import * as $ from 'jquery'
//Redux
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './services/reducers/';

import { BrowserRouter, Switch, Route , Redirect } from "react-router-dom";
import "./App.css";

//css
import "./assets/css/plugins.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/overwrite.css";
import "./assets/css/responsive.css";
import 'react-toastify/dist/ReactToastify.css';

//components
import Homepage from "./components/Homepage/Homepage";
import Productdetails from "./containers/ProductdetailsContainer";
import UserProfile from "./containers/UserProfileContainer";
import Productfilter from "./containers/ProductfilterContainer";
import Cart from "./containers/CartContainer";
import Checkout from "./containers/CheckoutContainer";
import Pages from "./components/Pages/Pages";
import Responsepayment from "./components/Responsepayment/Responsepayment";
import Passwordreset from "./components/Passwordreset/Passwordreset";

import axios from 'axios';
import * as Helper from "./Helper";

var loadScript = function(src) {
	var tag = document.createElement('script');
	tag.async = false;
	tag.src = src;
	document.getElementsByTagName('body')[0].appendChild(tag);
}

const Notfound = lazy(() => {
	return new Promise(resolve => {
	  setTimeout(() => resolve(import("./components/Notfoundpage/Notfoundpage")), 500);
	});
});

const javascript = () => {

	$(".sidebar_categories .sub-level a").on("click", function() {
		$(this).toggleClass('active');
		$(this).next(".sublinks").slideToggle("slow");
	}); 
}


const token = localStorage.getItem('token');
const store = createStore(reducer);

function App() {

	  //API call
	  const [pageurl, setPageurl] = useState();
	  let url = Helper.SITEURL+'admin/api/pageurl';
	  const routedynamic = () => {
		axios
			.get(url)
			.then((res) => {
				if (res.status === 200) {
	
					setPageurl(res.data.data);
				  
				}else{
					setPageurl([]);
				 
				}
			})
			.catch((err) => {
				setPageurl([]);
			});
	  	};

	useEffect(() => {

		routedynamic();
		
	}, []);

	useEffect(() => {
		javascript();
	}, []);

	return (
		
		<Provider store={store}>
			
		<main>
		  
			<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<Switch>
					<Route path="/" component={Homepage} exact />
					<Route path="/product/:id" component={Productdetails} />
					<Route path="/products/:param" component={Productfilter} />
					<Route path="/user/dashboard" render={props => {
						if (token === undefined || token === '' || token === null) {
							// not logged in so redirect to login page with the return url
							return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
						}
						// authorized so return component
						return <UserProfile/>
					}} />
					<Route path="/viewcart/" component={Cart} />
					<Route path="/checkout/" component={Checkout} />
					<Route path="/payment/status/:id" component={Responsepayment} />
					<Route path="/token/verify/:token" component={Passwordreset} />
					
				
					{pageurl && pageurl.map(item => (
						
						<Route key={item}  path={item} component={Pages} exact />
					))}
					
					<Route component={Notfound} />
				</Switch>
			</Suspense>	
			</BrowserRouter>
		  
		</main>
		</Provider>
		
	);
}

export default App;

import { LOAD_ADDRESS } from '../constants'

export default function loadAddressCall(state = [], action) {
    switch (action.type) {
        case LOAD_ADDRESS:
            return action.loadaddress
        default:
            return false;
    }
}

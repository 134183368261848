import Header from '../components/Header/Header'
import {connect} from 'react-redux'
import {cartProducts , cartPrice , showSearch , setSettings} from '../services/actions/action'

const mapStateToProps=(state)=>({
    data:state
})
const mapDispatchToProps = (dispatch) => {
    return {
        setCartproductHandler:(data) => dispatch(cartProducts(data)),
        setcartPriceHandler:(data) => dispatch(cartPrice(data)),
        searchShowHandler:(data) => dispatch(showSearch(data)),
        setsiteSettingsHandler:(data) => dispatch(setSettings(data)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Header)
import React, {useEffect , useState } from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import * as Helper from "../../Helper";
import $ from 'jquery';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import Productprice from '../Productprice/Productprice';
import noimage from '../../assets/images/noprofileimage.png';

import axios from "axios";

export default function Header(props) {

	const token = localStorage.getItem('token');
	const [profileImage, setprofileImage] = useState(localStorage.getItem('profileImage'));

	useEffect(() => {

		if(profileImage === undefined || profileImage === '' || profileImage === null ){
			setprofileImage(noimage);
		}

	}, [profileImage])

	//update profile iamge 
	useEffect(() => {
		if(props.data.setimageCall.length !== 0){
			setprofileImage(props.data.setimageCall);
		}
	}, [props.data.setimageCall])

	function toggel() {
		$("#header-cart3").slideToggle("slow");
	}

	function logout() {
		localStorage.clear();
		window.location.reload(); 
	}
	let logo = Helper.SITEURL + "admin/" + props.settings.site_logo;

	//API call
	const [navmenu, setNavmenu] = useState([]);
	let url = Helper.SITEURL+'admin/api/product-category';
	const headnavcall = () => {
		  axios
			  .get(url)
			  .then((res) => {
				  if (res.status === 200) {
  
						setNavmenu(res.data.data);
				  
				  }else{
					
						setNavmenu([]);
				  }
			  })
			  .catch((err) => {
				  
				  setNavmenu([]);
			  });
	};
  
	useEffect(() => {
		headnavcall();
	}, []);

	//cart Product
	const Apicartproductcall = () => {

	  axios
	  .get('https://geolocation-db.com/json/')
	  .then((res) => {

		  if (res.status ===200) {

				const formData = new FormData();
				formData.append("carttype", 'ip');
				formData.append("userip", res.data.IPv4);
				let cartproducturl = Helper.SITEURL+'admin/api/go-to-cart';
				  axios
				  .post(cartproducturl,formData , {
					headers: {
					  'Content-Type': 'multipart/form-data'
					}
				  })
				  .then((res) => {

					if (res.status === 200) {
						if(res.data.data !== undefined) {
							props.setCartproductHandler(res.data.data.cart);
							props.setcartPriceHandler(res.data.data.total);
						}
					}else{
						props.setCartproductHandler([]);
						props.setcartPriceHandler([]);
					}
				})
				.catch((err) => {
					props.setCartproductHandler([]);
					props.setcartPriceHandler([]);
				});        
			
		  }
		
	  })
	  .catch((err) => {
		  
	  });

	};

	useEffect(() => {
	  Apicartproductcall();
	}, []);  

	const [carttoggel, setCarttoggel] = useState(false);
	

	const [cartproducts , setproducts] = useState([]);
	useEffect(() => {
		setproducts(props.data.cartProducts);
	}, [props.data.cartProducts]);  
	
	const [cartprice , setcartprice] = useState([]);
	useEffect(() => {
		setcartprice(props.data.cartPrice);
	}, [props.data.cartPrice]);  

	const [Sitesettings , setSitesettings] = useState([]);

        //API call
        let surl = Helper.SITEURL+'admin/api/settings';
        const Apicall = () => {
          axios
              .get(surl)
              .then((res) => {
                  if (res.status === 200) {

                    if(res.data.data !== undefined) {
                      setSitesettings(res.data.data);
					  props.setsiteSettingsHandler(res.data.data);
                    }
                  
                  }else{
                    setSitesettings([]);
					props.setsiteSettingsHandler([]);
                  }
              })
              .catch((err) => {
                  setSitesettings([]);
				  props.setsiteSettingsHandler([]);
              });
        };
  
        useEffect(() => {
            Apicall();
        }, [setSitesettings]);

	      //cart update Product
		  const Updatecartproductcall = (data) => {

			axios
			.get('https://geolocation-db.com/json/')
			.then((res) => {
	
				if (res.status ===200) {
	
					  const formData = new FormData();
					  formData.append("carttype", 'ip');
					  formData.append("userip", res.data.IPv4);
					  formData.append("cartid", data['cid']);
					  formData.append("product_quantity", data['qut']);
	
	
					  let cartproducturl = Helper.SITEURL+'admin/api/add-to-cart';
						axios
						.post(cartproducturl,formData , {
						  headers: {
							'Content-Type': 'multipart/form-data'
						  }
						})
						.then((res) => {
	
						  if (res.status === 200) {
							  if(res.data.data !== undefined) {
								props.setCartproductHandler(res.data.data.cart);
								props.setcartPriceHandler(res.data.data.total);
							  }
						  }else{
							props.setCartproductHandler([]);
							props.setcartPriceHandler([]);
						  }
					  })
					  .catch((err) => {
						props.setCartproductHandler([]);
					  });        
				  
				}
			  
			})
			.catch((err) => {
				
			});
	
		  };
	
		  function incrementValue(e) {
			e.preventDefault();
			var attr = base64_decode(e.currentTarget.dataset.id); 
			var value = document.getElementById("qty-"+attr).value;
			var cartid = document.getElementById("qty-"+attr).getAttribute('cid');
			cartid = base64_decode (cartid);
	
			const setdata = new Array();
			setdata['cid'] = cartid;
			setdata['qut'] = parseInt(value)+parseInt(1);
	
			Updatecartproductcall(setdata);
	
		  }
	
		  function decrementValue(e) {
			e.preventDefault();
			var attr = base64_decode(e.currentTarget.dataset.id); 
			var value = document.getElementById("qty-"+attr).value;
			var cartid = document.getElementById("qty-"+attr).getAttribute('cid');
			cartid = base64_decode (cartid);
	
			const setdata = new Array();
			setdata['cid'] = cartid;
			setdata['qut'] = parseInt(value)-parseInt(1);
	
			Updatecartproductcall(setdata);
		  }

		  function removeProduct(e) {
			e.preventDefault();
			var cartid = e.currentTarget.dataset.id;
			cartid = base64_decode (cartid);
	
			const formData = new FormData();
			formData.append("cartid", cartid);
		   
			if (cartid !== undefined && cartid !== '') {
					  let cartproducturl = Helper.SITEURL+'admin/api/remove-from-cart';
						axios
						.post(cartproducturl,formData , {
						  headers: {
							'Content-Type': 'multipart/form-data'
						  }
						})
						.then((res) => {
	
						  if (res.status === 200) {
							  if(res.data.data !== undefined) {
								props.setCartproductHandler(res.data.data.cart);
								props.setcartPriceHandler(res.data.data.total);
							  }
						  }else{
							props.setCartproductHandler([]);
							props.setcartPriceHandler([]);
						  }
					  })
					  .catch((err) => {
						props.setCartproductHandler([]);
						props.setcartPriceHandler([]);
					  }); 
			}
	
		  }

	    //showmenu
		const [showsodemenu, setShowsodemenu] = useState(false);
		const handlesShow = () => setShowsodemenu(true);
		const handlesHide = () => setShowsodemenu(false);

		const [uid, setuid] = useState(localStorage.getItem('UID'))
  
		function checkoutprocess(pid = ''){
		  if (token === '' ||	token === undefined ||	token === null ) {
			  props.signinShowHandler({show:true});
		  } else {
  
			axios
			.get('https://geolocation-db.com/json/')
			.then((res) => {
  
				if (res.status ===200) {
  
					  const formData = new FormData();
					  formData.append("userip", res.data.IPv4);
					  formData.append("userid", uid);
					  
					  let cartproducturl = Helper.SITEURL+'admin/api/checkout';
						axios
						.post(cartproducturl,formData , {
						  headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer '+token
						  }
						})
						.then((res) => {
  
						  if (res.status === 200) {
  
							  if(res.data.data.order[0] !== undefined) {
								
								localStorage.setItem('orderid', res.data.data.order[0].orderid);
								window.location.href = "/checkout/";
								
							  }
						  }else{
							props.setCartproductHandler([]);
							props.setcartPriceHandler([]);
						  }
					  })
					  .catch((err) => {
						props.setCartproductHandler([]);
						props.setcartPriceHandler([]);
					  });        
				  
				}
			  
			})
			.catch((err) => {
				
			});
		  }
		}

		
	

	return (
		<>
			<div class="header-wrap animated d-flex border-bottom">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="logo col-md-2 col-lg-2 d-none d-lg-block">
							<a href="/">
								<img src={logo} alt={logo} title={props.settings.Title} style={{width:'74%'}} />
							</a>
						</div>

						<div class="col-4 col-sm-3 col-md-3 col-lg-8">
							<div class="d-block d-lg-none">
								<button type="button" class="btn--link site-header__menu js-mobile-nav-toggle mobile-nav--open" onClick={handlesShow}>
									<i class="icon anm anm-times-l"></i>
									<i class="anm anm-bars-r"></i>
								</button>
							</div>

							<nav class="grid__item" id="AccessibleNav">
								<ul id="siteNav" class="site-nav medium center hidearrow">
									<li class="lvl1 parent megamenu">
										<a href="/">
											Home <i class="anm anm-angle-down-l"></i>
										</a>
									</li>

									{ navmenu !== undefined && navmenu !== '' &&  navmenu.map((navdata, i) => { 
                                        return ( 
											<li class="lvl1 parent megamenu">
												<a href={'/products/&ct='+encodeURI(navdata.category_name)+'&pg=1&lt='+Sitesettings.product_limit}>
													{navdata.category_name} <i class="anm anm-angle-down-l"></i>
												</a>
											</li>
										)
                                    })}

									<li class="lvl1">
										<a href="/about-us">About</a>
									</li>
								</ul>
							</nav>
						</div>

						<div class="col-4 col-sm-6 col-md-6 col-lg-2 d-block d-lg-none mobile-logo">
							<div class="logo">
								<a href="/">
									<img src={logo} alt={logo} title={props.settings.Title} />
								</a>
							</div>
						</div>

						<div class="col-4 col-sm-3 col-md-3 col-lg-2">
							<div class="site-cart">
								<a href="javascript:void(0)" class="site-header__cart" title="Cart" onClick={() => setCarttoggel(!carttoggel)}>
									<i class="icon anm anm-bag-l"></i>
									<span
										id="CartCount"
										class="site-header__cart-count"
										data-cart-render="item_count"
									>
										{cartproducts.length}
									</span>
								</a>
								<div id="header-cart" class="block block-cart" style={{display: carttoggel ? 'block' : 'none' }} >
									
								{ cartproducts !== undefined && cartproducts.length > 0 ?	
									<ul class="mini-products-list">

									{cartproducts !== undefined && cartproducts.map((item ,key)=>{ 

										return(
										<li class="item" key={key}>
											<a class="product-image" href={Helper.SITEURL+'product/'+item.productslug}>
												<img src={item.productimage} title={item.productname} />
											</a>
											<div class="product-details">
												<a href="javascript:void(0)" onClick={removeProduct} data-id={base64_encode(item.id)}  class="remove">
													<i class="anm anm-times-l" aria-hidden="true"></i>
												</a>
												
												<a class="pName" href={Helper.SITEURL+'product/'+item.productslug}>
													{item.productname}
												</a>
												<div class="wrapQtyBtn">
													<div class="qtyField">
														<span class="label">Qty:</span>
														<a className="qtyBtn minus" href="javascript:void(0);" data-id={base64_encode(key)} onClick={decrementValue}>
                                                        <i className="icon icon-minus"></i>
                                                      	</a>
														<input className="cart__qty-input qty" type="text" name="updates[]"
															id={'qty-'+key} value={item.productquantity} pattern="[0-9]*" min="1" cid = {base64_encode(item.id)} />
														<a className="qtyBtn plus" href="javascript:void(0);" data-id={base64_encode(key)} onClick={incrementValue}>
															<i className="icon icon-plus"></i>
														</a>
													</div>
												</div>
												<div class="priceRow">
													<div class="product-price">
														
														{item.totalprice !== undefined && item.totalprice > 0 ? (<Productprice price ={item.mainprice} sale ={item.offerpercentage} offerstatus={(item.offerstatus === 1 )? true : false } type='type2' />) : ''}
													</div>
												</div>
											</div>
										</li>
										)
										
									})}

									</ul>

								 	: <ul class="mini-products-list">NO product In Cart</ul>}

									<div class="total">
										<div class="total-in">
											<span class="label">Cart Subtotal:</span>
											<span class="product-price">
												<span class="money">{Helper.CURRENCY+parseFloat(cartprice.totalgrossamount).toFixed(2)}</span>
											</span>
										</div>
										<div class="buttonSet text-center">

											<Link class="btn btn-secondary btn--small" to="/viewcart/">View Cart</Link>
											<a class="btn btn-secondary btn--small" herf="javascript:void(0)" onClick={checkoutprocess}>Checkout</a>
											
										</div>
									</div>
								</div>
							</div>
							<div class="site-header__search">
								<button type="button" class="search-trigger" onClick={()=>{props.searchShowHandler({show:true})} } >
									<i class="icon anm anm-search-l"></i>
								</button>
							</div>

							{ token === '' ||	token === undefined ||	token === null ?
								''       
								:  
								<>
									<div class="site-cart">
										<a href="javascript:void(0);" class="site-header__cart2" title="Cart" onClick={ toggel }>
											<img src={profileImage} width="50" height="50" class="rounded-circle" />
										</a>
										
										<div id="header-cart3" class="block block-cart">
											<ul class="mini-products-list">
												<li class="item">
													<Link to="/user/dashboard" >Profile</Link>
												</li>
												<li class="item">
													<a href="javascript:void(0);" onClick={logout}>Logout</a>
												</li>
											</ul>
										</div>
									</div> 
								</>  
							}

						</div>
					</div>
				</div>
			</div>

			{/* <!--Mobile Menu--> */}
			<div class={showsodemenu ===true ? 'mobile-nav-wrapper active' : 'mobile-nav-wrapper' } role="navigation">
				<div class="closemobileMenu" onClick={handlesHide}><i class="icon anm anm-times-l pull-right"></i> Close Menu</div>
				<ul id="MobileNav" class="mobile-nav">
					
					{ navmenu !== undefined && navmenu !== '' &&  navmenu.map((navdata, i) => { 
                        return ( 
							<li class="lvl1 parent megamenu">
								<a href={'/products/&ct='+encodeURI(navdata.category_name)+'&pg=1&lt='+Sitesettings.product_limit}>
									{navdata.category_name} <i class="anm anm-angle-down-l"></i>
								</a>
							</li>
						)
                    })}
					<li class="lvl1"><a href="/about-us"><b>About Us</b></a>
					</li>
				</ul>
			</div>
        	{/* <!--End Mobile Menu--> */}
		</>
	);
}

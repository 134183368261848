import React from 'react';
import loader from '../../assets/images/loader.gif';

export default function Loader(props){

  return(
    <>
      <div id="pre-loader">
          <img src={loader} alt="Loading..." />
      </div>
    </>
  );

}
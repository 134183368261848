import React, {useEffect , useState } from 'react';

export default function Reviewstar(props) {

      let totalstar = 5;
      let star = props.level;
      let intstar = parseInt(star);
      let floatstar = star - intstar; 

      let setstar = 0
      if (floatstar > 0) {
        setstar = intstar +1;
      }else{
        setstar = intstar
      }

      let reviews = 0;
      if (props.reviews!== undefined  &&  props.reviews > 0) {
        reviews = props.reviews;
      }

      return ( 
            <>
                <p className="product-review">
                    <strong>{star} </strong>
                    {(() => {
                      let row = []
                      for (var i = 1; i <= totalstar; i++) {
                        if(i <= setstar) {
                          if(floatstar > 0 ) {
                            if(i === intstar+1 ) {
                                row.push(<i class="fa fa-star-half-o" aria-hidden="true"></i>)
                            }else{
                                row.push(<i className="font-13 fa fa-star"></i>)
                            }
                          }else{
                            row.push(<i className="font-13 fa fa-star"></i>)
                          }
                          
                        }else{
                          row.push(<i class="fa fa-star-o" aria-hidden="true"></i>)
                        }
                          
                      }
                      return row
                    })()}
                    {reviews !== '' && reviews !== undefined && reviews !== 0 ? <strong>({reviews}) Reviews</strong>  : ''}
                      
                </p>
            </>
      );

}

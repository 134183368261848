import React, {useEffect , useState , Suspense} from 'react';
import Delivery from '../Delivery/Delivery';
import Deliverybanner from '../Deliverybanner/Deliverybanner';

//logo
import paylogo from '../../assets/images/safepayment.png';
import * as Helper from '../../Helper';
import $ from 'jquery';
import axios from 'axios';

export default function Footer(props){

   const [Sitesettings , setSitesettings] = useState([]);

      //API call
  let url = Helper.SITEURL+'admin/api/settings';
  const Apicall = () => {
    axios
        .get(url)
        .then((res) => {
            if (res.status === 200) {

              if(res.data.data !== undefined) {
                setSitesettings(res.data.data);
              }
              
            }else{
              setSitesettings([]);
            }
        })
        .catch((err) => {
            setSitesettings([]);
        });
  };

  useEffect(() => {
      Apicall();
      
  }, []);
   useEffect(() => {

    $(document).on('click','#information',function(){

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().find('ul').hide();
        }else{
            $(this).addClass('active');
            $(this).parent().find('ul').show();
        }

    });

    $(document).on('click','#customerservice',function(){

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().find('ul').hide();
        }else{
            $(this).addClass('active');
            $(this).parent().find('ul').show();
        }
        
    });
    
  }, []);

  let logo = Helper.SITEURL+'admin/'+props.settings.site_logo;

  return(   <> 
                    <footer id="footer" class="footer-2">

                    {props.type !== undefined  &&  props.type === 'productdetilas' ? '' : < Delivery />}
                    
                        
                        <div className="site-footer">
                            <div className="container">
                                <div className="footer-top">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 contact-box">
                                            <div className="footer-logo mb-3 mt-3"><img src={logo} alt={logo} title={props.settings.Title} style={{width:'50%'}} />
                                            </div>
                                            <div className="footer-social mt-3">

                                            <ul className="list--inline site-footer__social-icons social-icons">
                                                <li><strong>Connected:</strong></li>
                                                {Sitesettings.socal_fb !== undefined && Sitesettings.socal_fb !== '' && Sitesettings.socal_fb !== null ? <li><a className="social-icons__link" href={Sitesettings.socal_fb} target="_blank" title="Facebook"><i className="icon icon-facebook"></i></a></li>  : ''}
                                                {Sitesettings.socal_tw !== undefined && Sitesettings.socal_tw !== '' && Sitesettings.socal_tw !== null ? <li><a className="social-icons__link" href={Sitesettings.socal_tw} target="_blank" title="Twitter"><i className="icon icon-twitter"></i> <span className="icon__fallback-text">Twitter</span></a></li>  : ''}
                                                {Sitesettings.socal_in !== undefined && Sitesettings.socal_in !== '' && Sitesettings.socal_in !== null ? <li><a className="social-icons__link" href={Sitesettings.socal_in} target="_blank" title="Instagram"><i className="icon icon-instagram"></i> <span className="icon__fallback-text">Instagram</span></a></li>  : ''}
                                                {Sitesettings.socal_yu !== undefined && Sitesettings.socal_yu !== '' && Sitesettings.socal_yu !== null ? <li><a className="social-icons__link" href={Sitesettings.socal_yu} target="_blank" title="Youtube"><i class="icon icon-youtube"></i> <span className="icon__fallback-text">Youtube</span></a></li>  : ''}

                                            </ul>
                                            </div>

                                        </div>
                                        
                                        
                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 footer-links" dangerouslySetInnerHTML={{ __html: props.settings.section_block_1}}></div>
                                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 footer-links" dangerouslySetInnerHTML={{ __html: props.settings.section_block_2}}></div>
                                        <div className="col-12 col-sm-12 col-md-3 col-lg-4">
                                            <div className="display-table">
                                                <div className="display-table-cell footer-newsletter">
                                                    <form action="#" method="post">
                                                        <label className="h4">Store Location</label>
                                                    
                                                        <ul className="addressFooter">
                                                            <li><i className="icon anm anm-map-marker-al"></i>
                                                                
                                                                <p dangerouslySetInnerHTML={{ __html: props.settings.shop_address}}></p>
                                                            </li>
                                                            <li className="phone"><i className="icon anm anm-phone-l"></i>
                                                                <p>{props.settings.sale_contact}</p>
                                                            </li>
                                                            <li className="email"><i className="icon anm anm-envelope-l"></i>
                                                                <p>{props.settings.sale_email}</p>
                                                            </li>
                                                        </ul>
                                                        
                                                    </form>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <hr></hr>
                                <div className="footer-bottom">
                                    <div className="row">
                                        
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 order-1 order-md-0 order-lg-0 order-sm-1 copyright text-sm-center text-md-left text-lg-left">
                                            <span></span> <a href="templateshub.net">Developed by CodeByte Technologies</a></div>
                                       
                                        
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 order-0 order-md-1 order-lg-1 order-sm-0 payment-icons text-right text-md-center">
                                            <img src={paylogo} alt="Payment" />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

          
                    </footer>
        </> 
  );

}



import React , { useState, useCallback , useEffect} from 'react';
import ReactDOM from 'react-dom';
import Addnewaddress from '../../containers/AddnewaddressContainer'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Helper from '../../Helper';

//Axios
import axios from 'axios';

//Toster msg
import { toastError, toastSuccess } from "../Toaster/Toaster";

const addressblockreturn = (event) => {
  var currentnode =  event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
  ReactDOM.render(<Addnewaddress /> , currentnode);
}

const successstyle = {
  'textAlign' : "center",
  'float': 'left'
};

const successcontainer = {
  'paddingLeft': '15px',
  'paddingRight': '15px'
};

export default function Addadress(props){

  var settings = {
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false
    
  };
  

  //ADDRESS Validation
  const validationSchema = Yup.object().shape({
      name: Yup.string()
          .required('Please Enter Your Name.'),
      contact: Yup.string()
          .required('Please Enter Your Valid Contact Number.'),
      pincode: Yup.string()
          .required('Please Enter Your Pincode.'),
      street: Yup.string()
          .required('Please Enter Street.'),
      address: Yup.string()
          .required('Please Write down the address.'),    
      city: Yup.string()
          .required('Please add City.'),
      state: Yup.string()
          .required('Please add State.')

          
    });

    const [token, setstate] = useState(localStorage.getItem('token'))
    const formOptions = { resolver: yupResolver(validationSchema) };
    const [uid, setUid] = useState(localStorage.getItem('UID'));

    //get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] =  useState(false);
    const [message, setMessage] =  useState('');

    //ADDRESS id
    const [addressid, setAddressid] =  useState('');

    useEffect(() => {
      setAddressid(props.id);
    }, [props.id]);

    //API call ADDRESS
    function onSubmit(data) {

     // props.loadAddressHandler({show:true});

            if(data.name!== undefined && data.name !== ''){

              const formData = new FormData();
              formData.append("name", data.name);
              formData.append("contact", data.contact);
              formData.append("pincode", data.pincode);
              formData.append("street", data.street);
              formData.append("address", data.address);
              formData.append("city", data.city);
              formData.append("state", data.state);
              formData.append("optionalphone", data.optionalphone);
              formData.append("street", data.street);
              formData.append("addresslocation", data.addresslocation);
              formData.append("addresslocation", data.addresslocation);
              formData.append("addresstype", '1');
              formData.append("customerid", uid);
              formData.append("defaultaddress", '1');
              
                axios
                .post(Helper.SITEURL +'admin/api/customer-address-add/',formData , {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+token
                  }
                })
                .then((res) => {

                    if (res.data.success === true) {
                      setShowSuccess(true);
                      props.loadAddressHandler({show:true});
                      reset();
                      //setMessage(res.data.message);
                      toastSuccess(res.data.message);
                      props.showAddressHandler({show:false})
                    }else{
                      setShowError(true);
                      toastError(res.data.message);
                      //setMessage(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }
            return false;
    }

  return(
    <>
    <div class="card">
              <div className="account-login-form bg-light-gray padding-20px-all">
                <form  onSubmit={handleSubmit(onSubmit)}>
                <fieldset  style={{margin:'25px'}}>
                    <div className="row" style={{marginLeft:'0px'}}>
                      <h1>ADD A NEW ADDRESS</h1>
                    </div >
                       
                    <div className="row">
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                        <input {...register('name' , { required: true })} className={`form-control ${errors.name ? 'invalid' : ''}`} type="text" name="name" placeholder="Name" />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                      </div>
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                        <input {...register('contact' , { required: true })} className={`form-control ${errors.contact ? 'invalid' : ''}`} type="text" name="contact" placeholder='10-digit mobile number' />
                        <div className="invalid-feedback">{errors.contact?.message}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                        <input {...register('pincode' , { required: true })} className={`form-control ${errors.pincode ? 'invalid' : ''}`} type="text" name="pincode" placeholder='Pincode' />
                        <div className="invalid-feedback">{errors.pincode?.message}</div>
                      </div>
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                        <input {...register('street' , { required: true })} className={`form-control ${errors.street ? 'invalid' : ''}`} type="text" name="street" placeholder='Address (Area and Street)' />
                        <div className="invalid-feedback">{errors.street?.message}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-10 col-lg-10 col-xl-10 required">
                          <textarea  {...register('address' , { required: true })} className={`form-control ${errors.address ? 'invalid' : ''}`}  id="address" name="address" rows="4" cols="50" maxlength="200" placeholder='Address (Area and Street)'>
                          </textarea>
                          <div className="invalid-feedback">{errors.address?.message}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                        <input {...register('city' , { required: true })} className={`form-control ${errors.city ? 'invalid' : ''}`} type="text" name="city" placeholder='City/District/Town' />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                      </div>
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                          <select {...register('state' , { required: true })} className={`form-control ${errors.state ? 'invalid' : ''}`}  name="state" required="" tabindex="7">
                            <option value="" disabled="">--Select State--</option><option value="Andaman &amp; Nicobar Islands">Andaman &amp; Nicobar Islands</option><option value="Andhra Pradesh">Andhra Pradesh</option><option value="Arunachal Pradesh">Arunachal Pradesh</option><option value="Assam">Assam</option><option value="Bihar">Bihar</option><option value="Chandigarh">Chandigarh</option><option value="Chhattisgarh">Chhattisgarh</option><option value="Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu">Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu</option><option value="Delhi">Delhi</option><option value="Goa">Goa</option><option value="Gujarat">Gujarat</option><option value="Haryana">Haryana</option><option value="Himachal Pradesh">Himachal Pradesh</option><option value="Jammu &amp; Kashmir">Jammu &amp; Kashmir</option><option value="Jharkhand">Jharkhand</option><option value="Karnataka">Karnataka</option><option value="Kerala">Kerala</option><option value="Ladakh">Ladakh</option><option value="Lakshadweep">Lakshadweep</option><option value="Madhya Pradesh">Madhya Pradesh</option><option value="Maharashtra">Maharashtra</option><option value="Manipur">Manipur</option><option value="Meghalaya">Meghalaya</option><option value="Mizoram">Mizoram</option><option value="Nagaland">Nagaland</option><option value="Odisha">Odisha</option><option value="Puducherry">Puducherry</option><option value="Punjab">Punjab</option><option value="Rajasthan">Rajasthan</option><option value="Sikkim">Sikkim</option><option value="Tamil Nadu">Tamil Nadu</option><option value="Telangana">Telangana</option><option value="Tripura">Tripura</option><option value="Uttarakhand">Uttarakhand</option><option value="Uttar Pradesh">Uttar Pradesh</option><option value="West Bengal">West Bengal</option>
                          </select>
                          <div className="invalid-feedback">{errors.state?.message}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-5 col-lg-5 col-xl-5">
                        <input {...register('landmark')} className={`form-control ${errors.landmark ? 'invalid' : ''}`} type="text" name="landmark" placeholder='Landmark (Optional)' />
                        <div className="invalid-feedback">{errors.landmark?.message}</div>
                      </div>
                      <div className="form-group col-md-5 col-lg-5 col-xl-5">
                        <input {...register('optionalphone')} className={`form-control ${errors.optionalphone ? 'invalid' : ''}`} type="text" name="optionalphone" placeholder='Alternate Phone (Optional)' />
                        <div className="invalid-feedback">{errors.optionalphone?.message}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-5 col-lg-5 col-xl-5 required">
                          <label for="HOME" className="form-group">
                            <input {...register('addresslocation')}  type="radio" value="h"  className="form-group" name="addresslocation" readonly="" id="HOME" />&nbsp;
                            HOME
                          </label>
                          <label for="WORK" className="form-group" style={{marginLeft:'10px'}}>
                            <input {...register('addresslocation')}  type="radio" value="w" className="form-group" name="addresslocation" readonly="" id="WORK" />&nbsp;
                            WORK
                          </label>
                      </div>
                    </div>


                    <button type="submit" className="btn margin-15px-top btn-primary" >
                      Save
                    </button>
                    <button type="button" className="btn margin-15px-top btn-primary" style={{marginLeft:'10px' , background:'gray'}} onClick={(e) => props.showAddressHandler({show:false})}>
                      Cancel
                    </button>
                  </fieldset>
                
                </form>
              </div>
    </div>
  </>
  );
}

import React, {useEffect , useState , Suspense} from 'react';

//Component
import Topbanner from '../../containers/TopbannerContainer';
import Header from '../../containers/HeaderContainer';
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';
import Loader2 from '../Loader2/Loader2';
import Productfilterbanner from '../../containers/ProductfilterbannerContainer';
import Productfiltercontainer from '../../containers/ProductfiltercontainerContainer';

import * as Helper from '../../Helper';

import axios from 'axios';

export default function Productfilter(props) {


  const [loaderState, setLoaderState] = useState(true);
  const [Sitesettings , setSitesettings] = useState([]);
  const [productloader, setProductloader] = useState(false);

  const [token, setToken] = useState(localStorage.getItem('token'))
  const [uid, setuid] = useState(localStorage.getItem('UID'))


//Loader load
useEffect(() => {
  if (props.data.activeLoader === true) {
    setProductloader(true);
    const interval = setInterval(() => {
      setProductloader(false);
    }, 5000);
    return () => clearInterval(interval);
  }
}, [props.data.activeLoader]);

  //API call
  let url = Helper.SITEURL+'admin/api/settings';
  const Apicall = () => {
    axios
        .get(url)
        .then((res) => {
            if (res.status === 200) {

              setLoaderState(false);
              if(res.data.data !== undefined) {
                setSitesettings(res.data.data);
              }
              
            }else{
              setLoaderState(true);
              setSitesettings([]);
            }
        })
        .catch((err) => {
            setLoaderState(true);
            setSitesettings([]);
        });
  };

  useEffect(() => {
      Apicall();
      
  }, []);

  return(
    <>

          {loaderState !== false  ? <Loader />  : ''}
          {productloader === true  ? <Loader2 />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Topbanner settings = {Sitesettings} />  : ''}
          {loaderState === false && Sitesettings !== undefined  ? <Header settings = {Sitesettings} /> : ''}
          <div id="page-content ">
              <Productfilterbanner />
              <Productfiltercontainer />
          </div>
          {loaderState === false && Sitesettings !== undefined  ? <Footer settings = {Sitesettings}/> : ''}
    </>
  );
}

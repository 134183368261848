import { 
    SHOW_SEARCH,
    SETTINGS
} from '../constants'

export function showSearch(state = [], action) {
    switch (action.type) {
        case SHOW_SEARCH:
                return action.showsearch;
        default:
            return state;
    }
}

export function setsiteSettings(state = [], action) {
    switch (action.type) {
        case SETTINGS:
                return action.settings;
        default:
            return [];
    }
}


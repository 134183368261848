import React from 'react';
import './Loader2.css';

export default function Loader2(props){

  return(
    <>
      <div id="pre-loader">
        <div class="loader4"></div>
      </div>
    </>
  );

}
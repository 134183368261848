import React, {useEffect , useState } from 'react';
import Productcat from '../Productcategory/Productcategory'
import * as Helper from '../../Helper';

import axios from 'axios';

export default function Productpannel(props){


  const [loaderState, setLoaderState] = useState(true);
  const [Productcategory , setProductcategory] = useState([]);

  //API call

  let url = Helper.SITEURL+'admin/api/feature-category';

  const Apicall = () => {
    axios
        .get(url)
        .then((res) => {
            if (res.status === 200) {
              
              setLoaderState(false);
              if(res.data.data !== undefined) {
                setProductcategory(res.data.data);
              }
              
            }else{
              setLoaderState(true);
              setProductcategory([]);
            }
        })
        .catch((err) => {
            setLoaderState(true);
            setProductcategory([]);
        });
  };

  useEffect(() => {
      Apicall();
  }, []);


  return(
                    <>
                        <div className="section featured-column">
                          <div className="container">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="section-header text-center">
                                      <h2 class="h2">{props.title}</h2>
                                          <p>{props.description}</p>
                                    </div>
                                </div>
                              </div>
                              <div className="row">
                              
                              { Productcategory !== undefined &&  Productcategory.map((sdata, i) => { 
                                   return (<Productcat logo={ sdata.category_image} title={ sdata.category_name} description={ sdata.tagline} settings={ props.settings}/>)
                              })}
                              </div>
                          </div>
                        </div>
          
                    </>
  );

}